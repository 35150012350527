import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Country {
    @jsonMember
    id: number;
    @jsonMember
    name: string;
    @jsonMember
    code: string;
}
