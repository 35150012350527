import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-question-confirmation',
  templateUrl: './delete-question-confirmation.component.html',
})
export class DeleteQuestionConfirmationComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<DeleteQuestionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  closeDialog() {
    this.dialog.close(false);
  }

  confirm() {
    this.dialog.close(true);
  }

  ngOnInit() { }

}
