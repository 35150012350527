import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Rol} from '../models/rol.model';
import {NavController} from '@ionic/angular';


@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService,
        private nav: NavController
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.user;
        if (currentUser && currentUser.roles.findIndex((r: Rol) => r.name === 'admin')  !== -1) {
            return true;
        } else {
            this.nav.navigateRoot(['/login']);
            return false;
        }

    }
}
