import { Routes } from '@angular/router';
import {AdvertisingListPage} from './advertising-list/advertising-list.page';
import {AdvertisingFormPage} from './advertising-form/advertising-form.page';

export const AdvertisingRoutes: Routes = [
  {
    path: 'list',
    component: AdvertisingListPage
  },
  {
    path: 'form/:id',
    component: AdvertisingFormPage
  },
  {
    path: 'form',
    component: AdvertisingFormPage
  }
];


