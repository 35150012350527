import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Dimension {
    @jsonMember
    dimensionKey: string;
    @jsonMember
    dimension: string;

    constructor() {
    }
}
