import {Component, OnInit} from '@angular/core';
import {fields} from './profile.register';
import {FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../ngrx/app.reducer';
import {UpdateProfile} from '../../../../ngrx/user-logged/user-logged.actions';
import {first} from 'rxjs/operators';


@Component({
    selector: 'app-profile-data',
    templateUrl: './profile-data.component.html'
})
export class ProfileDataComponent implements OnInit {

    model: any;
    user: any;
    fields = fields;
    form = new FormGroup({});

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.store.select('userLogged')
            .pipe(
                first()
            )
            .subscribe((data) => {
                console.log(data);
                this.user = data.user;
                this.model = { password: '', extra: data.user.extra};
            });
    }

    onSubmit() {
        this.user.password = this.model.password;
        this.user.extra = this.model.extra;

        this.store.dispatch(new UpdateProfile({user: this.user}));
    }

}
