import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Tag} from '../../../models/tag.model';
import {FormTypes} from '../../../helpers/auxiliar';
import {NavController} from '@ionic/angular';
import {TagService} from '../../../services/tag.service';
import {CrudFormBaseComponent} from '../../crud-base/crud-form-base.component';

@Component({
    selector: 'app-course-form',
    templateUrl: './tag-form.page.html',
    styleUrls: ['./tag-form.page.scss'],
})
export class TagFormPage extends CrudFormBaseComponent implements OnInit {

    entity: Tag;

    constructor(private fb: FormBuilder,
                private nav: NavController,
                protected router: ActivatedRoute,
                protected service: TagService) {
        super(service, router);
    }


    createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
        });

        if (this.formType === FormTypes.edit) {
            this.form.controls.name.setValue(this.entity.name);
        }
    }
}
