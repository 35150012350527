import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {ModalController} from '@ionic/angular';
import {TagService} from '../../../services/tag.service';
import {CrudListBaseComponent} from '../../crud-base/crud-list-base.component';

@Component({
    selector: 'app-course-list',
    templateUrl: './tag-list.page.html',
    styleUrls: ['./tag-list.page.scss'],
})
export class TagListPage extends CrudListBaseComponent implements OnInit {
    constructor(private store: Store<AppState>,
                public modalController: ModalController,
                protected service: TagService) {
        super(service, modalController);
    }


}
