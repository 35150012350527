import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {TagListPage} from './tag-list/tag-list.page';
import {TagFormPage} from './tag-form/tag-form.page';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
      TagListPage,
      TagFormPage
  ]
})
export class TagModule {}
