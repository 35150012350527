import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { NotificationsService } from "src/app/services/notifications.service";
import { FormTypes } from "../../../helpers/auxiliar";
import { Store } from "@ngrx/store";
import { AppState } from "../../../ngrx/app.reducer";
import { ShowSuccess } from "src/app/ngrx/ui/ui.actions";

@Component({
    selector: "app-notification-form",
    templateUrl: "./notification-form.page.html",
    styleUrls: ["./notification-form.page.scss"],
})
export class NotificationFormPage implements OnInit {
    form: FormGroup;
    formType: string;

    constructor(
        private fb: FormBuilder,
        private nav: NavController,
        private router: Router,
        private store: Store<AppState>,
        private notificationService: NotificationsService,
    ) {
        router;
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            title: ["", Validators.required],
            body: [""],
            description: ["", Validators.required],
            link: [""],
            push: [true],
        });
    }

    submit() {
        if (this.form.valid) {
            this.notificationService.newNotification(this.form.value).subscribe((notification: Notification[]) => {
                this.store.dispatch(new ShowSuccess());
                this.nav.navigateRoot([`admin/courses/list/`]);
                setTimeout(() => {
                    this.nav.navigateRoot([`admin/notifications/list`]);
                }, 100);
            });
        }
    }
}
