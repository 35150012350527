import { Routes } from "@angular/router";
import { NotificationFormPage } from "./notification-form/notification-form.page";
import { NotificationsListPage } from "./notifications-list/notifications-list.page";

export const NotificationRoutes: Routes = [
    {
        path: "form",
        component: NotificationFormPage,
    },
    {
        path: "list",
        component: NotificationsListPage,
    },
];
