import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {delay, map, tap} from 'rxjs/operators';
import * as fromUi from '../ui/ui.actions';
import {NoShow} from './ui.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../services/error.service';


@Injectable()
export class UiEffects {
    public static readonly uiShowTimer = 1500;
    errorOrSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUi.UIActionsType.ShowError, fromUi.UIActionsType.ShowSuccess),
            delay(UiEffects.uiShowTimer),
            map(() => {
                return new NoShow();
            }),
        ));

    setError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUi.UIActionsType.ShowError),
            tap((action: fromUi.ShowError) => {
                if (action.payload && action.payload.error) {
                    if (action.payload.error instanceof HttpErrorResponse) {
                        this.errorService.errors = action.payload.error.error;
                    } else {
                        this.errorService.errors = action.payload.error;
                    }
                }
            }),
        ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private errorService: ErrorService
    ) {
    }
}


