import {Component, OnDestroy, OnInit} from "@angular/core"
import {Store} from "@ngrx/store"
import {AppState} from "../../ngrx/app.reducer"
import {Observable, Subject, Subscription} from "rxjs"
import {Course} from "../../models/course.model"
import {ActivatedRoute, Router} from "@angular/router"
import {Section} from "../../models/section.model"
import {Video} from "../../models/video.model"
import {map} from "rxjs/operators"
import {UserService} from "../../services/user.service"
import {ServerResponse} from "../../shared/models/response.interface"
import {CourseService} from "../../services/course.service"
import {DomSanitizer} from "@angular/platform-browser"
import {NavController} from "@ionic/angular"
import {PaymentsService} from "src/app/services/payments.service"
import {SubscriptionService} from "src/app/services/subscription.service"
import {NoShow} from "src/app/ngrx/ui/ui.actions"

@Component({
    selector: "app-course-show",
    templateUrl: "./course-show.page.html",
    styleUrls: ["./course-show.page.scss"],
})
export class CourseShowPage implements OnInit, OnDestroy {
    stopSubject: Subject<void> = new Subject<void>()
    course: Course
    sections: Section[] = []
    videos: Video[] = []
    plan: any
    videoSelected: Video
    progress: Observable<number>
    subscriptionCourse: Subscription = new Subscription()
    loading = true
    showVideo = false
    showAdv = true
    linkTest: any
    locked: boolean = false
    subscription: boolean = false
    redirectPayment: boolean = false
    hidden: boolean = true
    payment_id: any

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private courseService: CourseService,
        private PaymentService: PaymentsService,
        private SubscriptionService: SubscriptionService,
        private route: ActivatedRoute,
        private userService: UserService,
        private nav: NavController,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        const id = this.route.snapshot.params.id
        this.getData(id)
    }

    ionViewWillLeave() {
        this.stopSubject.next()
    }

    ngOnDestroy(): void {
        this.subscriptionCourse.unsubscribe()
    }

    getData(id) {
        this.courseService.get(id).subscribe((c: Course) => {
            this.course = c
            this.hidden = false
            if (c.modality == "pay") {
                this.locked = true

                this.getPayment(c)
            }

            if (c.modality == "subscription") {
                this.getSubscription()
            }

            if (c.modality !== "pay" && c.modality !== "subscription") {
                this.loading = false
            }

            this.getProgress()
        })
    }

    selectVideo(video: Video) {
        this.getProgress()
        this.videoSelected = null
        this.showAdv = true
        this.showVideo = false

        setTimeout(() => {
            this.videoSelected = video
            if (this.videoSelected.testLink) {
                this.linkTest = this.sanitizer.bypassSecurityTrustHtml(this.videoSelected.testLink)
            }
        }, 500)
    }

    getPayment(course) {
        this.PaymentService.getCoursePayment(course.id).subscribe((payment: any) => {
            this.hidden = true;
            this.redirectPayment = true;
            if(payment) {
                this.payment_id = payment.id
                if (payment.payment_status_detail == "pending_waiting_payment") {
                    this.redirectPayment = false;
                }
                if (payment.payment_status_detail == "accredited" || payment.payment_status_detail == "expired") {
                    this.hidden = false;
                    this.locked = false;
                    this.redirectPayment = false;
                }
            }
            this.loading = false
        })
    }

    getSubscription() {
        this.SubscriptionService.getActiveSubscription().subscribe((response: any) => {
            this.store.dispatch(new NoShow())

            if (response.status == "authorized") {
                this.subscription = true
            } else {
                this.SubscriptionService.getPlan().subscribe((plan: any) => {
                    this.plan = plan.body
                    this.store.dispatch(new NoShow())
                })
                this.hidden = true
            }

            this.loading = false
        })
    }


    getProgress() {
        this.progress = this.userService.getCourseProgress(this.course.id).pipe(
            map((resp: ServerResponse) => {
                const p = Math.round(resp.data)
                return p ? p : 0
            })
        )
    }

    finishAdv() {
        this.showVideo = true
        this.showAdv = false
    }

    redirectSubscriptions() {
        this.nav.navigateRoot([`subscription-main`])
    }

    goToExam(id) {
        this.nav.navigateRoot([`exam/detail/${id}`])
    }
}
