import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { PlatformDashboardPage } from "./dashboard/platform-dashboard.page";
import { MainPage } from "./main/main.page";
import { CourseDetailPage } from "./course-detail/course-detail.page";
import { CourseShowPage } from "./course-show/course-show.page";
import { CarouselComponent } from "./main/carousel/carousel.component";
import { HeaderDashboardComponent } from "./header-dashboard/header-dashboard.component";
import { TemplateComponent } from "./template/template.component";
import { MatchHeightDirective } from "./main/carousel/match-height.directive";

import { LoginPage } from "./user/login/login.page";
import { CodePage } from "./user/code/code.page";
import { RegisterComponent } from "./user/register/register.component";
import { CommentsComponent } from "./comments/comments.component";
import { CommentComponent } from "./comments/comment/comment.component";
import { AddCommentComponent } from "./comments/add-comment/add-comment.component";
import { ExamPage } from "./exam/exam.page";
import { QuestionComponent } from "./exam/question/question.component";
import { CountdownModule } from "ngx-countdown";
import { RegisterBasePage } from "./user/register-base/register-base.page";
import { RegisterImpulsarComponent } from "./user/register-impulsar/register.component";
import { ExamDetailPage } from "./exam-detail/exam-detail.page";
import { CertificationPage } from "./user/certification/certification.page";
import { FinishExamConfirmationComponent } from "./exam/finish-exam-confirmation/finish-exam-confirmation.component";
import { MatDialogModule } from "@angular/material";
import { CreditCardPage } from "./payments/credit-card/credit-card.page";
import { PaymentMethodPage } from "./payments/payment-method/payment-method.page";
import { PaymentPointsPage } from "./payments/payment-points/payment-points.page";
import { PaymentDetailPage } from "./payments/payment-detail/payment-detail.page";
import { SubscriptionMainPage } from "./subscription/subscription-main/subscription-main.page";
import { SubscriptionPaymentPage } from "./subscription/subscription-payment/subscription-payment.page";
import { SubscriptionMethodPage } from "./subscription/subscription-method/subscription-method.page";
import { SubscriptionPaymentsPointsPage } from "./subscription/subscription-payments-points/subscription-payments-points.page";
import { SubscriptionWireTransferPage } from "./subscription/subscription-wire-transfer/subscription-wire-transfer.page";
import { SubscriptionPayPalPage } from "./subscription/subscription-pay-pal/subscription-pay-pal.page";
import { NotificationPage } from "./notifications/notification/notification.page";

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//     suppressScrollX: true,
// };

@NgModule({
    declarations: [
        PlatformDashboardPage,
        CourseDetailPage,
        CourseShowPage,
        MainPage,
        MatchHeightDirective,
        CarouselComponent,
        HeaderDashboardComponent,
        TemplateComponent,
        CommentsComponent,
        CommentComponent,
        AddCommentComponent,
        RegisterBasePage,
        RegisterImpulsarComponent,
        LoginPage,
        CodePage,
        ExamPage,
        CertificationPage,
        QuestionComponent,
        RegisterComponent,
        ExamDetailPage,
        FinishExamConfirmationComponent,
        CreditCardPage,
        PaymentMethodPage,
        PaymentPointsPage,
        PaymentDetailPage,
        SubscriptionMainPage,
        SubscriptionPaymentPage,
        SubscriptionMethodPage,
        SubscriptionPaymentsPointsPage,
        SubscriptionWireTransferPage,
        SubscriptionPayPalPage,
        NotificationPage
    ],
    entryComponents: [FinishExamConfirmationComponent],
    imports: [CommonModule, SharedModule, CountdownModule, MatDialogModule],
    exports: [],
    providers: [
       
    ],
})
export class PlatformModule {}
