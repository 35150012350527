import {HttpClient, HttpHeaders} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Store} from "@ngrx/store"
import {environment} from "src/environments/environment"
import {AppState} from "../ngrx/app.reducer"
import {LoadStart, NoShow, ShowError, ShowSuccess} from "../ngrx/ui/ui.actions"
import {catchError, map} from "rxjs/operators"

@Injectable({
    providedIn: "root",
})
export class PaymentsService {
    constructor(private http: HttpClient, private store: Store<AppState>) {}

    public getPaymentPoints() {
      
        return this.http.get(`${environment.urlRoot}/payments/point-list`)
    }

    public getPayment(id) {
        return this.http.get(`${environment.urlRoot}/payments/payment/${id}`);
    }

    public getCoursePayment(id) {
        return this.http.get(`${environment.urlRoot}/payments/course/${id}`);
    }

    public setPaymentMethod(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/payments/process_payment_method`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }

    public sendPayment(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/payments/process_payment_other`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }
}
