import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {JobHistory} from './job-history.model';
import {Interest} from './interest.model';

@jsonObject
export class Advertising {
  @jsonMember
  id: number;
  @jsonMember
  temporalVideoPath: string;
  @jsonMember
  title: string;
  @jsonMember
  enabled: boolean | number;
  @jsonMember
  show: number;
  @jsonMember
  totalDuration: number;
  @jsonMember
  link: string;
  @jsonArrayMember(JobHistory)
  jobsHistory: JobHistory[];
  @jsonArrayMember(Interest)
  interests: Interest[];
}
