import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {FormBuilder, Validators} from '@angular/forms';
import {Section} from '../../../models/section.model';
import {VideoValidate} from '../../../shared/validations/custom.validation';
import {Observable, Subscription} from 'rxjs';
import {Course} from '../../../models/course.model';
import {ActivatedRoute} from '@angular/router';
import {AdvertisingService} from '../../../services/advertising.service';
import {NavController} from '@ionic/angular';
import {FormTypes, resetForm} from '../../../helpers/auxiliar';
import {Advertising} from '../../../models/advertising.model';
import {Interest} from '../../../models/interest.model';
import {CrudFormBaseComponent} from '../../crud-base/crud-form-base.component';

@Component({
    selector: 'app-advertising-form',
    templateUrl: './advertising-form.page.html',
    styleUrls: ['./advertising-form.page.scss'],
})
export class AdvertisingFormPage extends CrudFormBaseComponent implements OnInit, OnDestroy {

    @ViewChild('videoInput', {static: false}) videoInput: ElementRef;

    section: Section;
    course: Course;
    interests: Interest[] = [];
    interestsSelected: Interest[] = [];

    progressSub: Subscription = new Subscription();

    progress = 0;
    showProcessMessage = false;

    // edit
    advToEdit: Advertising;

    constructor(private store: Store<AppState>,
                private nav: NavController,
                private fb: FormBuilder,
                protected router: ActivatedRoute,
                protected service: AdvertisingService) {
        super(service, router);
    }

    ngOnDestroy() {
        this.progressSub.unsubscribe();
    }

    createForm() {
        if (this.formType === FormTypes.new) {
            this.form = this.fb.group({
                title: [null, Validators.required],
                video: [null, [Validators.required, VideoValidate]]
            });
        }

        if (this.formType === FormTypes.edit) {
            if (this.entity.interests) {
                this.interestsSelected = this.entity.interests;
            }
            this.form = this.fb.group({
                title: [this.entity.title, Validators.required],
                show: [this.entity.show]
            });
        }

    }

    extraStore() {
        this.service.interests()
            .subscribe((resp) => {
                this.interests = resp;
            });

        this.progressSub = this.service.progress.asObservable().subscribe(
            (progress) => {
                this.progress = progress;
                if (this.progress >= 100) {
                    this.showProcessMessage = true;
                    setTimeout(() => {
                        this.showProcessMessage = false;
                    }, 3500);
                }
            });
    }

    handleVideoFile(files) {
        this.form.get('video').markAllAsTouched();
        this.form.get('video').setValue(files[0]);
    }

    submit() {
        if (this.form.valid && this.formType === FormTypes.new) {
            const formData = new FormData();
            formData.append('title', this.form.get('title').value);
            formData.append('video', this.form.get('video').value);
            formData.append('interest', JSON.stringify(this.interestsSelected));
            this.service.save(formData).subscribe(() => {
                this.videoInput.nativeElement.value = null;
                this.interestsSelected = [];
                this.progress = 0;
                resetForm(this.form);
            });
        }

        if (this.form.valid && this.formType === FormTypes.edit) {
            this.entity.title = this.form.get('title').value;
            this.entity.show = (this.form.get('show').value) ? 1 : 0;
            this.entity.interest = this.interestsSelected;
            this.service.update(this.entity.id, this.entity).subscribe(() => {});
        }
    }

    isInterestSelected(int) {
        if (int instanceof Interest) {
            return (this.interestsSelected.find((item: Interest) => {
                return item.id === int.id;
            }));
        }
    }

    selectInterest(int) {
        if (int instanceof Interest) {
            const idx = this.interestsSelected.findIndex((item: Interest) => {
                return item.id === int.id;
            });

            if (idx > -1) {
                this.interestsSelected.splice(idx, 1);
            } else {
                this.interestsSelected.push(int);
            }
        }
    }
}
