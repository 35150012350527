import { Routes} from '@angular/router';

import { TaskListPage } from './task-list/task-list.page';

export const TaskRoutes: Routes = [
  {
    path: 'list',
    component: TaskListPage
  }
];


