import 'reflect-metadata';
import {schema} from 'normalizr';
import {Dimension} from './dimension.model';
import {EntityModel} from './entity.model';
import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {JobHistory} from './job-history.model';
import {Order} from '../shared/models/order.interface';
import {Exam} from './exam.model';

@jsonObject
export class Video extends EntityModel implements Order {
  @jsonMember
  id: number;
  @jsonArrayMember(Dimension)
  dimensions: Dimension[];
  @jsonMember
  imagePath: string;
  @jsonMember
  title: string;
  @jsonMember
  enabled: number;
  @jsonMember
  sectionId: number;
  @jsonMember
  totalDuration: number;
  @jsonMember
  position: number;
  @jsonMember
  link: string;
  @jsonArrayMember(JobHistory)
  jobsHistory: JobHistory[];
  @jsonMember
  exam: Exam;
  @jsonMember
  description: string;
  @jsonMember
  testLink: string;
  @jsonMember
  show: number;

  public static getSchema() {
    return new schema.Entity('videos');
  }
}
