import {Action} from '@ngrx/store';
import {Paginator} from '../../shared/models/paginator.interface';


export enum UIActionsType {
  LoadStart = '[UI] Load Start ',
  ShowError = '[UI] Show Error',
  ShowSuccess = '[UI] ShowSuccess',
  NoShow = '[UI] No Show Error',
  SetProgressPercent = '[UI] Set Progress Percent',
  SetPaginator = '[UI] Set Paginator'
}


export class LoadStart implements Action {
  readonly type = UIActionsType.LoadStart;
}


export class ShowError implements Action {
  readonly type = UIActionsType.ShowError;
  constructor(public payload?: {error: any}) {
  }
}

export class ShowSuccess implements Action {
  readonly type = UIActionsType.ShowSuccess;
}

export class NoShow implements Action {
  readonly type = UIActionsType.NoShow;
}

export class SetProgressPercent implements Action {
  readonly  type = UIActionsType.SetProgressPercent;
  constructor(public payload: {progress: number}) {
  }
}

export class SetPaginator implements Action {
  readonly type = UIActionsType.SetPaginator;
  constructor(public payload: {paginator: Paginator} ) {
  }
}


export type UIActions = ShowSuccess | LoadStart | ShowError | NoShow | SetProgressPercent | SetPaginator;

