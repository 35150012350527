import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Course} from '../../../models/course.model';
import {getS3Resource} from '../../auxiliar';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Router} from '@angular/router';
import {User} from '../../../models/user.model';
import {UserCourse} from '../../../models/user-course.model';
import {Subscription} from 'rxjs';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-course-item',
    templateUrl: './course-item.component.html',
    styleUrls: ['./course-item.component.scss'],
})
export class CourseItemComponent implements OnInit, OnDestroy {
    @Input() item = null;
    @Input() light = false;
    @Input() resource: string;
    @Input() index: any;
    @Input() activeCounter: boolean;
   

    user: User;
    userCourse: UserCourse[] = [];

    userSub: Subscription = new Subscription();

    constructor(private cdr: ChangeDetectorRef,
                private store: Store<AppState>,
                private nav: NavController,
                private router: Router) {
        this.getStore();
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
    }

    getStore() {
        this.userSub = this.store.select('userLogged')
            .subscribe(data => {
                this.user = data.user;
                this.userCourse = data.userCourses;
            });
    }

    getUrlImage(course: Course) {
        //console.log("getUrlImage")
        if (course.imagePath) {
            return getS3Resource(course.imagePath);
        }
    }

    selectCourse(course: Course) {
        if (course.type === 'live') {
            window.open(course.liveLink, "_blank");
        } else {
            if (this.userCourse.find((c) => c.courseId === course.id)) {
                this.nav.navigateRoot([`course/show/${course.id}`]);
                
            } else {
                this.nav.navigateRoot([`course/detail/${course.id}`]);
            }
        }
    }

}
