import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {CertificationFormPage} from './certification-form/certification-form.page';



@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        CertificationFormPage
    ]
})
export class CertificationModule {}
