const config = {
    name: 'Impulsar',
    styles: {
        '--ion-color-primary': '#7751d1',
        '--ion-color-primary-tint': '#4b1f6b',
        '--ion-color-primary-gradient': '#b271be'
    }
}

export default config;
