import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatCardModule, MatCheckboxModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {UiPage} from './components/ui/ui.page';
import {PlyrPlayerComponent} from './components/plyr-player/plyr-player.component';
import {PlyrModule} from 'ngx-plyr';
import {DeleteConfirmPage} from './components/delete-confirm/delete-confirm.page';
import {JwplayerComponent} from './components/jwplayer/jwplayer.component';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {UserLoggedMenuComponent} from './components/user-logged-menu/user-logged-menu.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AdvertisingPlayerComponent} from './components/advertising-player/advertising-player.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {VimeoPlayerComponent} from './components/vimeo-player/vimeo-player.component';
import {SafePipe} from './pipes/safe-pipe.pipe';
import {FormlyModule} from '@ngx-formly/core';
import {ProfilePage} from './components/profile/profile.page';
import {ProfileCourseComponent} from './components/profile/profile-course/profile-course.component';
import {ProfileDataComponent} from './components/profile/profile-data/profile-data.component';
import {ProfileCommentsComponent} from './components/profile/profile-comments/profile-comments.component';
import {ProgressComponent} from './components/progress/progress.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {ProfileDataImpulsarComponent} from './components/profile/profile-data-impulsar/profile-data-impulsar.component';
import {CourseItemComponent} from './components/course-item/course-item.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConfirmDataCertificationPage} from './components/profile/profile-course/confirm-data-certification/confirm-data-certification.page';
import {NotificationsComponent} from "../platform/notifications/notifications.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    declarations: [
        UiPage,
        PlyrPlayerComponent,
        DeleteConfirmPage,
        ConfirmDataCertificationPage,
        JwplayerComponent,
        PaginatorComponent,
        UserLoggedMenuComponent,
        AdvertisingPlayerComponent,
        VimeoPlayerComponent,
        SafePipe,
        ProfilePage,
        ProfileCourseComponent,
        ProfileDataComponent,
        ProfileCommentsComponent,
        ProfileDataImpulsarComponent,
        ProgressComponent,
        CourseItemComponent,
        NotificationsComponent
    ],
    entryComponents: [
        DeleteConfirmPage,
        ConfirmDataCertificationPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatExpansionModule,
        MatStepperModule,
        RouterModule,
        HttpClientModule,
        AngularEditorModule,
        MatInputModule,
        MatDividerModule,
        MatCardModule,
        MatRadioModule,
        FormsModule,
        PlyrModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        NoopAnimationsModule,
        BrowserAnimationsModule,
        MatSelectModule,
        NgxMaskModule.forRoot(options),
        FormlyModule.forRoot(),
    ],
    exports: [
        CommonModule,
        IonicModule,
        MatFormFieldModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatExpansionModule,
        MatDividerModule,
        MatStepperModule,
        MatRadioModule,
        FormsModule,
        RouterModule,
        MatTooltipModule,
        HttpClientModule,
        AngularEditorModule,
        MatCardModule,
        PlyrModule,
        MatProgressBarModule,
        NoopAnimationsModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        UiPage,
        PlyrPlayerComponent,
        VimeoPlayerComponent,
        JwplayerComponent,
        MatSelectModule,
        PaginatorComponent,
        UserLoggedMenuComponent,
        AdvertisingPlayerComponent,
        FormlyModule,
        NgxMaskModule,
        ProgressComponent,
        CourseItemComponent,
        NotificationsComponent
    ]
})
export class SharedModule {
}
