import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../models/user.model';
import {Subscription} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {first} from 'rxjs/operators';
import {ServerResponse} from '../../../shared/models/response.interface';
import {CertificationService} from '../../../services/certification.service';

@Component({
    selector: 'app-user-show',
    templateUrl: './user-show.page.html',
    styleUrls: ['./user-show.page.scss'],
})
export class UserShowPage implements OnInit, OnDestroy {

    user: User;
    certifications = [];
    subscritpions = [];
    pendingPaymentCourses = [];
    subUser: Subscription = new Subscription();

    constructor(private store: Store<AppState>,
                private route: ActivatedRoute,
                private service: UserService) {
    }

    ngOnInit() {
        const id = Number(this.route.snapshot.paramMap.get('id'));
        this.getUser(id);
        this.getCertifications(id);
        this.getSubscriptions(id);
        this.getPendingPaymentCourses(id);
    }

    ngOnDestroy(): void {
        this.subUser.unsubscribe();
    }

    getUser(id) {
        this.service.get(id)
            .pipe(first())
            .subscribe(user => {
                this.user = user;
            });
    }

    getSubscriptions(id) {
        this.service.getUserSubscriptions(id)
            .subscribe((s: any) => this.subscritpions = s);
    }

    getCertifications(id) {
        this.service.getUserCertifications(id)
            .subscribe((c: ServerResponse) => this.certifications = c.data);
    }

    getPendingPaymentCourses(id) {
        this.service.getPendingPaymentCourses(id)
            .subscribe((c: ServerResponse) => this.pendingPaymentCourses = c.data);
    }

    authorizateSubscription (subscription) {
        
        this.service.authorizateUserSuscription(subscription.id)
            .subscribe((s: any) => this.getSubscriptions(this.user.id));
    }
    


    disableUser() {
        this.user.enable = (this.user.enable) ? 0 : 1;
        this.service.update(this.user.id, this.user).subscribe();
    }

    getRol() {
        const isAdmin = this.user.roles.find((e) => (e.name === 'admin'));
        return (isAdmin) ? 'Admin' : 'User';
    }

    changeStatus(item) {
        item.enable = !item.enable;
        this.service.updateUserCertification(item.id, item)
            .subscribe();
    }

    changePaymentStatus(id) {
        this.service.updatePaymentStatus(id)
            .subscribe();
    }

}
