import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {environment} from '../../environments/environment';
import {LoadStart, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {TypedJSON} from "typedjson";
import {Course} from "../models/course.model";
import {catchError, map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CourseService extends EntityService {

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);
    }

    resource = 'courses';

    public getFilterCourse(filter) {
        return this.http.get(`${environment.urlRoot}/${this.resource}?filter=${filter}`)
            .pipe(
                map((resp: ServerResponse) => {
                    return resp.data.map((e) => this.transformToModel(e));
                })
            );
    }

    public getCarouselCourses(carouselId) {
        return this.http.get(`${environment.urlRoot}/${this.resource}?carousel=${carouselId}`)
            .pipe(
                map((resp: ServerResponse) => {
                    return resp.data.map((e) => this.transformToModel(e));
                })
            );
    }

    public getAllCourseNoPagination() {
        return this.http.get(`${environment.urlRoot}/${this.resource}`);
    }

    update(id, body) {
        this.store.dispatch(new LoadStart());
        return this.http.post(`${environment.urlRoot}/${this.resource}/${id}`, body)
            .pipe(
                map((resp) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                })
            );
    }

    public transformToModel(obj) {
        const courseSerializer = new TypedJSON(Course);
        return courseSerializer.parse(obj);
    }

    getCourseUserCertification(courseId) {
        return this.http.get(`${environment.urlRoot}/users/certification/course/${courseId}`);
    }

    createUserCertification(courseId, data) {
        return this.http.post(`${environment.urlRoot}/users/certification/course/${courseId}`, data);
    }

    getCertification(id) {
        return this.http.get(`${environment.urlRoot}/users/certifications/${id}`);
    }
}
