import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {EntityService} from './entity.service';
import {environment} from '../../environments/environment';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {LoadStart, SetPaginator, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {Paginator} from '../shared/models/paginator.interface';
import {ServerResponse} from '../shared/models/response.interface';
import {Subject, throwError} from 'rxjs';
import {TypedJSON} from 'typedjson';
import {Video} from '../models/video.model';

@Injectable({
    providedIn: 'root'
})
export class VideoService extends EntityService {

    public progress: Subject<number> = new Subject();

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);

    }

    resource = 'videos';
    className = 'video';

    public save(data: FormData) {
        return this.http.post(`${environment.urlRoot}/${this.resource}`, data, {reportProgress: true, observe: 'events'})
            .pipe(
                tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // This is an upload progress event. Compute and show the % done:
                        const progress = Math.round(100 * event.loaded / event.total);
                        this.progress.next(progress);
                        console.log(`File is ${progress}% uploaded.`);
                    }
                }),
                filter((resp: object) => resp.hasOwnProperty('body')),
                map((resp: HttpResponse<any>) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp.body.data;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    public update(data: FormData) {
        return this.http.post(`${environment.urlRoot}/${this.resource}/${data.get('id')}`, data)
            .pipe(
                map(resp => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    public getPending(page) {
        this.store.dispatch(new LoadStart());
        return this.http.get(`${environment.urlRoot}/tasks?page=${page}`)
            .pipe(
                map((resp: ServerResponse) => {
                    const paginator: Paginator = {
                        totalPages: resp.lastPage,
                        currentPage: resp.currentPage,
                        totalItems: resp.total
                    };
                    this.store.dispatch(new SetPaginator({paginator}));

                    return resp;
                })
            );
    }

    public repeatTask(id: number) {
        this.store.dispatch(new LoadStart());
        return this.http.get(`${environment.urlRoot}/tasks/${id}/repeat`);
    }

    transformToModel(obj) {
        const serializer = new TypedJSON(Video);
        return serializer.parse(obj);
    }



}
