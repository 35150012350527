import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterBasePage} from './platform/user/register-base/register-base.page';
import {AdminRoutingModule} from './admin/admin-routing.module';
import {LoginPage} from './platform/user/login/login.page';
import {CodePage} from './platform/user/code/code.page';
import {PlatformRoutingModule} from './platform/platform-routing.module';
import {CertificationPage} from './platform/user/certification/certification.page';
import {AuthGuard} from './helpers/auth.guard';

const routes: Routes = [
    {
        path: 'register',
        component: RegisterBasePage
    },
    {
        path: 'login',
        component: LoginPage
    },
    {
        path: 'user/code',
        component: CodePage
    },
    {
        path: 'users/course/{courseId}/certification',
        component: CertificationPage
    },
    {
        path: 'certification/:id',
        component: CertificationPage,
        canActivate: [AuthGuard]
    },
  
  
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        AdminRoutingModule,
        PlatformRoutingModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
