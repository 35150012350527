import {normalize, schema} from 'normalizr';
import {Section} from './section.model';
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from 'typedjson';
import {Tag} from './tag.model';
import {Exam} from './exam.model';
import {Certification} from './certification.model';


@jsonObject
export class Course {
    @jsonMember
    id: number;
    @jsonMember
    title: string;
    @jsonMember
    modality: string;
    @jsonMember
    price: number;
    @jsonMember
    paymentId: string;
    @jsonMember
    imagePath: string;
    @jsonMember
    description: string;
    @jsonMember
    videosCount: number;
    @jsonMember
    progress: number;
    @jsonArrayMember(Section)
    sections: Array<Section>;
    @jsonArrayMember(Tag)
    tags: Tag[];
    @jsonMember
    currentVideoId: number;
    @jsonMember
    show: number;
    @jsonMember
    type: string;
    @jsonMember
    liveLink: string;
    @jsonMember
    material: string;
    @jsonMember
    exam: Exam;
    @jsonMember
    certification: Certification
    @jsonMember
    code: string;
    


    constructor() {
        if (!this.sections) {
            this.sections = [];
        }
    }

    public static getSchema() {
        return new schema.Entity('courses', {
            sections: [Section.getSchema()]
        });
    }

    public static normalize(data) {
        return normalize(data, Course.getSchema());
    }

    public static toModel(obj: any) {
        const courseSerializer = new TypedJSON(Course);
        return courseSerializer.parse(obj);
    }
}
