import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import config from '../../../config/config';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public title: string;
  public appPages = [
    {
      title: 'Cursos',
      url: '/admin/courses/list',
      icon: 'school'
    },
    {
      title: 'Tareas',
      url: '/admin/tasks/list',
      icon: 'list-box'
    },
    {
      title: 'Usuarios',
      url: '/admin/users/list',
      icon: 'people'
    },
    {
      title: 'Tags',
      url: '/admin/tags/list',
      icon: 'pricetag'
    },
    {
      title: 'Publicidades',
      url: '/admin/advertisements/list',
      icon: 'tv'
    }
  ];


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
  ) {
    this.initializeApp();
    this.title = config.name;
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
