import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {NoShow} from '../../../ngrx/ui/ui.actions';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-code',
    templateUrl: './code.page.html',
    styleUrls: ['./code.page.scss'],
})
export class CodePage implements OnInit, AfterViewInit {
    submitted = false;
    form: FormGroup;

    success = false;
    error = false;

    @ViewChild('first', {static: true}) first: ElementRef;

    constructor(private fb: FormBuilder,
                private store: Store<AppState>,
                private authService: AuthService,
                private nav: NavController,
                private router: Router) {
    }

    ngOnInit() {
        this.existsUser();
        this.form = this.fb.group({
            firstNumber: ['', Validators.required],
            secondNumber: ['', Validators.required],
            thirdNumber: ['', Validators.required],
            fourthNumber: ['', Validators.required],
            fifthNumber: ['', Validators.required],
            sixthNumber: ['', Validators.required],
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.first.nativeElement.focus();
        }, 500);
    }

    next(event, target){
        if(event.target.value && (event.target.value !== ' ')){
            target.focus();
        }else {
            event.target.value = '';
        }
        if(event.key !== ' ') {
            target.focus();
        }
    }

    submit() {
        if (this.form.valid) {
            const values = this.form.value;
            let code = '';
            // tslint:disable-next-line:forin
            for (const prop in values) {
                code = code + values[prop];
            }

            this.authService.validate({code})
                .subscribe(
                    () => {
                        this.store.dispatch(new NoShow());
                        this.error = false;
                        this.success = true;
                        setTimeout(() => {
                            this.nav.navigateRoot(['/login']);
                        }, 3000);
                    },
                    () => {
                        this.store.dispatch(new NoShow());
                        this.error = true;
                    }
                );
        }
    }

    existsUser() {
        if (!localStorage.getItem('currentUser')) {
            this.nav.navigateRoot(['/login']);
        }
    }

}
