import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';
import {LoadStart} from '../ngrx/ui/ui.actions';
import {environment} from '../../environments/environment';
import {User} from '../models/user.model';
import {TypedJSON} from 'typedjson';
import {
    LogoutUser,
    SetUserLogged,
    SetUserLoggedAdvs,
    SetUserLoggedCourses,
    SetUserLoggedNotifications,
    SetUserLoggedVideos
} from '../ngrx/user-logged/user-logged.actions';
import {UserCourse} from '../models/user-course.model';
import {UserVideo} from '../models/user-videos.model';
import {Rol} from '../models/rol.model';
import {Advertising} from '../models/advertising.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public user: User;

    constructor(private http: HttpClient,
                private store: Store<AppState>) {
        this.store.select('userLogged', 'user').subscribe((user: User) => this.user = user);
    }

    register(body) {
        this.store.dispatch(new LoadStart());
        return this.http.post(`${environment.urlRoot}/auth/signup`, body);
    }


    validate(body) {
        this.store.dispatch(new LoadStart());
        return this.http.patch(`${environment.urlRoot}/auth/valid`, body);
    }


    getToken() {
        if (localStorage.getItem('currentUser')) {
            return localStorage.getItem('currentUser');
        }
    }

    login(body) {
        this.store.dispatch(new LoadStart());
     
        return this.http.post(`${environment.urlRoot}/auth/login`, body)
            .pipe(
                map((resp: ServerResponse) => {
                    localStorage.setItem('currentUser', resp.data.accessToken);
                    this.setUserSerializer(resp);
                    return resp;
                })
            );
    }

    getUserData() {
        return new Promise(resolve => {
            if (this.getToken()) {
                this.http.get(`${environment.urlRoot}/auth/user`)
                    .subscribe(
                        (data) => {
                            // console.log(data);
                            this.setUserSerializer(data);
                            resolve();
                        },
                        () => resolve());
            } else {
                resolve();
            }
        });
    }

    setUserSerializer(resp) {
        const userSerializer = new TypedJSON(User);
        const user = userSerializer.parse(resp.data);

        const userCoursesSerializer = new TypedJSON(UserCourse);
        const userCourses = [];
        if (resp.data.courses) {
            resp.data.courses.forEach((val) => {
                const courseUser = userCoursesSerializer.parse(val);
                userCourses.push(courseUser);
            });
        }

        const userVideosSerializer = new TypedJSON(UserVideo);
        const userVideos = [];
        if (resp.data.videos) {
            resp.data.videos.forEach((val) => {
                const userVideo = userVideosSerializer.parse(val);
                userVideos.push(userVideo);
            });
        }

        const userAdvsSerializer = new TypedJSON(Advertising);
        const userAdvs = [];
        if (resp.data.advs) {
            resp.data.advs.forEach((val) => {
                const adv = userAdvsSerializer.parse(val);
                userAdvs.push(adv);
            });
        }

        this.store.dispatch(new SetUserLoggedCourses({userCourses}));
        this.store.dispatch(new SetUserLogged({user}));
        this.store.dispatch(new SetUserLoggedVideos({userVideos}));
        this.store.dispatch(new SetUserLoggedAdvs({advs: userAdvs}));
        
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.store.dispatch(new LogoutUser());
    }

    hasRole(role: string): boolean {
        const idx = this.user.roles.findIndex((r: Rol) => r.name === role);
        return (idx > -1);
    }




}
