import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import moment from 'moment';

@Component({
  selector: 'app-edit-exams-confirmation',
  templateUrl: './edit-exams-confirmation.component.html',
})
export class EditExamsConfirmationComponent implements OnInit {

  public mails: string[];
  public dates: string;

  constructor(
    public dialog: MatDialogRef<EditExamsConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public inputs) { }

  closeDialog() {
    this.dialog.close(false);
  }

  confirm() {
    this.dialog.close(true);
  }

  ngOnInit() {
    this.inputs.data.forEach(d => {
      this.mails = this.inputs.data.map(m => m.user.email);
      this.dates = this.inputs.data.map(m => m.startedAt);
    });
  }
}
