import {Component, Inject} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Title} from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import config from '../config/config';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private titleService: Title,
    private notificationService: NotificationsService,
    @Inject(DOCUMENT)
    private document: Document
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.setStyles();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.titleService.setTitle(config.name);
      this.notificationService.addListeners()
    });
  }

  setStyles() {
    const body = document.querySelector('body');
    const props = config.styles;
    Object.keys(props).forEach((key) => {
      body.style.setProperty(key, props[key]);
    });
  }


}
