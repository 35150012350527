import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../../models/country.model';
import {Interest} from '../../../models/interest.model';
import {first} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ServerResponse} from '../../../shared/models/response.interface';
import {TypedJSON} from 'typedjson';
import {checkPasswordsValidate} from '../../../shared/validations/custom.validation';
// @ts-ignore
import {User} from '../../../models/user.model';
import {RegisterBasePage} from '../register-base/register-base.page';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {ShowError, ShowSuccess} from '../../../ngrx/ui/ui.actions';
import {resetForm} from '../../../helpers/auxiliar';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-register-impulsar',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterImpulsarComponent extends RegisterBasePage implements OnInit {
    public countries: Country[] = [];
    public interests: Interest[] = [];
    public interestsSelected = [];
    public isFromUNR = true;


    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>,
                @Inject(Router) router: Router,
                @Inject(ErrorService) errorService: ErrorService,
                @Inject(FormBuilder) fb: FormBuilder,
                @Inject(NavController) nav: NavController,
                private service: AuthService
    ) {
        super(http, store, router, errorService, nav, fb);
    }

    ngOnInit() {
        this.createForm();
        this.initStore();
        this.getData();
    }


    getData() {
        this.http.get(`${environment.urlRoot}/interests`)
            .pipe(
                first()
            )
            .subscribe((data: ServerResponse) => {
                const serializer = new TypedJSON(Interest);
                const arr: any[] = data.data;
                arr.forEach((item) => {
                    const val = serializer.parse(item);
                    this.interests.push(val);
                });
            });
    }

    createForm() {
        this.form = this.fb.group({
            email: [null, [Validators.email, Validators.required]],
            password: [null, [Validators.required]],
            passwordConfirmation: [null, [Validators.required]],
            extra: this.fb.group({
                name: [null, Validators.required],
                phoneNumber: [null, Validators.required],
                lastname: [null, Validators.required],
                university: ['UNR', Validators.required],
                joinTeam: [1, Validators.required]
            })
        }, {
            validators: checkPasswordsValidate('password', 'passwordConfirmation')
        });
    }

    submit() {
        this.submitted = true;
        if (this.form.valid && this.interestsSelected.length > 0) {
            this.showFormError = false;
            const values = this.form.value;
            values.extra.joinTeam = Number(values.extra.joinTeam);
            values.extra.interests = this.interestsSelected;

            const serializer = new TypedJSON(User);
            const user = serializer.parse(values);

            this.service.register(user)
                .subscribe(() => {
                        resetForm(this.form);
                        this.store.dispatch(new ShowSuccess());
                    },
                    (e) => {
                        this.errors = e.error;
                        setTimeout(() => {
                            this.errors = null;
                        }, 3000);
                        this.store.dispatch(new ShowError());
                    });
        } else {
            this.showFormError = true;
        }
    }

    changeUniversity(data) {
        this.isFromUNR = data.currentTarget.checked;
        const extra: FormGroup = this.form.controls.extra as FormGroup;
        if (!this.isFromUNR) {
            extra.controls.university.setValue('');
        } else {
            extra.controls.university.setValue('UNR');
        }
    }

    selectInterest(interest: Interest) {
        const idx = this.interestsSelected.findIndex((e: Interest) => e.id === interest.id);
        (idx > -1) ? this.interestsSelected.splice(idx, 1) : this.interestsSelected.push(interest);
    }

}
