import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsService } from "src/app/services/notifications.service";
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { Notification } from "src/app/models/notification.model";
import { NavController } from "@ionic/angular";

@Component({
    selector: "app-notification",
    templateUrl: "./notification.page.html",
    styleUrls: ["./notification.page.scss"],
})
export class NotificationPage implements OnInit {
    notification: Notification;
    urlNotification: SafeResourceUrl = "";
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationsService,
        private sanitizer: DomSanitizer,
        private nav: NavController,
    ) {}

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.loadData(id);
    }

    loadData(id) {
        this.notificationService.getNotification(id).subscribe(
            (n: Notification) => (
        
                (this.notification = n), this.getURL(n)
            ),
        );
    }

    getURL(notification: Notification) {
        if ( notification && notification?.link?.length > 1) {
            console.log("getURL");
            this.urlNotification = this.sanitizer.bypassSecurityTrustResourceUrl(this.notification.link);
        }
    }

    goHome() {
        this.nav.navigateRoot(["/"]);
    }
}
