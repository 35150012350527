import { Component, Input, OnInit } from "@angular/core";
import { Notification } from "../../models/notification.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../ngrx/app.reducer";
import { NotificationsService } from "src/app/services/notifications.service";
import { SetUserLoggedNotifications } from "src/app/ngrx/user-logged/user-logged.actions";
import { NavController } from "@ionic/angular";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
    @Input() showNotificationActive: boolean;
    notifications: Notification[] = [];
    focusID: number;
    showNotifications: boolean = false;

    constructor(
        private store: Store<AppState>,
        private nav: NavController,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit() {
        //this.showNotificationActive = true;
        this.store.select("userLogged").subscribe((data) => {
            this.notifications = data.userNotifications;
        });
    }

    openNotification(notification: Notification) {
        if (!notification.read) {
            this.notificationService.setNotificationRead(notification);

            this.notifications.forEach((n: Notification) => {
                if (n.id == notification.id) {
                    n.read = true;
                }
            });
            this.store.dispatch(new SetUserLoggedNotifications({ userNotifications: this.notifications }));
        }
        if(notification.link?.length > 5){
            this.showNotificationActive = false;
            this.nav.navigateRoot([`notification/${notification.id}`]);
        }
    }

    seeMore(notification: Notification) {
      this.openNotification(notification)
    }
}
