export function ResponseErrors() {
    return [
        {
            code: "205",
            name_error: "parameter cardNumber can not be null/empty",
            detail: "Ingresa el número de tu tarjeta.",
        },
        {code: "208", name_error: "parameter cardExpirationMonth can not be null/empty", detail: "Elige un mes."},
        {code: "209", name_error: "parameter cardExpirationYear can not be null/empty", detail: "Elige un año."},
        {code: "212", name_error: "parameter docType can not be null/empty", detail: "Ingresa tu tipo de documento."},
        {
            code: "213",
            name_error: "The parameter cardholder.document.subtype can not be null or empty",
            detail: "Ingresa tu documento.",
        },
        {code: "214", name_error: "parameter docNumber can not be null/empty", detail: "Ingresa tu documento."},
        {code: "220", name_error: "parameter cardIssuerId can not be null/empty", detail: "Ingresa tu banco."},
        {
            code: "221",
            name_error: "parameter cardholderName can not be null/empty",
            detail: "Ingresa el nombre y apellido.",
        },
        {
            code: "224",
            name_error: "parameter securityCode can not be null/empty",
            detail: "Ingresa el código de seguridad.",
        },
        {code: "E301", name_error: "invalid parameter cardNumber", detail: "Ingresa un número de tarjeta válido."},
        {code: "E302", name_error: "invalid parameter securityCode", detail: "Revisa el código de seguridad."},
        {code: "316", name_error: "invalid parameter cardholderName", detail: "Ingresa un nombre válido."},
        {code: "322", name_error: "invalid parameter docType", detail: "El tipo de documento es inválido."},
        {code: "323", name_error: "invalid parameter cardholder.document.subtype", detail: "Revisa tu documento."},
        {code: "324", name_error: "invalid parameter docNumber", detail: "El documento es inválido."},
        {code: "325", name_error: "invalid parameter cardExpirationMonth", detail: "El mes es inválido."},
        {code: "326", name_error: "invalid parameter cardExpirationYear", detail: "El año es inválido."},
        {code: "default", name_error: "Otro código de error", detail: "Revisa los datos."}
    ]
}
