export function ResponsePayment () {
    return [
        {
            name_error: "cc_rejected_bad_filled_card_number",
            detail: "Revisa el número de tarjeta.",
        },
        {
            name_error: "cc_rejected_bad_filled_date",
            detail: "Revisa la fecha de vencimiento.",
        },
        {name_error: "cc_rejected_bad_filled_other", detail: "Revisa los datos."},
        {
            name_error: "cc_rejected_bad_filled_security_code",
            detail: "Revisa el código de seguridad de la tarjeta.",
        },
        {
            name_error: "cc_rejected_blacklist",
            detail: "No pudimos procesar tu pago.",
        },
        {
            name_error: "cc_rejected_call_for_authorize",
            detail: "Debes autorizar ante tu banco el pago del importe de la compra .",
        },
        {
            name_error: "cc_rejected_card_disabled",
            detail: "Llama a tu banco para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta. ",
        },
        {
            name_error: "cc_rejected_card_error",
            detail: "No pudimos procesar tu pago.",
        },
        {
            name_error: "cc_rejected_duplicated_payment",
            detail: "Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.",
        },
        {
            name_error: "cc_rejected_high_risk",
            detail: "Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.",
        },
        {
            name_error: "cc_rejected_insufficient_amount",
            detail: "Tu tarjeta no tiene fondos suficientes.",
        },
        {
            name_error: "cc_rejected_invalid_installments",
            detail: "No pudimos procesar tu pago en cuotas.",
        },
        {
            name_error: "cc_rejected_max_attempts",
            detail: "Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.",
        },
        {
            name_error: "cc_rejected_other_reason",
            detail: "Tu banco no procesó el pago.",
        },
    ]
}