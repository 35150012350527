import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../ngrx/app.reducer';
import {Subscription} from 'rxjs';
import {Course} from '../../models/course.model';
import {ActivatedRoute, Router} from '@angular/router';
import {getS3Resource} from '../../shared/auxiliar';
import {StartCourse} from '../../ngrx/user-logged/user-logged.actions';
import {CourseService} from '../../services/course.service';
import {SubscriptionService} from 'src/app/services/subscription.service'
import { NoShow } from 'src/app/ngrx/ui/ui.actions';
import { NavController } from '@ionic/angular';


@Component({
    selector: 'app-course-detail',
    templateUrl: './course-detail.page.html',
    styleUrls: ['./course-detail.page.scss'],
})
export class CourseDetailPage implements OnInit, OnDestroy {

    private selectedCourseSub: Subscription = new Subscription();
    subscription: boolean = false
    validCodeProvided = false;
    code = '';
    public course: Course;

    constructor(private store: Store<AppState>,
                private route: ActivatedRoute,
                private coursService: CourseService,
                private SubscriptionService: SubscriptionService,
                private nav: NavController,
                private router: Router) {
    }

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.route.queryParams.subscribe(params => {
            this.code = params.code;
        });
        
        this.getData(id);
    }

    ngOnDestroy(): void {
        this.selectedCourseSub.unsubscribe();
    }

    getData(id) {
        this.coursService.get(id)
            .subscribe(c => this.getSubscription(c));
    }

    getSubscription(c){
        this.course = c;
        if(c.modality == "subscription" || "free_with_code"){
            this.SubscriptionService.getActiveSubscription().subscribe((response: any) => {
                this.store.dispatch(new NoShow())
                
                if (response.status== 'authorized'){
                
                    this.subscription = true;
                }
            }) 
        }

        if(c.modality == "free_with_code") {
            this.validCodeProvided = c.code == this.code;
        }
       
    }

    getImage() {
        if (this.course.imagePath) {  
            return getS3Resource(this.course.imagePath);
        }
    }

    startCourse() {
        this.store.dispatch(new StartCourse({course: this.course}));
    }

    redirectSubscriptions() {
        this.nav.navigateRoot([`subscription-main`])
    }

}
