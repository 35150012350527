import {Component, Input, OnInit, OnChanges, NgZone} from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})

export class ProgressComponent implements OnInit, OnChanges {
  @Input() progress = '0';

  strokeDasharray =  '0,100';
  color = '#000';

  constructor(public zone: NgZone) {
    const body = document.querySelector('body');
    this.color = body.style.getPropertyValue('--ion-color-primary');
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.strokeDasharray = this.progress + ',100';
  }

}
