import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CarouselListPage } from './carousel-list/carousel-list.page';
import { CarouselFormPage } from './carousel-form/carousel-form.page';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        CarouselListPage,
        CarouselFormPage,
    ], entryComponents: [
        
    ]
})

export class CarouselModule {}
