import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CourseModule } from "./course/course.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { VideoModule } from "./video/video.module";
import { TaskModule } from "./task/task.module";
import { SharedModule } from "../shared/shared.module";
import { AdminDashboardPage } from "./dashboard/admin-dashboard.page";
import { UserModule } from "./user/user.module";
import { TagModule } from "./tags/tag.module";
import { AdvertisingModule } from "./advertising/advertising.module";
import { ExamModule } from "./exams/exam.module";
import { CertificationModule } from "./certifications/certification.module";
import { MenuComponent } from "./menu/menu.component";
import { CarouselModule } from "./carousels/carousel.module";

@NgModule({
    declarations: [AdminDashboardPage, MenuComponent],
    imports: [
        CommonModule,
        SharedModule,
        CourseModule,
        VideoModule,
        UserModule,
        TagModule,
        ExamModule,
        AdvertisingModule,
        CertificationModule,
        CarouselModule,
        NotificationsModule,
    ],
    exports: [
        CourseModule,
        VideoModule,
        TaskModule,
        UserModule,
        TagModule,
        ExamModule,
        CertificationModule,
        MenuComponent,
        CarouselModule,
        NotificationsModule,
    ],
})
export class AdminModule {}
