import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentsService} from '../../services/comments.service';
import {ServerResponse} from '../../shared/models/response.interface';
import {TypedJSON} from 'typedjson';
import {Comment} from '../../models/comment.model';
import {Course} from '../../models/course.model';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit, OnDestroy {
    @Input() course: Course;

    comments: Array<Comment> = [];
    sub$: Subscription = new Subscription();
    page = 1;
    showMore = true;

    constructor(private service: CommentsService) {
    }

    ngOnInit() {
        this.getComments();
        // setInterval(() => {
        //     this.getComments();
        // }, 20000);
    }

    ngOnDestroy() {
        this.sub$.unsubscribe();
    }

    addComment(comment: Comment) {
        this.comments.push(comment);
    }

    getComments() {
        this.sub$ = this.service.getCourseComments(this.course.id, this.page).subscribe(
            (resp: ServerResponse) => {
                this.page = resp.currentPage;
                if (this.page === resp.lastPage) {
                    this.showMore = false;
                }

                const serializer = new TypedJSON(Comment);
                resp.data.forEach(d => {
                    if (!this.comments.find((c) => Number(c.id) === Number(d.id))) {
                        this.comments.push(serializer.parse(d));
                    }
                });
            },
            (e) => this.showMore = false
            );
    }

    more() {
        this.page++;
        this.getComments();
    }

}
