import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {LoadStart, NoShow, SetPaginator, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {environment} from '../../environments/environment';
import {UserVideo} from '../models/user-videos.model';
import {catchError, map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';
import {Paginator} from '../shared/models/paginator.interface';
import {TypedJSON} from 'typedjson';
import {User} from '../models/user.model';
import {throwError} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserService extends EntityService {

    resource = 'users';
    className = 'user';

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);
    }

    public startCourse(courseId) {
    
        this.store.dispatch(new LoadStart());
        return this.http.post(`${environment.urlRoot}/${this.resource}/courses/${courseId}`, {});
    }

    public putUserVideo(userVideo: UserVideo) {
        return this.http.put(`${environment.urlRoot}/${this.resource}/videos/${userVideo.videoId}`, userVideo);
    }

    public getCourseProgress(id) {
        return this.http.get(`${environment.urlRoot}/${this.resource}/courses/${id}/progress`);
    }

    public getCoursesProgress() {
        return this.http.get(`${environment.urlRoot}/${this.resource}/courses/progress`);
    }

    public getAll(page, filter?) {
        this.store.dispatch(new LoadStart());
        const url = (filter) ? `${environment.urlRoot}/${this.resource}?page=${page}&filter=${filter}` :
            `${environment.urlRoot}/${this.resource}?page=${page}`;
        return this.http.get(url)
            .pipe(
                map((resp: ServerResponse) => {
                    const paginator: Paginator = {
                        totalPages: resp.lastPage,
                        currentPage: resp.currentPage,
                        totalItems: resp.total
                    };

                    const data = resp.data.map((e) => this.transformToModel(e));
                    this.store.dispatch(new SetPaginator({paginator}));
                    this.store.dispatch(new NoShow());
                    return data;
                })
            );
    }

    updateProfile(body) {
        this.store.dispatch(new LoadStart());
        return this.http.patch(`${environment.urlRoot}/profile`, body);
    }


    public getUserProfileCourse() {
        const url = `${environment.urlRoot}/users/profile/courses`;
        return this.http.get(url);
    }

    public getUserListLink() {
        this.store.dispatch(new LoadStart());
        return this.http.get(`${environment.urlRoot}/users/list`, {responseType: 'blob'})
            .pipe(
                map((resp) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    transformToModel(obj) {
        if (obj.extraUser) {
            obj.extra = JSON.parse(obj.extraUser);
        }

        if (obj.interests && obj.extra) {
            obj.extra.interests = obj.interests;
        }
        const courseSerializer = new TypedJSON(User);
        return courseSerializer.parse(obj);
    }

    getUserCertifications(userId) {
        return this.http.get(`${environment.urlRoot}/users/${userId}/certifications`);
    }

    getPendingPaymentCourses(userId) {
        return this.http.get(`${environment.urlRoot}/users/${userId}/pending-payments`);
    }

    getUserSubscriptions(userId) {
        return this.http.get(`${environment.urlRoot}/users/${userId}/subscriptions`);
    }

    authorizateUserSuscription (subscriptionId) {
        
        return this.http.post(`${environment.urlRoot}/users/${subscriptionId}/authorizate-subscription`, {});
    } 

    updateUserCertification(id, data) {
        this.store.dispatch(new LoadStart());
        return this.http.patch(`${environment.urlRoot}/users/certifications/${id}`, data)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp.data;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    updatePaymentStatus(id) {
        this.store.dispatch(new LoadStart());
        return this.http.patch(`${environment.urlRoot}/users/pending-payments/${id}`, {payment_satus_detail:'accredited'})
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp.data;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }


}
