import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Section} from '../../../models/section.model';
import {VideoService} from '../../../services/video.service';
import {ImgValidate, VideoValidate} from '../../../shared/validations/custom.validation';
import {Observable, Subscription} from 'rxjs';
import {Course} from '../../../models/course.model';
import {ActivatedRoute} from '@angular/router';
import {Video} from '../../../models/video.model';
import {FormTypes, resetForm} from '../../../helpers/auxiliar';
import {NavController} from '@ionic/angular';
import {CourseService} from '../../../services/course.service';
import {ServerResponse} from '../../../shared/models/response.interface';

@Component({
    selector: 'app-video-form',
    templateUrl: './video-form.page.html',
    styleUrls: ['./video-form.page.scss'],
})
export class VideoFormPage implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('videoInput', {static: false}) videoInput: ElementRef;
    @ViewChild('imageInput', {static: false}) imageInput: ElementRef;

    form: FormGroup;
    section: Section;
    course: Course;
    videoToEdit: Video;

    sections: any;

    loadingObs$: Observable<boolean>;
    progress = 0;
    showProcessMessage = false;

    courseSub: Subscription = new Subscription();
    progressSub: Subscription = new Subscription();
    uiSuccessSub: Subscription = new Subscription();

    // edit
    videoId: number;
    courseId: number;
    sectionId: number;

    formType: string;


    constructor(private store: Store<AppState>,
                private fb: FormBuilder,
                private videoService: VideoService,
                private courseService: CourseService,
                private router: ActivatedRoute,
                private nav: NavController) {
    }

    ngOnInit() {
        this.setFormType();
        this.getData();
    }

    ngOnDestroy(): void {
        this.courseSub.unsubscribe();
        this.progressSub.unsubscribe();
        this.uiSuccessSub.unsubscribe();
    }

    ngAfterViewInit(): void {
    }

    setFormType() {
        this.courseId = Number(this.router.snapshot.paramMap.get('course-id'));
        this.sectionId = Number(this.router.snapshot.paramMap.get('section-id'));

        if (this.router.snapshot.paramMap.get('video-id')) {
            this.videoId = Number(this.router.snapshot.paramMap.get('video-id'));
            this.formType = FormTypes.edit;
        } else {
            this.formType = FormTypes.new;
            this.createForm();
        }
    }


    getData() {
        this.courseSub = this.courseService.get(this.courseId)
            .subscribe(async (c: Course) => {
                this.course = c;
                this.section = this.course.sections.find((s: Section) => s.id === this.sectionId);

                if (this.formType === FormTypes.edit) {
                    this.videoToEdit = this.section.videos.find((v: Video) => v.id === this.videoId);
                    if(!this.videoToEdit) {
                        this.videoService.get(this.videoId).subscribe(v => { 
                            this.videoToEdit = v;
                            this.createForm();
                        });
                    }else {
                        this.createForm();
                    }
                } else {
                    this.progressSub = this.videoService.progress.asObservable().subscribe(
                        (progress) => {
                            this.progress = progress;
                            if (this.progress >= 100) {
                                this.showProcessMessage = true;
                                setTimeout(() => {
                                    this.showProcessMessage = false;
                                }, 3500);
                            }
                        });
                    this.createForm();
                }

                
            });
    }

    createForm() {
        if (this.formType === FormTypes.new) {
            this.form = this.fb.group({
                title: [null, Validators.required],
                description: [null],
                testLink: [null],
                video: [null, [Validators.required, VideoValidate]],
                image: [null, [Validators.required, ImgValidate]],
                show: [true, [Validators.required]]
            });
        }

        if (this.formType === FormTypes.edit) {
            this.form = this.fb.group({
                title: [this.videoToEdit.title, Validators.required],
                description: [(this.videoToEdit.description) ? this.videoToEdit.description : null],
                testLink: [(this.videoToEdit.testLink) ? this.videoToEdit.testLink : null],
                section: [this.videoToEdit.sectionId, Validators.required],
                image: [null, [ImgValidate]],
                show: [this.videoToEdit.show?true:false, Validators.required],
            });
        }

    }

    handleVideoFile(files) {
        this.form.get('video').markAllAsTouched();
        this.form.get('video').setValue(files[0]);
    }

    handleImgFile(files) {
        this.form.get('image').markAllAsTouched();
        this.form.get('image').setValue(files[0]);
    }

    submit() {
        if (this.form.valid) {
            const formData = new FormData();
            formData.append('title', this.form.get('title').value);
            formData.append('section', this.section.id.toString());
            formData.append('image', this.form.get('image').value);
            formData.append('show', this.form.get('show').value?"1":"0");

            if (this.form.get('description').value) {
                formData.append('description', this.form.get('description').value);
            }

            if (this.form.get('testLink').value) {
                formData.append('testLink', this.form.get('testLink').value);
            }

            if (this.formType === FormTypes.new) {
                formData.append('video', this.form.get('video').value);

                this.videoService.save(formData)
                    .subscribe(
                        () => {
                            this.videoInput.nativeElement.value = '';
                            this.imageInput.nativeElement.value = '';
                            resetForm(this.form);
                            setTimeout(()=> window.location.href = window.location.href.replace(/\/[^/]+$/, '/'), 500);
                        }
                    );
            }

            if (this.formType === FormTypes.edit) {
                formData.append('id', this.videoToEdit.id.toString());

                this.videoService.update(formData)
                    .subscribe((resp: ServerResponse) => {
                    
                    });
            }
        }
    }

}
