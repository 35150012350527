import {ServerResponse} from '../../../models/response.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../models/user.model';
import {UpdateProfile} from '../../../../ngrx/user-logged/user-logged.actions';
import {TypedJSON} from 'typedjson';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Interest} from '../../../../models/interest.model';
import {ErrorService} from '../../../../services/error.service';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Country} from '../../../../models/country.model';
import {Subscription} from 'rxjs';
import {AppState} from '../../../../ngrx/app.reducer';
import {first} from 'rxjs/operators';


@Component({
    selector: 'app-profile-data-impulsar',
    templateUrl: './profile-data-impulsar.component.html'
})
export class ProfileDataImpulsarComponent implements OnInit {
    public countries: Country[] = [];
    public interests: Interest[] = [];
    public interestsSelected = [];
    public isFromUNR = true;

    protected subSucc: Subscription = new Subscription();
    protected subError: Subscription = new Subscription();

    public form: FormGroup;
    public submitted = false;
    public errors: any;
    public showFormError = false;

    public user: User;


    constructor(private http: HttpClient,
                private store: Store<AppState>,
                private route: Router,
                private errorService: ErrorService,
                private fb: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.initStore();
        this.getData();
    }

    initStore() {
        this.subError = this.store.select('ui', 'error')
            .subscribe(() => {
                if (this.errorService.errors) {
                    this.errors = this.errorService.getErrors();
                }
            });

        this.store.select('userLogged')
            .pipe(
                first()
            )
            .subscribe((data) => {
                this.user = data.user;
                if (!this.user.extra) {
                    // @ts-ignore
                    this.user.extra = {};
                }

                this.isFromUNR = (this.user.extra['university'] === 'UNR');

                this.interestsSelected = (data.user.extra && data.user.extra['interests']) ? data.user.extra['interests'] : [];
                this.createForm();
            });
    }

    getData() {
        this.http.get(`${environment.urlRoot}/interests`)
            .pipe(
                first()
            )
            .subscribe((data: ServerResponse) => {
                const serializer = new TypedJSON(Interest);
                const arr: any[] = data.data;
                arr.forEach((item) => {
                    const val = serializer.parse(item);
                    this.interests.push(val);
                });
            });

        this.http.get(`${environment.urlRoot}/countries`)
            .pipe(
                first()
            )
            .subscribe((data: ServerResponse) => {
                const serializer = new TypedJSON(Country);
                const arr: any[] = data.data;
                arr.forEach((item) => {
                    const val = serializer.parse(item);
                    this.countries.push(val);
                });
            });
    }

    createForm() {
        this.form = this.fb.group({
            password: [null],
            extra: this.fb.group({
                name: [(this.user.extra.name) ? this.user.extra.name : null, Validators.required],
                lastname: [(this.user.extra.lastname) ? this.user.extra.lastname : null, Validators.required],
                phoneNumber: [(this.user.extra['phoneNumber']) ? this.user.extra['phoneNumber'] : null, Validators.required],
                country: [(this.user.extra['country']) ? this.user.extra['country'] : null, null],
                incomeYear: [(this.user.extra['incomeYear']) ? this.user.extra['incomeYear'] : null, [Validators.min(1900), Validators.max(2100)]],
                isGraduated: [(this.user.extra['isGraduated']) ? this.user.extra['isGraduated'] : null, false],
                currentYearStudying: [(this.user.extra['currentYearStudying']) ? this.user.extra['currentYearStudying'] : null, null],
                birthDate: [(this.user.extra['birthDate']) ? this.user.extra['birthDate'] : null, [Validators.pattern('^(0?[1-9]|[12][0-9]|3[01])[\\/\\-](0?[1-9]|1[012])[\\/\\-]\\d{4}$')]],
                fromRosario: [(this.user.extra['fromRosario']) ? this.user.extra['fromRosario'] : null, null],
                university: [(this.user.extra['fromRosario']) ? this.user.extra['fromRosario'] : 'UNR', Validators.required],
                instagram: [(this.user.extra['instagram']) ? this.user.extra['instagram'] : null, null],
                joinTeam: [(this.user.extra['joinTeam']) ? this.user.extra['joinTeam'] : true]
            })
        });
    }

    submit() {
        this.submitted = true;
        if (this.form.valid && this.interestsSelected.length > 0) {
            this.showFormError = false;
            const values = this.form.value;
            values.extra.interests = this.interestsSelected;
            values.extra.currentYearStudying = (values.extra.currentYearStudying) ? Number(values.extra.currentYearStudying) : null;
            values.extra.joinTeam = Number(values.extra.joinTeam);
            values.extra.fromRosario = Number(values.extra.fromRosario);
            values.extra.isGraduated = Number(values.extra.isGraduated);

            this.store.dispatch(new UpdateProfile({user: values}));
        } else {
            this.showFormError = true;
        }
    }

    changeGraduated() {
        if (!this.form.value.extra.isGraduated) {
            const extra: FormGroup = this.form.controls.extra as FormGroup;
            extra.controls.currentYearStudying.setValidators([Validators.required, Validators.min(1), Validators.max(6)]);
        } else {
            const extra: FormGroup = this.form.controls.extra as FormGroup;
            extra.controls.currentYearStudying.setErrors(null);
            extra.controls.currentYearStudying.clearValidators();
            extra.controls.currentYearStudying.updateValueAndValidity();
        }
    }

    changeUniversity(data) {
        this.isFromUNR = data.currentTarget.checked;
        const extra: FormGroup = this.form.controls.extra as FormGroup;
        if (!this.isFromUNR) {
            extra.controls.university.setValue('');
        } else {
            extra.controls.university.setValue('UNR');
        }
    }

    selectInterest(interest: Interest) {
        const idx = this.interestsSelected.findIndex((e: Interest) => e.id === interest.id);
        (idx > -1) ? this.interestsSelected.splice(idx, 1) : this.interestsSelected.push(interest);
    }

    isChecked(interest) {
        if (this.user.extra['interests']) {
            return (this.user.extra['interests'].find(i => Number(i.id) === Number(interest.id)));
        } else {
            return false;
        }
    }

}
