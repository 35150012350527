import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Video} from '../../../models/video.model';
import {tap, throttleTime} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {User} from '../../../models/user.model';
import {UserVideo} from '../../../models/user-videos.model';
import {PutUserLoggedVideo} from '../../../ngrx/user-logged/user-logged.actions';
import * as moment from 'moment';
import Player from '@vimeo/player';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-vimeo-player',
    templateUrl: './vimeo-player.component.html',
    styleUrls: ['./vimeo-player.component.scss'],
})
export class VimeoPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('akademi_vimeo_player', {static: false}) playerRef: ElementRef;
    @Input() video: Video;
    @Input() type = 'client';
    @Input() stop: Observable<void>;
    @Output() finished = new EventEmitter();
    user: User;
    userVideos: UserVideo[] = [];

    initTime = 0;
    storeSub: Subscription = new Subscription();
    videoLink = '';
    player: Player;

    // own
    private stopSubscription: Subscription;
    timeUpdateObs$: Subject<VimeoTimeEventInterface> = new Subject<VimeoTimeEventInterface>();

    constructor(private store: Store<AppState>) {
    }

    async ngOnInit() {
        this.videoLink = `${environment.urlVimeo}${this.video.link}?&autoplay=1`;
        setTimeout(() => this.getStore(), 500);
        this.timeUpdateSub();
        if (this.type === 'client') {
            this.stopSubscription = this.stop.subscribe(() => {
                this.player.pause();
            });
        }
    }

    ngOnDestroy(): void {
        this.timeUpdateObs$.unsubscribe();
        this.storeSub.unsubscribe();
        if (this.type === 'client') {
            this.stopSubscription.unsubscribe();
        }

        this.player.off('ended');
        this.player.off('timeupdate');
        this.player.off('play');
    }

    ngAfterViewInit(): void {
        this.player = new Player(this.playerRef.nativeElement);
        this.player.setCurrentTime(this.initTime);

        this.player.on('timeupdate', (event: VimeoTimeEventInterface) => {
            this.timeUpdateObs$.next(event);
        });

        this.player.on('play', (event: VimeoTimeEventInterface) => {
            this.play(event);
        });

        this.player.on('ended', (event) => {
            this.ended(event);
        });

    }

    // Inicia store redux
    getStore() {
        this.storeSub = this.store.select('userLogged')
            .subscribe((userLoggedData) => {
                this.user = userLoggedData.user;
                this.userVideos = userLoggedData.userVideos;

                const uv: UserVideo = this.userVideos.find((uvv) => uvv.videoId === this.video.id);
                if (uv) {
                    this.initTime = uv.watchedTime;
                    if (this.player) {
                        this.player.setCurrentTime(this.initTime);
                    }
                } else {
                    this.putUserVideo();
                }
            });
    }

    ended(event: VimeoTimeEventInterface) {
        this.putUserVideo(Math.round(event.seconds), true);
    }


    timeUpdateSub() {
        if (this.type === 'client') {
            this.timeUpdateObs$.pipe(
                throttleTime(5000),
                tap(data => console.log(data))
            ).subscribe((event: VimeoTimeEventInterface) => {
                if (event.percent > 0.90) {
                    this.putUserVideo(Math.round(event.seconds), true);
                } else {
                    this.putUserVideo(Math.round(event.seconds));
                }
            });
        }
    }

    // Actualiza Cuanto tiempo de video vio el usuario
    putUserVideo(currentTime = 0, end: boolean = false) {
        if (this.type === 'client') {
            const userVideo = new UserVideo();
            if (end) {
                userVideo.finishedAt = moment().format();
                this.finished.emit();
            }
            userVideo.videoId = this.video.id;
            userVideo.watchedTime = Math.round(currentTime);
            this.store.dispatch(new PutUserLoggedVideo({userVideo}));
        }
    }

    play(event: VimeoTimeEventInterface) {
        if (this.type === 'client') {
            this.putUserVideo(Math.round(event.seconds));
        }
    }
}
