import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends EntityService {

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);
    }

    resource = 'tasks';
    className = 'task';

    transformToModel(obj) {
        return obj;
    }
}
