import {Routes} from '@angular/router';
import {CourseFormPage} from './course-form/course-form.page';
import {CourseShowPage} from './course-show/course-show.page';
import {CourseListPage} from './course-list/course-list.page';
import {VideoFormPage} from '../video/video-form/video-form.page';
import { CourseVideoListPage } from './course-video-list/course-video-list.page';

export const CourseRoutes: Routes = [
    {
        path: 'show/:id',
        component: CourseShowPage
    },
    {
        path: 'form',
        component: CourseFormPage
    },
    {
        path: 'form/:id',
        component: CourseFormPage
    },
    {
        path: 'list',
        component: CourseListPage
    },
    {
        path: ':course-id/sections/:id/videos',
        component: CourseVideoListPage
    },
    {
        path: ':course-id/sections/:section-id/videos/form',
        component: VideoFormPage
    },
    {
        path: ':course-id/sections/:section-id/videos/:video-id',
        component: VideoFormPage
    }
];

