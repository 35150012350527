import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Course} from '../../../models/course.model';
import {Section} from '../../../models/section.model';
import {ModalController} from '@ionic/angular';
import {FormTypes, resetForm} from '../../../helpers/auxiliar';
import {SectionService} from '../../../services/section.service';

@Component({
    selector: 'app-section-form',
    templateUrl: './section-form.page.html',
    styleUrls: ['./section-form.page.scss'],
})
export class SectionFormPage implements OnInit {
    @Input() section: Section;
    @Input() course: Course;

    form: FormGroup;
    formType: string;

    constructor(private fb: FormBuilder,
                private modal: ModalController,
                private service: SectionService) {
    }

    ngOnInit() {
        this.setFormType();
        this.createForm();
    }


    setFormType() {
        this.formType = (this.section) ? FormTypes.edit : FormTypes.new;
    }

    createForm() {
        if (this.formType === FormTypes.new) {
            this.form = this.fb.group({
                title: ['', Validators.required],
            });
        }

        if (this.formType === FormTypes.edit) {
            this.form = this.fb.group({
                title: [this.section.title, Validators.required],
            });
        }

    }

    submit() {
        if (this.form.valid && this.formType === FormTypes.new) {
            const section = new Section();

            section.title = this.form.value.title;
            section.courseId = this.course.id;

            this.service.save(section)
                .subscribe((sec) => {
                    resetForm(this.form);
                    this.modal.dismiss();
                });
        }

        if (this.form.valid && this.formType === FormTypes.edit) {
            this.section.title = this.form.get('title').value;

            this.service.update(this.section.id, this.section)
                .subscribe((sec) => {
                    this.modal.dismiss();
                });
        }
    }

}
