import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServerResponse} from '../../../shared/models/response.interface';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {NoShow} from '../../../ngrx/ui/ui.actions';
import {AuthService} from '../../../services/auth.service';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

    public form: FormGroup;
    public submitted = false;

    showError = false;

    constructor(private fb: FormBuilder,
                private nav: NavController,
                private store: Store<AppState>,
                private service: AuthService,
                private router: Router) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    submit() {
        this.submitted = true;
        if (this.form.valid) {
            this.service.login(this.form.value).subscribe((resp: ServerResponse) => {
                    this.store.dispatch(new NoShow());
                    if (!resp.data.enable) {
                        this.nav.navigateRoot(['/user/code']);
                    } else {
                        if (this.service.hasRole('admin')) {
                            this.nav.navigateRoot(['/admin']);
                        } else {
                            this.nav.navigateRoot(['/']);
                        }
                    }
                },
                (error) => {
                   
                    this.store.dispatch(new NoShow());
                    this.showError = true;
                    setTimeout(() => this.showError = false, 2000);
                });
        }
    }
}
