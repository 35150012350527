import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {environment} from '../../environments/environment';
import {LoadStart, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {TypedJSON} from "typedjson";
import {Course} from "../models/course.model";
import {catchError, map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(@Inject(HttpClient) protected http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
    }

    
    public getData() {
        return this.http.get(`${environment.urlRoot}/data`)
    }

}
