import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {VideoShowPage} from './video-show/video-show.page';
import {VideoFormPage} from './video-form/video-form.page';


@NgModule({
    declarations: [
        VideoShowPage,
        VideoFormPage
    ],
    entryComponents: [
        VideoShowPage,
        VideoFormPage
    ],
    exports: [
        VideoShowPage,
        VideoFormPage
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class VideoModule {
}
