import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Dimension} from '../../../models/dimension.model';
import {getS3Resource} from '../../auxiliar';

declare var jwplayer: any;

@Component({
    selector: 'app-jwplayer',
    templateUrl: './jwplayer.component.html',
    styleUrls: ['./jwplayer.component.scss'],
})
export class JwplayerComponent implements OnInit {

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
    }

}
