import {FormlyFieldConfig} from '@ngx-formly/core';

export const fields: FormlyFieldConfig[] = [
    {
        fieldGroup: [
            {
                key: 'password',
                type: 'input',
                templateOptions: {
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Must be at least 3 characters',
                    required: true,
                    minLength: 3,
                },
            },
            {
                key: 'extra.name',
                type: 'input',
                templateOptions: {
                    label: 'Nombre',
                    placeholder: 'Nombre',
                    required: true,
                },
            },
            {
                key: 'extra.lastname',
                type: 'input',
                templateOptions: {
                    label: 'Apellido',
                    placeholder: 'Apellido',
                    required: true,
                }
            }
        ]
    }
];
