import {Routes} from '@angular/router';
import { CarouselListPage } from './carousel-list/carousel-list.page';
import { CarouselFormPage } from './carousel-form/carousel-form.page';

export const CarouselRoutes: Routes = [
    {
        path: 'list',
        component: CarouselListPage
    },
    {
        path: 'form',
        component: CarouselFormPage
    },
    {
        path: 'form/:id',
        component: CarouselFormPage
    }
];

