import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {

  opt = 'data';
  environment = environment;

  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    this.opt = (this.router.snapshot.queryParamMap.get('tab')) ? this.router.snapshot.queryParamMap.get('tab') : 'data';
  }



}
