import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CourseShowPage} from './course-show/course-show.page';
import {CourseFormPage} from './course-form/course-form.page';
import {CourseListPage} from './course-list/course-list.page';
import {SharedModule} from '../../shared/shared.module';
import {VideoModule} from '../video/video.module';
import {SectionFormPage} from '../section/section-form/section-form.page';
import { CourseVideoListPage } from './course-video-list/course-video-list.page';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        VideoModule
    ],
    declarations: [
        CourseShowPage,
        CourseFormPage,
        CourseListPage,
        SectionFormPage,
        CourseVideoListPage
    ], entryComponents: [
        SectionFormPage
    ]
})

export class CourseModule {}
