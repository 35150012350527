import * as fromUi from './ui/ui.reducer';
import * as fromUserLogged from './user-logged/user-logged.reducer';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {UiEffects} from './ui/ui.effect';
import {UserLoggedEffects} from './user-logged/user-logged.effect';
import {UserLoggedActionTypes} from './user-logged/user-logged.actions';


export interface AppState {
    ui: fromUi.State;
    userLogged: fromUserLogged.State;
}

export const AppReducers: ActionReducerMap<AppState> = {
    ui: fromUi.reducer,
    userLogged: fromUserLogged.reducer,
};

export const effectsArr: any[] = [
    UiEffects,
    UserLoggedEffects
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) =>  {
        switch (action.type) {
            case UserLoggedActionTypes.LogoutUser:
                state = undefined;
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [debug];
