import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {Rol} from './rol.model';
import {ExtraUser} from './extra-user.model';

@jsonObject
export class User {
    @jsonMember
    id: number;
    @jsonMember
    name: string;
    @jsonMember
    email: string;
    @jsonMember
    password: string;
    @jsonMember
    passwordConfirmation: string;
    @jsonMember
    accessToken: string;
    @jsonMember
    enable: number;
    @jsonMember
    activationCode: string;
    @jsonArrayMember(Rol)
    roles: Rol[];
    @jsonMember
    extra: ExtraUser;
}
