import {NgModule} from "@angular/core"
import {Routes, RouterModule} from "@angular/router"
import {PlatformDashboardPage} from "./dashboard/platform-dashboard.page"
import {MainPage} from "./main/main.page"
import {CourseDetailPage} from "./course-detail/course-detail.page"
import {CourseShowPage} from "./course-show/course-show.page"
import {AuthGuard} from "../helpers/auth.guard"
import {ProfilePage} from "../shared/components/profile/profile.page"
import {ExamPage} from "./exam/exam.page"
import {ExamDetailPage} from "./exam-detail/exam-detail.page"
import {CertificationPage} from "./user/certification/certification.page"
import {CreditCardPage} from "./payments/credit-card/credit-card.page"
import {PaymentMethodPage} from "./payments/payment-method/payment-method.page"
import { PaymentPointsPage } from "./payments/payment-points/payment-points.page"
import { PaymentDetailPage } from "./payments/payment-detail/payment-detail.page"
import { SubscriptionMainPage } from "./subscription/subscription-main/subscription-main.page"
import { SubscriptionPaymentPage } from "./subscription/subscription-payment/subscription-payment.page"
import { SubscriptionMethodPage } from "./subscription/subscription-method/subscription-method.page"
import { SubscriptionPaymentsPointsPage } from "./subscription/subscription-payments-points/subscription-payments-points.page"
import { SubscriptionWireTransferPage } from "./subscription/subscription-wire-transfer/subscription-wire-transfer.page"
import { SubscriptionPayPalPage } from "./subscription/subscription-pay-pal/subscription-pay-pal.page"
import { NotificationPage } from "./notifications/notification/notification.page"

const routes: Routes = [
    {
        path: "",
        component: PlatformDashboardPage,
        children: [
            {
                path: "",
                component: MainPage,
                canActivate: [AuthGuard],
            },
            {
                path: "notification/:id",
                component: NotificationPage,
                canActivate: [AuthGuard],
            },
            {
                path: "course/detail/:id",
                component: CourseDetailPage,
                canActivate: [AuthGuard],
            },
            {
                path: "course/show/:id",
                component: CourseShowPage,
                canActivate: [AuthGuard],
            },
            {
                path: "profile",
                component: ProfilePage,
                canActivate: [AuthGuard],
            },
            {
                path: "exam/detail/:id",
                component: ExamDetailPage,
                canActivate: [AuthGuard],
            },
            {
                path: "exam/:id",
                component: ExamPage,
                canActivate: [AuthGuard],
            },
            {
                path: "credit-card/:id",
                component: CreditCardPage,
                canActivate: [AuthGuard],
            },
            {
                path: "payment-main/:id",
                component: PaymentMethodPage,
                canActivate: [AuthGuard],
            },
            {
                path: "payment-points/:id",
                component: PaymentPointsPage,
                canActivate: [AuthGuard],
            },
            {
                path: "payment-detail/:id",
                component: PaymentDetailPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-main",
                component: SubscriptionMainPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-method",
                component: SubscriptionMethodPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-payment-credit-card/:id",
                component: SubscriptionPaymentPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-payments-points/:id",
                component: SubscriptionPaymentsPointsPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-wire-transfer/:id",
                component: SubscriptionWireTransferPage,
                canActivate: [AuthGuard],
            },
            {
                path: "subscription-pay-pal/:id",
                component: SubscriptionPayPalPage,
                canActivate: [AuthGuard],
            },

            
        ],
       
    },
  {
    path: 'notification',
    loadChildren: () => import('./notifications/notification/notification.module').then( m => m.NotificationPageModule)
  },

  

]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlatformRoutingModule {}
