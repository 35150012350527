import {AbstractControl, FormGroup} from '@angular/forms';

export function VideoValidate(control: AbstractControl) {
    const value = control.value;
    if (value instanceof File && (value.type.search('video') > -1)) {
        return null;
    }

    return {invalidVideo: true};
}


export function ImgValidate(control: AbstractControl) {
    const value = control.value;
    if (value === null) {
        return null;
    }

    if (value instanceof File && (value.type.search('image') > -1)) {
        return null;
    }

    return {invalidImg: true};
}


export function checkPasswordsValidate(pass: string, confirmPass: string) {
    return (formGroup: FormGroup) => {
        const passwordInput = formGroup.controls[pass],
            passwordConfirmationInput = formGroup.controls[confirmPass];
        if (passwordInput.value !== passwordConfirmationInput.value) {
            return passwordConfirmationInput.setErrors({notEquivalent: true});
        } else {
            return passwordConfirmationInput.setErrors(null);
        }
    };
}

export function greaterThan(max, min) {
    return (formGroup: FormGroup) => {
        const maxInput = formGroup.controls[max].value;
        const minInput = formGroup.controls[min].value;

        if (Number(minInput) > Number(maxInput)) {
            return formGroup.controls[min].setErrors({grater: true});
        } else {
            return null;
        }
    };
}

