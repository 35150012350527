import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {Question} from './question.model';

@jsonObject
export class Exam {
    @jsonMember
    id: number;
    @jsonMember
    minutesBetweenAttemps: number;
    @jsonMember
    minutesTimeLimit: number;
    @jsonMember
    minCorrectAnswersToPass: number;
    @jsonMember
    title: string;
    @jsonMember
    type: string;
    @jsonMember
    numbersOfQuestions: number;
    @jsonArrayMember(Question)
    questions: Question[] = [];
    @jsonMember
    videoId: number;
    @jsonMember
    courseId: number;
    @jsonMember
    status: number;
}