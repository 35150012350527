import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ExamService} from '../../services/exam.service';
import {Exam} from '../../models/exam.model';
import {ServerResponse} from '../../shared/models/response.interface';
import moment from 'moment';
import {NavController} from '@ionic/angular';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-exam-detail',
    templateUrl: './exam-detail.page.html',
    styleUrls: ['./exam-detail.page.scss'],
})
export class ExamDetailPage implements OnInit {

    exam: Exam;

    enabled: boolean;
    timeMessage: boolean;
    approvedMessage: boolean;

    check = false;
    examAttemp: string;


    constructor(private route: ActivatedRoute,
                private service: ExamService,
                private nav: NavController) {
    }


    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.getData(id);

    }

    getData(id) {
        this.service.get(id)
            .pipe(first())
            .subscribe(e => this.exam = e);

        this.service.checkExam(id)
            .pipe(first())
            .subscribe((d: ServerResponse) => {
                console.log(d);
                this.check = true;
                this.enabled = d.data.enabled;
                if (!d.data.enabled && d.data.timeEnabled) {
                    const utc = moment.utc(d.data.timeEnabled.date);
                    this.timeMessage = true;
                    this.examAttemp = moment(utc).local().format('DD-MM-YYYY HH:mm:ss');
                }

                if (!d.data.enabled && d.data.approved) {
                    this.approvedMessage = true;
                }
            });
    }

    goToExam() {
        this.nav.navigateRoot([`/exam/${this.exam.id}`]);
    }
}
