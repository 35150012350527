import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Observable, Subscription} from 'rxjs';
import {Advertising} from '../../../models/advertising.model';
import Player from '@vimeo/player';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-adv-player',
    templateUrl: './advertising-player.component.html',
    styleUrls: ['./advertising-player.component.scss'],
})
export class AdvertisingPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('akademi_vimeo_player_adv', {static: false}) playerRef: ElementRef;
    @Output() finished = new EventEmitter();
    @Input() stop: Observable<void>;
    @Input() advSelected: Advertising;
    advs: Advertising[];

    storeSub: Subscription = new Subscription();
    player: Player;
    videoLink = '';
    timeWatched: number = 0;


    // own
    private stopSubscription: Subscription;

    constructor(private store: Store<AppState>) {
    }

    async ngOnInit() {
        this.getStore();
        if (this.advs.length === 0) {
            this.ended();
        } else {
            await this.setSource();
            if (this.stop) {
                this.stopSubscription = this.stop.subscribe(() => {
                    this.player.pause();
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (typeof this.playerRef !== undefined) {
            return;
        }

        this.storeSub.unsubscribe();
        if (this.stop) {
            this.stopSubscription.unsubscribe();
        }

        this.player.off('ended');
        this.player.off('timeupdate');
        this.player.off('seeked');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (typeof this.playerRef === undefined) {
                return;
            }
            this.player = new Player(this.playerRef.nativeElement);
            this.player.play();

            this.player.on('ended', (event) => {
                this.ended();
            });

            this.player.on('timeupdate', (data) => {
                if (data.seconds - 1 < this.timeWatched && data.seconds > this.timeWatched) {
                    this.timeWatched = data.seconds;
                }
            });

            this.player.on('seeked', (data) => {
                if (this.timeWatched < data.seconds) {
                    this.player.setCurrentTime(this.timeWatched);
                }
            });
        }, 500);
    }

    async setSource() {
        if (!this.advs || this.advs.length === 0) {
            this.ended();
        } else {
            const idx = Math.floor(Math.random() * ((this.advs.length - 1) + 1));
            this.videoLink = `${environment.urlVimeo}${this.advs[idx].link}?&autoplay=1`;
        }
    }


    // Inicia store redux
    getStore() {
        if (this.advSelected) {
            this.advs = [this.advSelected];
        } else {
            this.storeSub = this.store.select('userLogged')
                .subscribe((userLoggedData) => {
                    this.advs = userLoggedData.userAdvs;
                });
        }
    }

    ended() {
        this.finished.emit();
    }

    error(error) {
        this.ended();
    }


}
