import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {AdvertisingListPage} from './advertising-list/advertising-list.page';
import {AdvertisingFormPage} from './advertising-form/advertising-form.page';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
      AdvertisingListPage,
      AdvertisingFormPage
  ]
})
export class AdvertisingModule {}
