import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CourseService} from '../../../services/course.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ServerResponse} from '../../../shared/models/response.interface';
import Canvg from 'canvg';

@Component({
    selector: 'app-certification',
    templateUrl: './certification.page.html',
    styleUrls: ['./certification.page.scss'],
})
export class CertificationPage implements OnInit {

    svgImg: any;

    @ViewChild('canvas', { static: false }) canvas;
    @ViewChild('link', { static: false }) link;
    context: CanvasRenderingContext2D;
    renderedCanvas;

    constructor(private courseService: CourseService,
                private router: ActivatedRoute,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const courseId = this.router.snapshot.paramMap.get('id');
        this.courseService.getCertification(courseId)
            .subscribe((resp: ServerResponse) => {
                // this.svgImg = this.sanitizer.bypassSecurityTrustHtml(resp.data);
                this.svgImg = resp.data;
                this.setSvg();
            });
    }

    async setSvg() {
        this.context = this.canvas.nativeElement.getContext('2d');
        this.renderedCanvas = await Canvg.from(
            this.context,
            this.svgImg
        );
        this.renderedCanvas.start();
    }

    dowload() {
        console.log('1');
        const link = this.link;
        link.nativeElement.setAttribute('download', 'certificado.png');
        link.nativeElement.setAttribute('href', this.canvas.nativeElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'));
    }


}
