import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionService} from '../../../services/question.service';
import {Question} from '../../../models/question.model';
import {Answer} from '../../../models/answer.model';
import {AnswerService} from '../../../services/answer.service';
import {FormTypes} from '../../../helpers/vars';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-answer-form',
    templateUrl: './answer-form.page.html',
    styleUrls: ['./answer-form.page.scss'],
})
export class AnswerFormPage implements OnInit, OnDestroy {
    @Input() answer: Answer;

    form: FormGroup;
    formType: string;

    constructor(private fb: FormBuilder) {
    }

    async ngOnInit() {
        this.createForm();
    }

    ngOnDestroy() {
    }

    createForm() {
        this.form = this.fb.group({
            description: [this.answer.description, Validators.required],
        });
    }

    checkForm() {
        this.answer.description = this.form.value.description;
        this.form.markAllAsTouched();
        return this.form.valid;
    }
}
