export const environment = {
  production: true,
  urlRoot: 'https://api.impulsar.tv/api/v1',
  urlBucket: 'https://api.impulsar.tv/storage/',
  urlVimeo: 'https://player.vimeo.com/video/',
  client: 'impulsar',
  ML_PUB_KEY: 'APP_USR-891c0fd3-6f84-4a97-aaf5-f257125a0532',
  instagram: "impulso.fcm",
  phone: "543624905274",
  app_version: "3"
};
