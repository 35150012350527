import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Course} from '../../../models/course.model';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ModalController, NavController} from '@ionic/angular';
import {CourseService} from '../../../services/course.service';
import {CrudListBaseComponent} from '../../crud-base/crud-list-base.component';

@Component({
    selector: 'app-course-list',
    templateUrl: './course-list.page.html',
    styleUrls: ['./course-list.page.scss'],
})
export class CourseListPage extends CrudListBaseComponent implements OnInit {

    entities: Course[] = [];
    subscription: Subscription = new Subscription();


    constructor(private store: Store<AppState>,
                public modalController: ModalController,
                protected nav: NavController,
                protected service: CourseService,
                private route: Router) {
        super(service, modalController);
    }

    select(item) {
        if (item instanceof Course && item.type !== 'live') {
            this.nav.navigateRoot(['/admin/courses/show', item.id]);
        }
    }

    new() {
        this.nav.navigateRoot(['/admin/courses/form']);
    }

    redirect(url) {
        this.nav.navigateRoot([url]);
    }
}
