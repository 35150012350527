import { Component, OnInit } from '@angular/core';
import {Subscription} from "src/app/models/subscription.model"
import {LoadStart, NoShow} from "src/app/ngrx/ui/ui.actions"
import {SubscriptionService} from "src/app/services/subscription.service"
import {Store} from "@ngrx/store"
import {AppState} from "../../../ngrx/app.reducer"
import {NavController} from "@ionic/angular"

@Component({
  selector: 'app-subscription-method',
  templateUrl: './subscription-method.page.html',
  styleUrls: ['./subscription-method.page.scss'],
})
export class SubscriptionMethodPage implements OnInit {
    alert: boolean = false
    subscriptionMethod: string = 'credit_card_payment'
  constructor(private SubscriptionService: SubscriptionService,
    private nav: NavController,) { }

 
    ngOnInit () {
        
    }
  
  onSelect(subscription_method_id) {
    this.alert =  false
    this.subscriptionMethod = subscription_method_id
}

onSubmit() {    
    this.SubscriptionService.newSubscriptionMethod({
        subscription_method: this.subscriptionMethod
    }).subscribe((data: any) => {
      
        if(data.type == 'credit_card_payment'){
            this.nav.navigateRoot([`/subscription-payment-credit-card/${data.id}`])
        } 
        if(data.type == 'authorized_points_payment'){
            this.nav.navigateRoot([`/subscription-payments-points/${data.id}`])
        }

        if(data.type == 'branch_payment'){
            this.nav.navigateRoot([`/subscription-wire-transfer/${data.id}`])
        }
        
        if(data.type == 'pay_pal_subscription'){
            this.nav.navigateRoot([`/subscription-pay-pal/${data.id}`])
        }
        
    })
}

}
