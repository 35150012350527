import {Component, OnInit} from "@angular/core"
import {Router, ActivatedRoute} from "@angular/router"
import {Course} from "src/app/models/course.model"
import {Payment} from "src/app/models/payment.model"
import {CourseService} from "src/app/services/course.service"
import {PaymentsService} from "src/app/services/payments.service"

@Component({
    selector: "app-payment-method",
    templateUrl: "./payment-method.page.html",
    styleUrls: ["./payment-method.page.scss"],
})
export class PaymentMethodPage implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private coursService: CourseService,
        private PaymentService: PaymentsService
    ) {}

    payment: Payment = {
        id: null,
        payment_id: "",
        course_id: "",
        payment_status: "",
        payment_status_detail: "",
        payment_method_id: 0,
        transaction_amount: "",
        external_resource_url: "",
        user_id: "",
        email: "",
        created_at: null
    }
    course: Course
    alert: boolean = false
    message: string = ""

    ngOnInit() {
        const id = this.route.snapshot.params.id
        this.getData(id)
    }

    getData(id) {
        this.coursService.get(id).subscribe((c) => this.loadData(c))
    }

    loadData(c) {
        this.course = c
        if (this.course.modality == 'pay') {
            this.PaymentService.getCoursePayment(this.course.id).subscribe((payment: Payment) =>
                this.setPayment(payment)
            )
        }
    }

    setPayment(payment: Payment) {
        if (payment) {
            this.payment = payment
            if (payment.payment_status == "approved") {
                ;(this.alert = true),
                    (this.message =
                        "De acuerdo a nuestros registros este curso se encuentra pago, no deberías realizar un nuevo pago")
            }

            if (payment.payment_status == "pending") {
                ;(this.alert = true),
                    (this.message =
                        "De acuerdo a nuestros registros este curso se encuentra en proceso de pago, no deberías realizar un nuevo pago")
            }
        } else {
            this.payment.payment_method_id = 0
        }
    }

    onSelect(payment_method_id) {
        this.alert= false
        this.payment.payment_method_id = payment_method_id
    }

    onSubmit() {
        this.PaymentService.setPaymentMethod({
            course_id: this.course.id,
            payment_method_id: this.payment.payment_method_id,
        }).subscribe((data: any) => {
            if (data.payment_method_id == 1) {
                this.router.navigate([`credit-card/${this.course.id}`])
            }
            if (data.payment_method_id == 2) {
                this.router.navigate([`payment-points/${this.course.id}`])
            }
        })
    }
}
