import { User } from "../../models/user.model";
import { UserCourse } from "../../models/user-course.model";
import { UserLoggedActions, UserLoggedActionTypes } from "./user-logged.actions";
import { UserVideo } from "../../models/user-videos.model";
import { Advertising } from "../../models/advertising.model";
import { Notification } from "../../models/notification.model";

export interface State {
    user: User;
    userCourses: UserCourse[];
    userVideos: UserVideo[];
    userAdvs: Advertising[];
    userNotifications: Notification[];
}

export const initialState: State = {
    user: null,
    userCourses: [],
    userVideos: [],
    userAdvs: [],
    userNotifications: [],
};

export function reducer(state = initialState, action: UserLoggedActions): State {
    switch (action.type) {
        case UserLoggedActionTypes.SetUserLogged: {
            return {
                ...state,
                user: { ...action.payload.user },
            };
        }
        case UserLoggedActionTypes.SetUserLoggedCourses: {
            return {
                ...state,
                userCourses: [...action.payload.userCourses],
            };
        }
        case UserLoggedActionTypes.SetUserLoggedVideos: {
            return {
                ...state,
                userVideos: [...action.payload.userVideos],
            };
        }
        case UserLoggedActionTypes.SetUserLoggedAdvs: {
            return {
                ...state,
                userAdvs: [...action.payload.advs],
            };
        }
        case UserLoggedActionTypes.AddUserLoggedCourse: {
            return {
                ...state,
                userCourses: [...state.userCourses, action.payload.userCourse],
            };
        }
        case UserLoggedActionTypes.UpdateUserLoggedSuccess: {
            return {
                ...state,
                user: { ...action.payload.user },
            };
        }
        case UserLoggedActionTypes.SetUserLoggedNotifications: {
            return {
                ...state,
                userNotifications: [...action.payload.userNotifications],
            };
        }
        default: {
            return state;
        }
    }
}
