import 'reflect-metadata';
import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class JobHistory {
    @jsonMember
    id: number;
    @jsonMember
    jobCreateTime: string;
    @jsonMember
    jobProgressTime: string;
    @jsonMember
    jobFinishedTime: string;
    @jsonMember
    jobCancelledTime: string;
    @jsonMember
    extra: string;
    @jsonMember
    state: number;
    @jsonMember
    action: string;
    @jsonMember
    jobId: number;
}
