import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    public errors: any;

    getErrors() {
        if (typeof this.errors === 'object') {
            const ObjError = {
                message: '',
                errors: []
            };

            ObjError.message = (this.errors.message) ? this.errors.message : '';
            if (this.errors.errors && Object.keys(this.errors.errors).length >= 1) {
                Object.keys(this.errors.errors).forEach((e) => {
                    if (Array.isArray(this.errors.errors[e])) {
                        this.errors.errors[e].forEach(el => ObjError.errors.push(el));
                    } else if (typeof e === 'string') {
                        ObjError.errors.push(e);
                    }
                });
            }
            return ObjError;
        }
    }
}
