import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskListPage } from './task-list/task-list.page';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
      TaskListPage
  ]
})
export class TaskModule {}
