import { Routes} from '@angular/router';

import { UserListPage } from './user-list/user-list.page';
import {UserFormPage} from './user-form/user-form.page';
import {UserShowPage} from './user-show/user-show.page';

export const UserRoutes: Routes = [
  {
    path: 'list',
    component: UserListPage
  },
  {
    path: 'form',
    component: UserFormPage
  },
  {
    path: 'show/:id',
    component: UserShowPage
  }
];


