import {Routes} from '@angular/router';
import {ExamListPage} from './exam-list/exam-list.page';
import {ExamFormPage} from './exam-form/exam-form.page';
import {QuestionFormPage} from './question-form/question-form.page';

export const ExamRoutes: Routes = [
    // {
    //     path: 'show/:id',
    //     component: null
    // },
    // {
    //     path: 'form',
    //     component: null
    // },
    {
        path: 'form/:type/:id',
        component: ExamFormPage
    },
    {
        path: 'form/:id',
        component: ExamFormPage
    },
    {
        path: 'list/:course-id',
        component: ExamListPage
    }
];

