import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Video} from '../../../models/video.model';
import {ActivatedRoute} from '@angular/router';
import {VideoService} from '../../../services/video.service';

@Component({
    selector: 'app-video-show',
    templateUrl: './video-show.page.html',
    styleUrls: ['./video-show.page.scss'],
})
export class VideoShowPage implements OnInit {

    public video: Video;

    constructor(private store: Store<AppState>,
                private route: ActivatedRoute,
                private service: VideoService) {
    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.service.get(id).subscribe(v => this.video = v);
    }

}
