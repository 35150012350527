import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Course} from '../../../models/course.model';
import {Section} from '../../../models/section.model';
import {ModalController} from '@ionic/angular';
import {SectionFormPage} from '../../section/section-form/section-form.page';
import {Subscription} from 'rxjs';
import {DeleteConfirmPage} from '../../../shared/components/delete-confirm/delete-confirm.page';
import {CourseService} from '../../../services/course.service';
import {SectionService} from '../../../services/section.service';

@Component({
    selector: 'app-course-show',
    templateUrl: './course-show.page.html',
    styleUrls: ['./course-show.page.scss'],
})
export class CourseShowPage implements OnInit, OnDestroy {

    course: Course;

    sections: Section[] = [];

    subscription: Subscription = new Subscription();
    subscriptionSection: Subscription = new Subscription();
    subscriptionSelectedSection: Subscription = new Subscription();

    selectedSection: Section;

    hiddenOrder = true;

    constructor(private route: ActivatedRoute,
                private store: Store<AppState>,
                private service: CourseService,
                private serviceSection: SectionService,
                public modalController: ModalController) {
    }

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.getCourse(id);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscriptionSection.unsubscribe();
        this.subscriptionSelectedSection.unsubscribe();
    }

    getCourse(id) {
        this.subscription = this.service.get(id).subscribe((c: Course) => this.course = c);
    }

    async addSection() {
        const modal = await this.modalController.create({
            cssClass: 'section-modal',
            component: SectionFormPage,
            componentProps: {
                course: this.course
            }
        });

        modal.onDidDismiss().then(() => this.getCourse(this.route.snapshot.params.id));

        return await modal.present();
    }

    async editSection(section: Section) {
        const modal = await this.modalController.create({
            component: SectionFormPage,
            componentProps: {
                section,
                course: this.course
            },
        });

        modal.onDidDismiss().then(() => this.getCourse(this.route.snapshot.params.id));

        return await modal.present();
    }

    isOpen(section: Section) {
        if (this.selectedSection && section) {
            return (section.id === this.selectedSection.id);
        }
    }

    async deleteSection(section: Section) {
        const modal = await this.modalController.create({
            component: DeleteConfirmPage,
            cssClass: 'delete-modal'
        });

        modal.onDidDismiss()
            .then((resp) => {
                if (resp.data === true) {
                    this.serviceSection.delete(section.id)
                        .subscribe(() => {
                            this.getCourse(this.route.snapshot.params.id);
                        });
                }
            });

        return await modal.present();
    }
}
