import { Routes } from '@angular/router';
import {TagListPage} from './tag-list/tag-list.page';
import {TagFormPage} from './tag-form/tag-form.page';

export const TagRoutes: Routes = [
  {
    path: 'list',
    component: TagListPage
  },
  {
    path: 'form/:id',
    component: TagFormPage
  },
  {
    path: 'form',
    component: TagFormPage
  }
];


