import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/app.reducer';
import { CourseService } from '../../../services/course.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamService } from '../../../services/exam.service';
import { Exam } from '../../../models/exam.model';
import { FormTypes } from '../../../helpers/vars';
import { first } from 'rxjs/operators';
import { greaterThan } from '../../../shared/validations/custom.validation';
import { NavController } from '@ionic/angular';
import { Question } from '../../../models/question.model';
import { QuestionFormPage } from '../question-form/question-form.page';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerResponse } from '../../../shared/models/response.interface';
import { MatDialog } from '@angular/material/dialog';
import { EditExamsConfirmationComponent } from '../edit-exams-confirmation/edit-exams-confirmation.component';
import { DeleteQuestionConfirmationComponent } from '../delete-question-confirmation/delete-question-confirmation.component';

@Component({
    selector: 'app-exam-list',
    templateUrl: './exam-form.page.html',
    styleUrls: ['./exam-form.page.scss'],
})
export class ExamFormPage implements OnInit {
    @ViewChildren('cmp') childComponents: QueryList<QuestionFormPage>;

    form: FormGroup;
    public exam: Exam;

    formType: string;
    examType: string;
    errorStatus = '';
    apbMessage = '';

    // exam id | course id | video id
    id: string;

    constructor(
        private router: ActivatedRoute,
        private fb: FormBuilder,
        private nav: NavController,
        private store: Store<AppState>,
        private courseService: CourseService,
        private examService: ExamService,
        public dialog: MatDialog) {

    }

    ngOnInit() {
        this.id = this.router.snapshot.paramMap.get('id');
        this.examType = this.router.snapshot.paramMap.get('type');

        if (this.examType) {
            this.formType = FormTypes.new;
            this.exam = new Exam();
            this.createForm();
        } else {
            this.formType = FormTypes.edit;
            this.getData();
        }
    }


    getData() {
        if (this.formType === FormTypes.edit) {
            this.examService.get(this.id)
                .pipe(first())
                .subscribe((e: Exam) => {
                    this.exam = e;
                    this.createForm();
                });
        }
    }

    createForm() {
        if (this.formType === FormTypes.new) {
            this.exam.minutesBetweenAttemps = 0;
            this.exam.title = (this.router.snapshot.queryParamMap.get('title')) ? (this.router.snapshot.queryParamMap.get('title')) : '';
            this.exam.minutesTimeLimit = 0;
            this.exam.minCorrectAnswersToPass = null;
            this.exam.numbersOfQuestions = null;
            this.exam.type = this.examType;
            this.exam.videoId = (this.examType === 'video') ? Number(this.id) : null;
            this.exam.courseId = (this.examType === 'course') ? Number(this.id) : null;
        }

        this.form = this.fb.group({
            minutesBetweenAttemps: [this.exam.minutesBetweenAttemps, [Validators.required, Validators.min(0)]],
            title: [this.exam.title, Validators.required],
            minutesTimeLimit: [this.exam.minutesTimeLimit, [Validators.required, Validators.min(0)]],
            minCorrectAnswersToPass: [this.exam.minCorrectAnswersToPass, [Validators.required, Validators.min(0)]],
            numbersOfQuestions: [this.exam.numbersOfQuestions, [Validators.required, Validators.min(1)]],
            videoId: [this.exam.videoId],
            courseId: [this.exam.courseId],
            status: [this.exam.status],
        }, {validators: greaterThan('numbersOfQuestions', 'minCorrectAnswersToPass')});
    }

    submit() {
        let allValids = true;
        this.childComponents.forEach((c) => {
            allValids = c.checkForm();
        });

        if (allValids) {
            if (this.formType === FormTypes.new) {
                if (this.form.valid) {
                    this.exam = Object.assign(this.exam, this.form.value);
                    this.examService.save(this.exam)
                        .pipe(first())
                        .subscribe((exam) => {
                            this.exam = Object.assign(this.exam, exam);
                            this.exam.status = 0;
                            this.formType = FormTypes.edit;
                            this.nav.navigateRoot([`/admin/exams/form/${exam.id}`]);
                        });
                }
            }

            if (this.formType === FormTypes.edit) {


                if (this.form.valid) {

                    this.exam = Object.assign(this.exam, this.form.value);
                    this.exam.status = this.exam.status?1:0;
                    this.examService.inProgressExamResolution(this.id).subscribe((r: ServerResponse) => {
                        if (r.data.length > 0) {
                            this.dialog.open(EditExamsConfirmationComponent, {
                                width: '500px',
                                data: { data: r.data }
                            }).afterClosed().subscribe(result => {
                                if (result) {
                                    this.update();
                                }
                            });
                        } else {
                            this.update();
                        }
                    });

                }
            }
        }
    }

    update() {
        this.examService.update(this.exam.id, this.exam)
            .pipe(first())
            .subscribe((exam) => this.exam.status = 0,
                (e: HttpErrorResponse) => {
                    this.errorStatus = e.error.message;
                    setTimeout(() => {
                        this.errorStatus = '';
                    }, 4000);
                }
            );
    }

    newQuestion() {
        const question = new Question();
        question.answers = [];

        this.exam.questions.push(question);
    }

    deleteQuestion(questionIndex) {
        if (confirm('¿Está seguro que desea borrar esta pregunta?')) {
            this.exam.questions.splice(questionIndex, 1);
        }

    }
}
