import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '../../../models/user.model';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-user-logged-menu',
    templateUrl: './user-logged-menu.component.html',
    styleUrls: ['./user-logged-menu.component.scss'],
})
export class UserLoggedMenuComponent implements OnInit, OnDestroy {
    @Input() site: string;
    @Output() adminMenu = new EventEmitter<any>();
    public showUserMenu = false;
    public user: User;
    userSub: Subscription = new Subscription();
    clicked = false;

    constructor(private store: Store<AppState>,
                private authService: AuthService,
                private router: Router,
                private nav: NavController,
                private auth: AuthService) {
    }

    showMenu() {
        this.clicked = true;
        this.showUserMenu = !this.showUserMenu;
        setTimeout(() => {
            this.clicked = false;
        }, 50);
    }

    @HostListener('document:click')
    clickout() {
        if (!this.clicked) {
            this.showUserMenu = false;
        }
    }

    ngOnInit(): void {
        this.showUserMenu = false;

        this.store.select('userLogged', 'user')
            .subscribe((user) => {
                this.user = user;
            });
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
    }

    logout() {
        this.authService.logout();
        this.nav.navigateRoot(['/login']);
    }

    isAdmin() {
        return this.auth.hasRole('admin');
    }

    goToAdmin() {
        this.nav.navigateRoot(['/admin']);
    }

    goToPlatform() {
        this.nav.navigateRoot(['/']);
    }

    profile() {
        this.nav.navigateRoot(['/profile']);
    }

    subscription () {
        this.nav.navigateRoot(['/subscription-main']);
    }

    emitEventMenuAdmin() {
        this.adminMenu.emit();
    }

}
