import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {environment} from '../../environments/environment';
import {LoadStart, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {TypedJSON} from "typedjson";
import {Carousel} from "../models/carousel.model";
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CarouselService extends EntityService {

    resource = 'carousels';

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);
    }

    update(id, body) {
        this.store.dispatch(new LoadStart());
        return this.http.post(`${environment.urlRoot}/${this.resource}/${id}`, body)
            .pipe(
                map((resp) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                })
            );
    }

    public transformToModel(obj) {
        const carouselSerializer = new TypedJSON(Carousel);
        return carouselSerializer.parse(obj);
    }

}
