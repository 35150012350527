import {Action} from '@ngrx/store';
import {User} from '../../models/user.model';
import {Course} from '../../models/course.model';
import {UserCourse} from '../../models/user-course.model';
import {UserVideo} from '../../models/user-videos.model';
import {Advertising} from '../../models/advertising.model';
import { Notification } from "../../models/notification.model";


export enum UserLoggedActionTypes {
    SetUserLogged = '[User] Set User',
    UpdateUserLoggedSuccess = '[User] Set User',
    SetUserLoggedCourses = '[User] Set User Logged Courses',
    SetUserLoggedVideos = '[User] Set User Logged Videos',
    SetUserLoggedAdvs = '[User] Set User Advs',
    StartCourse = '[User] Start Courses',
    PutUserLoggedVideo = '[User] Put User Logged Video',
    AddUserLoggedCourse = '[User] Add User Logged Course',
    LogoutUser = '[User] Logout User',
    UpdateProfile = '[User] Update Profile',
    SetUserLoggedNotifications = '[User] Set User Logged Notifications'
}


export class SetUserLogged implements Action {
    readonly type = UserLoggedActionTypes.SetUserLogged;

    constructor(public payload: { user: User }) {
    }
}

export class SetUserLoggedCourses implements Action {
    readonly type = UserLoggedActionTypes.SetUserLoggedCourses;

    constructor(public payload: { userCourses: UserCourse[] }) {
    }
}

export class SetUserLoggedVideos implements Action {
    readonly type = UserLoggedActionTypes.SetUserLoggedVideos;

    constructor(public payload: { userVideos: UserVideo[] }) {
    }
}

export class SetUserLoggedAdvs implements Action {
    readonly type = UserLoggedActionTypes.SetUserLoggedAdvs;

    constructor(public payload: { advs: Advertising[] }) {
    }
}

export class PutUserLoggedVideo implements Action {
    readonly type = UserLoggedActionTypes.PutUserLoggedVideo;

    constructor(public payload: { userVideo: UserVideo }) {
    }
}

export class AddUserLoggedCourse implements Action {
    readonly type = UserLoggedActionTypes.AddUserLoggedCourse;

    constructor(public payload: { userCourse: UserCourse }) {
    }
}

export class StartCourse implements Action {
    readonly type = UserLoggedActionTypes.StartCourse;

    constructor(public payload: { course: Course }) {
    }
}

export class LogoutUser implements Action {
    readonly type = UserLoggedActionTypes.LogoutUser;

    constructor() {
    }
}

export class UpdateProfile implements Action {
    readonly type = UserLoggedActionTypes.UpdateProfile;

    constructor(public payload: { user: any }) {
    }
}

export class UpdateUserLoggedSuccess implements Action {
    readonly type = UserLoggedActionTypes.UpdateUserLoggedSuccess;

    constructor(public payload: { user: any }) {
    }
}

export class SetUserLoggedNotifications implements Action {
    readonly type = UserLoggedActionTypes.SetUserLoggedNotifications;

    constructor(public payload: { userNotifications: Notification[] }) {
    }
}


export type UserLoggedActions =
    | SetUserLogged
    | SetUserLoggedCourses
    | SetUserLoggedVideos
    | PutUserLoggedVideo
    | StartCourse
    | AddUserLoggedCourse
    | LogoutUser
    | UpdateProfile
    | UpdateUserLoggedSuccess
    | SetUserLoggedAdvs
    | SetUserLoggedNotifications

