import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {LoadStart, NoShow} from '../ngrx/ui/ui.actions';
import {environment} from '../../environments/environment';
import {TypedJSON} from 'typedjson';
import {Tag} from '../models/tag.model';
import {map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';

@Injectable({
  providedIn: 'root'
})
export class TagService extends EntityService {

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(Store) store: Store<AppState>) {
    super(http, store);
  }

  resource = 'tags';
  className = 'tag';

  getAllTagsNoPagination() {
    this.store.dispatch(new LoadStart());
    return this.http.get(`${environment.urlRoot}/${this.resource}`)
        .pipe(
            map((resp: ServerResponse) => {
              this.store.dispatch(new NoShow());
              return  resp.data.map((e) => this.transformToModel(e));
            }));
  }

  transformToModel(obj) {
    const serializer = new TypedJSON(Tag);
    return serializer.parse(obj);
  }

}
