import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CommentsService} from '../../../services/comments.service';
import {Course} from '../../../models/course.model';
import {Comment} from '../../../models/comment.model';
import {AppState} from '../../../ngrx/app.reducer';
import {Store} from '@ngrx/store';
import {User} from '../../../models/user.model';
import {ServerResponse} from '../../../shared/models/response.interface';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit, OnDestroy {
    @Input() father: Comment;
    @Input() course: Course;
    @Output() createComment = new EventEmitter();

    type = '';
    inputTitle = '';
    message = new FormControl('');
    user: User;
    sub$: Subscription = new Subscription();
    subStore$: Subscription = new Subscription();

    constructor(private service: CommentsService,
                private store: Store<AppState>) {
    }

    ngOnInit() {
        this.subStore$ = this.store.select('userLogged', 'user')
            .subscribe((user) => this.user = user);

        this.setType();
    }

    ngOnDestroy() {
        this.sub$.unsubscribe();
        this.subStore$.unsubscribe();
    }

    setType() {
        if (this.father) {
            this.type = 'response';
            this.inputTitle = 'Nueva respuesta';
        } else {
            this.type = 'new';
            this.inputTitle = 'Nuevo comentario';
        }
    }

    newComment() {
        const comment = new Comment();
        comment.message = this.message.value;
        comment.userId = this.user.id;
        if (this.type === 'response') {
            comment.commentId = this.father.id;
        } else {
            comment.courseId = this.course.id;
        }

        this.sub$ = this.service.addCourseComments(comment)
            .subscribe((resp: ServerResponse) => {
                this.createComment.emit(resp.data);
                this.clear();
            });
    }

    clear() {
        this.message.setValue('');
    }
}
