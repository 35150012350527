import {Video} from './video.model';
import {schema} from 'normalizr';
import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Section {
    @jsonMember
    id: number;
    @jsonMember
    title: string;
    @jsonMember
    courseId: number;
    @jsonArrayMember(Video)
    videos: Array<Video>;


    public static getSchema() {
        return new schema.Entity('sections', {
            videos: [Video.getSchema()]
        });
    }

}
