import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Comment {
    @jsonMember
    id: number;
    @jsonMember
    message: string;
    @jsonMember
    username: string;
    @jsonMember
    createdAt: string;
    @jsonMember
    responsesCount: number;
    commentId: number;
    courseId: number;
    userId: number;
    responses: any[];
}
