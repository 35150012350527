import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class UserVideo {
    @jsonMember
    videoId: number;
    @jsonMember
    createdAt: string;
    @jsonMember
    finishedAt: string;
    @jsonMember
    watchedTime: number;
}
