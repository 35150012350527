import {Component, OnInit} from "@angular/core"
import {ActivatedRoute} from "@angular/router"
import { NavController } from "@ionic/angular"
import { NoShow } from "src/app/ngrx/ui/ui.actions"
import {SubscriptionService} from "src/app/services/subscription.service"

@Component({
    selector: "app-subscription-payments-points",
    templateUrl: "./subscription-payments-points.page.html",
    styleUrls: ["./subscription-payments-points.page.scss"],
})
export class SubscriptionPaymentsPointsPage implements OnInit {
    payment_points = [
        {id: "rapipago", name: "Rapipago", secure_thumbnail: "assets/images/rapipago.png"},
        {id: "pagofacil", name: "Pago Facil", secure_thumbnail: "assets/images/pagofacil.png"},
    ]
    payment_point_selected = "rapipago"
    plan: any
    store: any

    constructor(private SubscriptionService: SubscriptionService, private nav: NavController, private route: ActivatedRoute) {}

    ngOnInit() {
        this.loadData()
    }

    async loadData() {
        this.SubscriptionService.getPlan().subscribe((plan: any) => (this.plan = plan.body))
    }

    selectPoint(payment_point) {
        this.payment_point_selected = payment_point
    }

    onSubmit() {
        const id = this.route.snapshot.params.id
        this.SubscriptionService.newSubscriptionMethodPaymentsPints({
            subscription_id: id,
            method_id: this.payment_point_selected,
            amount: this.plan.auto_recurring.transaction_amount * 6,
            description: this.plan.reason
        }).subscribe((response: any) => {
            this.nav.navigateRoot([`/subscription-main`])
        })
    }
}
