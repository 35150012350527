import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ImgValidate} from '../../../shared/validations/custom.validation';
import {Tag} from '../../../models/tag.model';
import {FormTypes} from '../../../helpers/auxiliar';
import {NavController} from '@ionic/angular';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {CourseService} from '../../../services/course.service';
import {TagService} from '../../../services/tag.service';
import {CrudFormBaseComponent} from '../../crud-base/crud-form-base.component';
import {first} from 'rxjs/operators';


@Component({
    selector: 'app-course-form',
    templateUrl: './course-form.page.html',
    styleUrls: ['./course-form.page.scss'],
})

export class CourseFormPage extends CrudFormBaseComponent implements OnInit {
    tags: any;
    tagsSelected: Tag[] = [];

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Descripcion',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            [
                'link',
                'unlink',
                'insertImage',
                'insertVideo'
            ]
        ],
        customClasses: []
    };

    showError: boolean;

    constructor(private fb: FormBuilder,
                private nav: NavController,
                protected router: ActivatedRoute,
                protected service: CourseService,
                private tagService: TagService) {
        super(service, router);
    }

    extraStore() {
        this.tagService.getAllTagsNoPagination()
            .pipe(first())
            .subscribe((tags) => {
                this.tags = tags;
                if (this.formType === FormTypes.edit) {
                    this.tagsSelected = this.entity.tags;
                }
            });
    }


    createForm() {
        if (this.formType === FormTypes.new) {
            this.form = this.fb.group({
                type: ['', Validators.required],
                modality: ['free', Validators.required],
                price: [0, Validators.required],
                title: ['', Validators.required],
                liveLink: ['', null],
                show: [false, null],
                description: ['', Validators.required],
                material: ['', null],
                image: [null, [Validators.required, ImgValidate]],
                code: ['', null]
            });
        } else {
            this.form = this.fb.group({
                type: [this.entity.type, Validators.required],
                modality: [this.entity.modality, Validators.required],
                price: [this.entity.price || 0, Validators.required],
                title: [this.entity.title, Validators.required],
                liveLink: [this.entity.liveLink, null],
                description: [this.entity.description, Validators.required],
                material: [this.entity.material, null],
                show: [this.entity.show, null],
                image: [null, [ImgValidate]],
                code: [this.entity.code, null]
            });
        }
    }


    handleImgFile(files) {
        this.form.get('image').markAllAsTouched();
        this.form.get('image').setValue(files[0]);
    }


    submit() {
        if (this.form.valid && this.tagsSelected.length > 0) {
            this.showError = false;
            const formData = new FormData();
            formData.append('title', this.form.get('title').value);
            formData.append('modality', this.form.get('modality').value);
            formData.append('price', this.form.get('price').value);
            formData.append('description', this.form.get('description').value);
            formData.append('material', this.form.get('material').value ? this.form.get('material').value : '');
            formData.append('type', this.form.get('type').value);
            formData.append('show', (this.form.get('show').value) ? '1' : '0');
            formData.append('tags', JSON.stringify(this.tagsSelected));
            formData.append('code', this.form.get('code').value ? this.form.get('code').value : '');
            if (this.form.get('liveLink').value) {
                formData.append('liveLink', this.form.get('liveLink').value);
            }
            if (this.form.get('image').value) {
                formData.append('image', this.form.get('image').value);
            }
            if (this.formType === FormTypes.new) {
                this.service.save(formData).subscribe(() => {
                    this.nav.navigateRoot(['/admin/courses/list']);
                });
            }
            if (this.formType === FormTypes.edit) {
                this.service.update(this.entity.id, formData).subscribe();
            }
        } else {
            this.showError = true;
            Object.values(this.form.controls).forEach(control => {
                if (control instanceof FormGroup) {
                    Object.values(control.controls).forEach(c => c.markAsTouched());
                } else {
                    control.markAsTouched();
                }
            });
        }

    }


    isTagSelected(tag) {
        if (tag instanceof Tag) {
            return (this.tagsSelected.find((item: Tag) => {
                return item.id === tag.id;
            }));
        }
    }


    selectTag(tag) {
        if (tag instanceof Tag) {
            const idx = this.tagsSelected.findIndex((item: Tag) => {
                return item.id === tag.id;
            });

            if (idx > -1) {
                this.tagsSelected.splice(idx, 1);
            } else {
                this.tagsSelected.push(tag);
            }
        }
    }
}

