import {Component, OnInit, Sanitizer} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FormTypes, resetForm} from '../../../helpers/auxiliar';
import {NavController} from '@ionic/angular';
import {CrudFormBaseComponent} from '../../crud-base/crud-form-base.component';
import {Certification} from '../../../models/certification.model';
import {CertificationService} from '../../../services/certification.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-certification-form',
    templateUrl: './certification-form.page.html',
    styleUrls: ['./certification-form.page.scss'],
})
export class CertificationFormPage extends CrudFormBaseComponent implements OnInit {

    entity: Certification;
    courseId: number;
    svgImg: any;

    constructor(private fb: FormBuilder,
                private nav: NavController,
                protected router: ActivatedRoute,
                protected service: CertificationService,
                private sanitizer: DomSanitizer) {
        super(service, router);

    }

    createForm() {
        this.courseId = Number(this.router.snapshot.queryParamMap.get('course'));
        this.form = this.fb.group({
            svg: ['', Validators.required],
            type: ['free', Validators.required],
            comments: [''],
            enable: [1],
            courseId: [this.courseId]
        });

        if (this.formType === FormTypes.edit) {
            this.form.controls.svg.setValue(this.entity.svg);
            this.form.controls.type.setValue(this.entity.type);
            this.form.controls.comments.setValue(this.entity.comments);
            this.form.controls.courseId.setValue(this.entity.courseId);
            this.form.controls.enable.setValue(this.entity.enable);

            this.svgImg = this.sanitizer.bypassSecurityTrustHtml(this.entity.svg);
        }

        this.form.get('type').valueChanges
            .subscribe((c) => {
                if (c === 'pay') {
                    this.form.get('comments').setValidators(Validators.required);
                } else {
                    this.form.get('comments').clearValidators();
                    this.form.get('comments').setErrors(null);
                }
            });
    }

    afterSave() {
        resetForm(this.form);
        this.nav.navigateRoot(['/admin/exams/list/' + this.courseId]);
    }


}
