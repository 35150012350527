import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Advertising} from '../../../models/advertising.model';
import {AdvertisingService} from '../../../services/advertising.service';
import {CrudListBaseComponent} from '../../crud-base/crud-list-base.component';

@Component({
    selector: 'app-advertising-list',
    templateUrl: './advertising-list.page.html',
    styleUrls: ['./advertising-list.page.scss'],
})
export class AdvertisingListPage extends CrudListBaseComponent implements OnInit {

    selectedAdv: Advertising;

    constructor(protected service: AdvertisingService,
                public modalController: ModalController) {
        super(service, modalController);
    }

    selectAdv(item) {
        this.selectedAdv = item;
    }


}
