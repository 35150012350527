import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PlyrComponent} from 'ngx-plyr';
import Plyr from 'plyr';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Video} from '../../../models/video.model';
import {getS3Resource} from '../../auxiliar';
import {filter, map, throttleTime} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {User} from '../../../models/user.model';
import {UserVideo} from '../../../models/user-videos.model';
import {PutUserLoggedVideo} from '../../../ngrx/user-logged/user-logged.actions';
import * as moment from 'moment';
import {HttpHeaders} from '@angular/common/http';


@Component({
    selector: 'app-plyr-player',
    templateUrl: './plyr-player.component.html',
    styleUrls: ['./plyr-player.component.scss'],
})
export class PlyrPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(PlyrComponent, {static: false}) plyr: PlyrComponent;
    @Input() video: Video;
    @Input() type = 'client';
    @Input() stop: Observable<void>;
    @Output() finished = new EventEmitter();

    // own
    private stopSubscription: Subscription;
    user: User;
    userVideos: UserVideo[] = [];
    initTime = 0;
    duration: number;

    httpHeader = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
        })
    };

    typeClass = 'video';
    timeUpdateObs: Subject<any> = new Subject<any>();
    storeSub: Subscription = new Subscription();


    // player settings
    player: Plyr;
    poster = '';
    options = {
        quality: {default: 720, options: [1080, 720, 576, 480, 360, 240, 144]},
        autoplay: false,
        keyboard: {focused: false, global: false}
    };
    videoSources: Plyr.Source[] = [{
        src: 'https://player.vimeo.com/external/412465111.hd.mp4?s=73c963e3cbf5b7733cbd1737675183eab44e9268&profile_id=174',
        size: 720
    }];

    constructor(private store: Store<AppState>) {
    }

    async ngOnInit() {
        await this.setSource();
        setTimeout(() => this.getStore(), 500);
        this.timeUpdateSub();
        if (this.type === 'client') {
            this.stopSubscription = this.stop.subscribe(() => {
                this.player.pause();
            });
        }
    }

    ngOnDestroy(): void {
        this.timeUpdateObs.unsubscribe();
        this.storeSub.unsubscribe();
        if (this.type === 'client') {
            this.stopSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.player.currentTime = this.initTime;
    }

    async setSource() {
        this.videoSources = [];

        // for (const dim of this.video.dimensions) {
        //     this.videoSources.push({
        //         src: getS3Resource(dim.dimensionKey),
        //         size: Number.parseInt(dim.dimension, 10)
        //     });
        // }

        this.poster = getS3Resource(this.video.imagePath);

        // Ver como arreglar HEAD a S3 para comprobar
        // for (const dim of this.video.dimensions) {
        //     const resp = await this.http.head(getS3Resource(dim.dimensionKey), this.httpHeader)
        //         .pipe(
        //             catchError((e) => {
        //                 console.log(e);
        //                 return of('error');
        //             })
        //         )
        //         .toPromise();
        //
        //     console.log(resp);
        //     if (resp === null) {
        //         this.videoSources.push({
        //             src: getS3Resource(dim.dimensionKey),
        //             size: dim.dimension
        //         });
        //     }
        // }
        //
    }


    // Inicia store redux
    getStore() {
        this.storeSub = this.store.select('userLogged')
            .subscribe((userLoggedData) => {
                this.user = userLoggedData.user;
                this.userVideos = userLoggedData.userVideos;

                const uv: UserVideo = this.userVideos.find((uvv) => uvv.videoId === this.video.id);
                if (uv) {
                    this.initTime = uv.watchedTime;
                    if (this.player) {
                        this.player.currentTime = this.initTime;
                    }
                } else {
                    this.putUserVideo();
                }
            });
    }

    ended(event: Plyr.PlyrEvent) {
        this.putUserVideo(this.duration, true);
    }


    timeUpdate(event) {
        this.timeUpdateObs.next(event);
    }

    timeUpdateSub() {
        if (this.type === 'client') {
            this.timeUpdateObs.pipe(
                throttleTime(5000),
                map(data => data.detail.plyr.media.currentTime),
                filter(time => time !== 0)
            ).subscribe((currentTime) => {
                if (this.duration && ((this.duration - 10) < currentTime)) {
                    this.putUserVideo(currentTime, true);
                } else {
                    this.putUserVideo(currentTime);
                }
            });
        }
    }

    /** Actualiza Cuanto tiempo de video vio el usuario */
    putUserVideo(currentTime = 0, end: boolean = false) {
        if (this.type === 'client') {
            const userVideo = new UserVideo();
            if (end) {
                userVideo.finishedAt = moment().format();
                this.finished.emit();
            }
            userVideo.videoId = this.video.id;
            userVideo.watchedTime = Math.round(currentTime);
            this.store.dispatch(new PutUserLoggedVideo({userVideo}));
        }
    }

    play(data) {
        if (this.type === 'client') {
            this.duration = Math.round(data.detail.plyr.media.duration);
            this.putUserVideo(data.detail.plyr.media.currentTime);
        }
    }

    plyError(event) {
      
    }


}
