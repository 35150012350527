import { Component, HostListener, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Notification } from "src/app/models/notification.model";

import { NotificationsService } from "src/app/services/notifications.service";
import { Store } from "@ngrx/store";
import { AppState } from "../../../ngrx/app.reducer";

@Component({
    selector: "app-notifications-list",
    templateUrl: "./notifications-list.page.html",
    styleUrls: ["./notifications-list.page.scss"],
})
export class NotificationsListPage implements OnInit {
    notifications: Notification[] = [];

    constructor(
        private notificationService: NotificationsService,
        private route: ActivatedRoute,
        private store: Store<AppState>,
    ) {
        console.log("constructor")
    }

    ngOnInit() {
        console.log("ngOnInit")
        this.getNotifications();
    }

  

    getNotifications() {
        this.notificationService.getAdminNotifications().subscribe((notifications: Notification[]) => {
            this.notifications = notifications;
        });
    }

    removeNotification(notification: Notification) {
        this.notificationService.removeNotification(notification).subscribe((resp) => {
            this.getNotifications();
        });
    }
}
