import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import {delay, filter} from 'rxjs/operators';
import {UiEffects} from '../../../ngrx/ui/ui.effect';
import { environment } from '../../../../environments/environment';
import {NavController} from '@ionic/angular';


@Component({
    selector: 'app-register-base',
    templateUrl: './register-base.page.html',
    styleUrls: ['./register-base.page.scss'],
})
export class RegisterBasePage implements OnDestroy {

    public form: FormGroup;
    public submitted = false;
    public errors: any;
    public showFormError = false;
    public environment = environment;

    // Subscriptions
    protected subSucc: Subscription = new Subscription();
    protected subError: Subscription = new Subscription();

    constructor(protected http: HttpClient,
                protected store: Store<AppState>,
                protected route: Router,
                protected errorService: ErrorService,
                private nav: NavController,
                protected fb: FormBuilder) {
    }

    ngOnDestroy(): void {
        this.subSucc.unsubscribe();
        this.subError.unsubscribe();
    }

    initStore() {
        this.subSucc = this.store.select('ui', 'success')
            .pipe(
                filter(data => data),
                delay(UiEffects.uiShowTimer)
            )
            .subscribe(() => this.nav.navigateRoot(['/login']));

        this.subError = this.store.select('ui', 'error')
            .subscribe(() => {
                if (this.errorService.errors) {
                    this.errors = this.errorService.getErrors();
                }
            });
    }
}
