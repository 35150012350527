import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {ModalController} from '@ionic/angular';
import {CrudListBaseComponent} from '../../crud-base/crud-list-base.component';
import {TaskService} from '../../../services/task.service';
import {first} from 'rxjs/operators';
import moment from 'moment';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.page.html',
    styleUrls: ['./task-list.page.scss'],
})
export class TaskListPage extends CrudListBaseComponent implements OnInit {

    constructor(private store: Store<AppState>,
                public modalController: ModalController,
                protected service: TaskService) {
        super(service, modalController);
    }

    load(page) {
        this.page = page;

        this.service.getAll(page)
            .pipe(first())
            .subscribe(
                (resp) => {
                    this.entities = resp.map(e => {
                        e.jobCancelledTime = (e.jobCancelledTime) ? moment(e.jobCancelledTime).subtract({hours: 3}) : null;
                        e.jobCreateTime = (e.jobCreateTime) ? moment(e.jobCreateTime).subtract({hours: 3}) : null;
                        e.jobFinishedTime = (e.jobFinishedTime) ? moment(e.jobFinishedTime).subtract({hours: 3}) : null;
                        e.jobProgressTime = (e.jobProgressTime) ? moment(e.jobProgressTime).subtract({hours: 3}) : null;
                        return e;
                    });
                
                }
            );
    }
}
