import {Component, OnInit} from "@angular/core"
import {Subscription} from "src/app/models/subscription.model"
import {LoadStart, NoShow} from "src/app/ngrx/ui/ui.actions"
import {SubscriptionService} from "src/app/services/subscription.service"
import {Store} from "@ngrx/store"
import {AppState} from "../../../ngrx/app.reducer"
import {NavController} from "@ionic/angular"
import {environment} from "src/environments/environment"

@Component({
    selector: "app-subscription-main",
    templateUrl: "./subscription-main.page.html",
    styleUrls: ["./subscription-main.page.scss"],
})
export class SubscriptionMainPage implements OnInit {
    plan: any
    subscriptions: Array<Subscription>
    generalStatusActive: boolean = true
    phone: string = environment.phone || "El proveedor no ha definido su telefono de contacto."
    instagram: string = environment.instagram || "El proveedor no ha definido su instagram de contacto."

    constructor(
        private SubscriptionService: SubscriptionService,
        private nav: NavController,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.loadData()
    }

    async loadData() {
        this.subscriptions = []

        this.SubscriptionService.getActiveSubscription().subscribe((response: any) => {})
        this.SubscriptionService.getPlan().subscribe((plan: any) => (this.plan = plan.body))
        this.SubscriptionService.getSubscriptions().subscribe((subscriptions: Array<Subscription>) =>
            this.setSubscriptions(subscriptions)
        )
    }

    setSubscriptions(subscriptions: Array<Subscription>) {
        this.subscriptions = subscriptions
        this.generalStatusActive = false
        const status = this.subscriptions.filter(
            (subscriptions) => subscriptions.status == "authorized" || subscriptions.status == "pending_waiting_payment"
        )
        if (status.length > 0) {
            this.generalStatusActive = true
        }
        this.store.dispatch(new NoShow())
    }

    startSuscription() {
        this.nav.navigateRoot(["/subscription-method"])
    }

    changeSuscriptionStatus(id, status) {
        this.SubscriptionService.changeSubscruiptionStatus({
            id: id,
            status: status,
        }).subscribe((data: any) => {
            this.loadData()
        })
    }

    cancelSuscription(id) {
        this.SubscriptionService.cancelSuscription({
            subscription_id: id,
        }).subscribe((data: any) => {
            this.loadData()
        })
    }

    openWhatsapp(subscription) {
        var message = encodeURI(
            `Hola ${environment.client.toUpperCase()}! Me contacto para realizar el pago de una suscripción semestral. El importe de la misma es de $${
                subscription.payment_id.transaction_amount
            }, creada en la siguiente fecha: ${subscription.created_at}. Mi email es: ${subscription.payer_email}.`
        )
        var url = `https://api.whatsapp.com/send/?phone=${this.phone}&text=${message}&app_absent=0`
        window.open(url, "_blank")
    }
    openInstagram() {
        var url = `https://www.instagram.com/${this.instagram}/`
        window.open(url, "_blank")
    }

    cancelPayPalSubscription(id) {
        this.SubscriptionService.cancelPayPalSuscription({
            subscription_id: id,
        }).subscribe((data: any) => {
            this.loadData()
        })
    }
}
