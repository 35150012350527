import * as fromUi from './ui.actions';
import {UIActions} from './ui.actions';
import {Paginator} from '../../shared/models/paginator.interface';


export interface State {
    loading: boolean;
    error: boolean;
    success: boolean;
    progress: number;
    paginator: Paginator;
}

export const initialState: State = {
    loading: false,
    error: false,
    success: false,
    progress: 0,
    paginator: {
        currentPage: 1,
        totalPages: 0,
        totalItems: 0
    }
};

export function reducer(
    state = initialState,
    action: UIActions
): State {
    switch (action.type) {
        case fromUi.UIActionsType.ShowError: {
            return {
                ...state,
                loading: false,
                error: true,
                success: false
            };
        }
        case fromUi.UIActionsType.LoadStart: {
            return {
                ...state,
                loading: true,
                error: false,
                success: false
            };
        }
        case fromUi.UIActionsType.ShowSuccess: {
            return {
                ...state,
                loading: false,
                error: false,
                success: true
            };
        }
        case fromUi.UIActionsType.NoShow: {
            return {
                ...state,
                loading: false,
                error: false,
                success: false
            };
        }
        case fromUi.UIActionsType.SetProgressPercent: {
            return {
                ...state,
                progress: action.payload.progress
            };
        }
        case fromUi.UIActionsType.SetPaginator : {
            return {
                ...state,
                paginator: {...action.payload.paginator}
            };
        }
        default: {
            return state;
        }
    }
}


