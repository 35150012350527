import {HttpClient} from "@angular/common/http"
import {Component, OnInit} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {CourseService} from "src/app/services/course.service"
import {PaymentsService} from "src/app/services/payments.service"
import {environment} from "src/environments/environment"
import {LoadStart, NoShow, ShowError, ShowSuccess} from "../../../ngrx/ui/ui.actions"
import {Store} from "@ngrx/store"
import {AppState} from "../../../ngrx/app.reducer"
import {first} from "rxjs/operators"
import {User} from "src/app/models/user.model"
import {ResponsePayment} from "../../payments/credit-card/response"
import {ResponseErrors} from "../../payments/credit-card/errors"
import {SubscriptionService} from "src/app/services/subscription.service"
import {NavController} from "@ionic/angular"

declare var Mercadopago: any

@Component({
    selector: "app-subscription-payment",
    templateUrl: "./subscription-payment.page.html",
    styleUrls: ["./subscription-payment.page.scss"],
})
export class SubscriptionPaymentPage implements OnInit {
    alert = false
    message = ""
    locked = false
    loading = false
    showIssuer = false
    error = false
    public user: User

    errors = ResponsePayment()
    data_errors = ResponseErrors()

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private nav: NavController,
        private coursService: CourseService,
        private SubscriptionService: SubscriptionService,
        private store: Store<AppState>
    ) {
        this.setCardTokenAndPay = this.setCardTokenAndPay.bind(this)
    }

    apiEndPoint = environment.urlRoot + "/process_payment"

    ionViewWillEnter() {
        const id = this.route.snapshot.params.id
        
        this.store.dispatch(new LoadStart())
        this.store
            .select("userLogged")
            .pipe(first())
            .subscribe((data) => {
                this.user = data.user
                if (!this.user.extra) {
                    // @ts-ignore
                    this.user.extra = {}
                }
            })
        ;(<HTMLInputElement>document.getElementById("email")).value = this.user.email

        this.store.dispatch(new NoShow())
    }

    ngOnInit() {
        Mercadopago.setPublishableKey(environment.ML_PUB_KEY)
        Mercadopago.getIdentificationTypes()

        document.getElementById("cardNumber").addEventListener("change", guessPaymentMethod)

        function guessPaymentMethod(event) {
            this.showIssuer = false
            let cardnumber = (<HTMLInputElement>document.getElementById("cardNumber")).value

            if (cardnumber.length >= 6) {
                let bin = cardnumber.substring(0, 6)
                Mercadopago.getPaymentMethod(
                    {
                        bin: bin,
                    },
                    (status, response) => {
                        if (status == 200) {
                            setPaymentMethod(response[0])
                        } else {
                            this.setErrorPayment(response)
                        }
                    }
                )
            }
        }

        function setPaymentMethod(paymentMethod) {
            ;(<HTMLInputElement>document.getElementById("paymentMethodId")).value = paymentMethod.id

            if (paymentMethod.additional_info_needed.includes("issuer_id")) {
                getIssuers(paymentMethod.id)
            }
        }

        function getIssuers(paymentMethodId) {
            Mercadopago.getIssuers(paymentMethodId, setIssuers)
        }

        function setIssuers(status, response) {
            if (status == 200) {
                let issuerSelect = document.getElementById("issuer")
                response.forEach((issuer) => {
                    let opt = document.createElement("option")
                    opt.text = issuer.name
                    opt.value = issuer.id
                    issuerSelect.appendChild(opt)
                })
                this.showIssuer = true
            } else {
                this.setErrorPayment(response)
            }
        }
    }

    getCardToken() {
        let $form = document.getElementById("paymentForm")
        Mercadopago.createToken($form, this.setCardTokenAndPay)
    }

    setCardTokenAndPay(status, response) {
        if (status == 200 || status == 201) {
            this.processPayment(response)
        } else {
            this.setErrorPayment(response)
        }
    }

    processPayment(data) {
        this.store.dispatch(new NoShow())
        var mail = (<HTMLInputElement>document.getElementById("email")).value
        var card_token = data.id
        const id = this.route.snapshot.params.id
        this.SubscriptionService.newSubscription({
            subscription_id: id,
            card_token_id: card_token,
            payer_email: mail,
        }).subscribe(
            (response) => {
                this.error = false
                this.store.dispatch(new NoShow())
                this.nav.navigateRoot(["/subscription-main"])
            },
            (error) => {
                this.error = true
            }
        )
    }

    setErrorPayment(data, message?) {
        this.store.dispatch(new NoShow())
        this.alert = true
        let error
        if (!message) {
            error = this.errors.find((error) => error.name_error === data.status_detail)
        } else {
            error = message
        }

        if (data.error == "bad_request") {
            error = this.data_errors.find((error) => error.code === data.cause[0].code)
        }

        if (error) {
            this.message = error.detail
        } else {
            this.message = "Ha ocurrido un error, por favor intente nuevamente"
        }
    }

    sendPayment() {
        this.store.dispatch(new LoadStart())
        this.alert = false
        this.getCardToken()
    }
}
