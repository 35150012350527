import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
// @ts-ignore
import {Interest} from './interest.model';


@jsonObject
export class ExtraUser {
    @jsonMember
    name: string;
    @jsonMember
    lastname: string;
    @jsonMember
    phoneNumber: string;
    @jsonMember
    fromRosario: number;
    @jsonMember
    isGraduated: number;
    @jsonMember({isRequired: false})
    currentYearStudying: number;
    @jsonMember
    incomeYear: number;
    @jsonMember
    birthDate: string;
    @jsonMember
    country: string;
    @jsonMember
    userId: number;
    @jsonArrayMember(Interest)
    interests: Interest[];
    @jsonMember
    university: string | number;
    @jsonMember
    instagram: string;
    @jsonMember
    joinTeam: number;
}
