import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Comment} from '../../../models/comment.model';
import {CommentsService} from '../../../services/comments.service';
import {ServerResponse} from '../../../shared/models/response.interface';
import {Subscription} from 'rxjs';
import {TypedJSON} from 'typedjson';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {

    @Input() comment: Comment;
    responses: Comment[] = [];

    showResponses = false;
    showResponseTextBox = false;
    page = 1;
    showMore = true;

    sub$: Subscription = new Subscription();

    constructor(private service: CommentsService) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.sub$.unsubscribe();
    }

    showResponsesChange() {
        if (this.showResponses) {
            this.showResponses = false;
        } else {
            this.getResponses();
        }
    }

    newResponse(comment: Comment) {
        this.comment.responsesCount++;
        this.responses.push(comment);
    }

    getResponses() {
        this.sub$ = this.service.getCommentsResponse(this.comment.id, this.page).subscribe((resp: ServerResponse) => {
            this.page = resp.currentPage;
            if (this.page === resp.lastPage) {
                this.showMore = false;
            }

            const serializer = new TypedJSON(Comment);
            resp.data.forEach(d => {
                if (!this.responses.find((c) => Number(c.id) === Number(d.id))) {
                    this.responses.push(serializer.parse(d));
                }
            });

            this.showResponses = true;
        });
    }

    more() {
        this.page++;
        this.getResponses();
    }


}
