import {FormTypes, resetForm} from '../../helpers/auxiliar';
import {ActivatedRoute} from '@angular/router';
import {OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {EntityService} from '../../services/entity.service';
import {FormGroup} from '@angular/forms';

export abstract class CrudFormBaseComponent implements OnInit {

    form: FormGroup;
    id: number;
    formType: string;
    entity: any;

    constructor(
        protected service: EntityService,
        protected router: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.setFormType();
        this.getStore();
    }

    abstract createForm();

    setFormType() {
        if (this.router.snapshot.paramMap.get('id')) {
            this.id = Number(this.router.snapshot.paramMap.get('id'));
            this.formType = FormTypes.edit;
        } else {
            this.formType = FormTypes.new;
        }
    }

    getStore() {
        if (this.formType === FormTypes.edit) {
            this.service.get(this.id)
                .pipe(first())
                .subscribe((entity) => {
                    this.entity = entity;
                    this.createForm();
                    this.extraStore();
                });
        } else {
            this.createForm();
            this.extraStore();
        }
    }

    extraStore() {
        return null;
    }

    afterSave() {
        resetForm(this.form);
    }

    submit() {
        if (this.form.valid) {
            if (this.formType === FormTypes.new) {
                const entity = this.service.transformToModel(this.form.value);
                this.service.save(entity)
                    .subscribe(() => {
                        this.afterSave();
                    });
            }

            if (this.formType === FormTypes.edit) {
                const entity = {...this.entity, ...this.form.value};
                this.service.update(this.id, entity)
                    .pipe(first())
                    .subscribe(() => {});
            }

        }
    }
}
