import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router'
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Store} from '@ngrx/store';
import {AppState} from '../../ngrx/app.reducer';
import config from '../../../config/config';
import {MenuController} from '@ionic/angular';
import { AdminService } from 'src/app/services/admin.service';


@Component({
    selector: 'admin-dashboard',
    templateUrl: 'admin-dashboard.page.html',
    styleUrls: ['admin-dashboard.page.scss']
})
export class AdminDashboardPage implements OnInit, OnDestroy {
    public title: string;
    public data = null;
    public appPages = [
        {
            title: 'Cursos',
            url: '/admin/courses/list',
            icon: 'school'
        },
        {
            title: 'Usuarios',
            url: '/admin/users/list',
            icon: 'people'
        },
        {
            title: 'Tags',
            url: '/admin/tags/list',
            icon: 'pricetag'
        },
        {
            title: 'Publicidades',
            url: '/admin/advertisements/list',
            icon: 'tv'
        },
        {
            title: 'Carruseles',
            url: '/admin/carousels/list',
            icon: 'photos'
        },
        {
            title: 'Notificaciones',
            url: '/admin/notifications/list',
            icon: 'notifications'
        }
    ];
    public disabledMenu = false;


    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private store: Store<AppState>,
        private menu: MenuController,
        private service: AdminService,
        public router: Router
    ) {
        this.initializeApp();
        this.title = config.name;

    }

    async ngOnInit() {
        await this.menu.open();
        this.load();
    }

    load() {
        this.service.getData()
            .subscribe(
                (data) => {
                    this.data = data;
                }
            );
    }

    ngOnDestroy(): void {  }

    async changeDisabledMenu() {
        if(this.disabledMenu) {
            this.disabledMenu = false;
            await this.menu.open();
        } else {
            this.disabledMenu = true;
            await this.menu.close();
        }
    }
    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
