import {Component, OnInit} from "@angular/core"
import {PaymentsService} from "src/app/services/payments.service"
import {Inject, Injectable} from "@angular/core"
import {Store} from "@ngrx/store"
import {AppState} from "../../../ngrx/app.reducer"
import {LoadStart, NoShow} from "../../../ngrx/ui/ui.actions"
import {ActivatedRoute, Router} from "@angular/router"
import {CourseService} from "src/app/services/course.service"

@Component({
    selector: "app-payment-points",
    templateUrl: "./payment-points.page.html",
    styleUrls: ["./payment-points.page.scss"],
})
export class PaymentPointsPage implements OnInit {
    payment_points = [
        {id: "rapipago", name: "Rapipago", secure_thumbnail: "assets/images/rapipago.png"},
        {id: "pagofacil", name: "Pago Facil", secure_thumbnail: "assets/images/pagofacil.png"}
    ]
    payment_point_selected = ""
    payment_id: any
    method_id: 1
    course_id: null

    constructor(
        private PaymentService: PaymentsService,
        private route: ActivatedRoute,
        private router: Router,
        private coursService: CourseService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {}

    ionViewWillEnter() {
        // this.store.dispatch(new LoadStart())
        const id = this.route.snapshot.params.id
        this.getData(id)
    }

    getData(id) {
        this.coursService.get(id).subscribe((c) => this.loadData(c))
    }

    loadData(c) {
        this.course_id = c.id
        this.PaymentService.getCoursePayment(c.id).subscribe((payment: any) => {
            this.payment_id = payment.id
        })
    }

    selectPoint(payment_point) {
        this.payment_point_selected = payment_point
    }

    onSubmit() {
        this.PaymentService.sendPayment({
            payment_id: this.payment_id,
            method_id: this.payment_point_selected,
            course_id: this.course_id,
        }).subscribe((response: any) => {
            if (response.internalPaymentId) {
                this.router.navigate([`payment-detail/${response.internalPaymentId}`])
            }
        })
    }
}
