import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es')

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {AppReducers, effectsArr, metaReducers} from './ngrx/app.reducer';
import {StoreModule} from '@ngrx/store';
import {environment} from '../environments/environment';
import {AdminModule} from './admin/admin.module';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BasicAuthInterceptor} from './helpers/auth.interceptor';
import {PlatformModule} from './platform/platform.module';
import {APP_INITIALIZER} from '@angular/core';
import {AuthService} from './services/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

export function initializeApp(authService: AuthService) {
    return (): Promise<any> => {
            return authService.getUserData();
    };
}

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        SharedModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AdminModule,
        PlatformModule,
        StoreModule.forRoot(AppReducers, {metaReducers}),
        EffectsModule.forRoot(effectsArr),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        ReactiveFormsModule,
        FormlyBootstrapModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true},
        {provide: LOCALE_ID, useValue: 'es'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
