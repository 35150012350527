import {HttpClient, HttpHeaders} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Store} from "@ngrx/store"
import {environment} from "src/environments/environment"
import {AppState} from "../ngrx/app.reducer"
import {LoadStart, NoShow, ShowError, ShowSuccess} from "../ngrx/ui/ui.actions"
import {catchError, map} from "rxjs/operators"
import {of, throwError} from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class SubscriptionService {
    constructor(private http: HttpClient, private store: Store<AppState>) {}

    public getPlan() {
        //this.store.dispatch(new LoadStart())
        return this.http.get(`${environment.urlRoot}/subscription/get-plan`)
    }

    public getPayPalPlan() {
        //this.store.dispatch(new LoadStart())
        return this.http.get(`${environment.urlRoot}/subscription/get-paypal-plan`)
    }

    public getSubscriptions() {
        this.store.dispatch(new LoadStart())
        return this.http.get(`${environment.urlRoot}/subscription/get-user-suscriptions`)
    }

    public getActiveSubscription() {
        this.store.dispatch(new LoadStart())
        return this.http.get(`${environment.urlRoot}/subscription/get-user-active-suscriptions`)
    }

    public newSubscriptionMethod(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/new-subscription-method`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            }),
            catchError((err, caught) => {
                this.store.dispatch(new ShowError());
                return throwError(err);
            })
        )
    }

    public newSubscriptionMethodPaymentsPints(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/new-subscription-payments-points`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }

    public newSubscriptionWireTransfer(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/new-subscription-wire-transfer`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }


    public newSubscription(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/new-subscription`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            }),
            catchError((err, caught) => {
                this.store.dispatch(new ShowError());
                return throwError(err);
            })
        )
    }


    public newPayPalSubscription(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/new-pay-pal-subscription`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            }),
            catchError((err, caught) => {
                this.store.dispatch(new ShowError());
                return throwError(err);
            })
        )
    }

    public changeSubscruiptionStatus(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/change-status`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }


    public cancelSuscription(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/cancel-subscription-payments-points`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }

    public cancelPayPalSuscription(body) {
        this.store.dispatch(new LoadStart())
        return this.http.post(`${environment.urlRoot}/subscription/cancel-pay-pal-subscription`, body).pipe(
            map((resp) => {
                this.store.dispatch(new NoShow())
                return resp
            })
        )
    }
}
