import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CrudFormBaseComponent} from '../../crud-base/crud-form-base.component';
import {UserService} from '../../../services/user.service';
import {checkPasswordsValidate} from '../../../shared/validations/custom.validation';
import {FormTypes, resetForm} from '../../../helpers/auxiliar';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.page.html',
    styleUrls: ['./user-form.page.scss'],
})
export class UserFormPage extends CrudFormBaseComponent implements OnInit {

    public form: FormGroup;

    constructor(private http: HttpClient,
                protected service: UserService,
                protected route: ActivatedRoute,
                private fb: FormBuilder) {
        super(service, route);
    }

    createForm() {
        this.form = this.fb.group({
            email: [null, [Validators.email, Validators.required]],
            password: [null, [Validators.required]],
            passwordConfirmation: [null, [Validators.required]],
            admin: [true],
            extra: this.fb.group({
                name: [null, Validators.required],
                lastname: [null, Validators.required],
            })
        }, {
            validators: checkPasswordsValidate('password', 'passwordConfirmation')
        });
    }

    submit() {
        if (this.form.valid) {
            if (this.formType === FormTypes.new) {
                this.service.save(this.form.value)
                    .subscribe(() => {
                        resetForm(this.form);
                    });
            }

            if (this.formType === FormTypes.edit) {
                const entity = {...this.entity, ...this.form.value};
                this.service.update(this.id, entity)
                    .pipe(first())
                    .subscribe(() => {});
            }
        }
    }
}
