import {HttpClient} from "@angular/common/http"
import {Component, OnInit} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {CourseService} from "src/app/services/course.service"
import {PaymentsService} from "src/app/services/payments.service"
import {environment} from "src/environments/environment"
import {LoadStart, NoShow, ShowError, ShowSuccess} from "../../../ngrx/ui/ui.actions"
import {Store} from "@ngrx/store"
import {AppState} from "../../../ngrx/app.reducer"
import {first} from 'rxjs/operators';
import { User } from "src/app/models/user.model"
import {ResponsePayment} from './response'
import { ResponseErrors } from "./errors"


declare var Mercadopago: any

@Component({
    selector: "app-credit-card",
    templateUrl: "./credit-card.page.html",
    styleUrls: ["./credit-card.page.scss"],
})
export class CreditCardPage implements OnInit {
    
    alert = false
    message = ""
    locked = false
    loading = false
    showIssuer = false
    public user: User;

    errors = ResponsePayment();
    data_errors = ResponseErrors();

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private PaymentService: PaymentsService,
        private coursService: CourseService,
        
        private store: Store<AppState>
    ) {
        this.setCardTokenAndPay = this.setCardTokenAndPay.bind(this)
    }

    apiEndPoint = environment.urlRoot + "/process_payment"

    ionViewWillEnter() {
        this.store.dispatch(new LoadStart())
        const id = this.route.snapshot.params.id
        this.store.select('userLogged')
            .pipe(
                first()
            )
            .subscribe((data) => {
                this.user = data.user;
                if (!this.user.extra) {
                    // @ts-ignore
                    this.user.extra = {};
                }
            });
        this.getData(id)


           
    }

    ngOnInit() {
        Mercadopago.setPublishableKey(environment.ML_PUB_KEY)
        Mercadopago.getIdentificationTypes()

        document.getElementById("cardNumber").addEventListener("change", guessPaymentMethod)

        function guessPaymentMethod(event) {
            this.showIssuer = false
            let cardnumber = (<HTMLInputElement>document.getElementById("cardNumber")).value

            if (cardnumber.length >= 6) {
                let bin = cardnumber.substring(0, 6)
                Mercadopago.getPaymentMethod(
                    {
                        bin: bin,
                    },
                    (status, response) => {
                        if (status == 200) {
                            setPaymentMethod(response[0])
                        } else {
                            this.setErrorPayment(response)
                        }
                    }
                )
            }
        }

        function setPaymentMethod(paymentMethod) {
            ;(<HTMLInputElement>document.getElementById("paymentMethodId")).value = paymentMethod.id

            if (paymentMethod.additional_info_needed.includes("issuer_id")) {
                getIssuers(paymentMethod.id)
            }
        }

        function getIssuers(paymentMethodId) {
            Mercadopago.getIssuers(paymentMethodId, setIssuers)
        }

        function setIssuers(status, response) {
            if (status == 200) {
                let issuerSelect = document.getElementById("issuer")
                response.forEach((issuer) => {
                    let opt = document.createElement("option")
                    opt.text = issuer.name
                    opt.value = issuer.id
                    issuerSelect.appendChild(opt)
                })
                this.showIssuer = true
            } else {
                this.setErrorPayment(response)
            }
        }
    }

    getCardToken() {
        let $form = document.getElementById("paymentForm")
        Mercadopago.createToken($form, this.setCardTokenAndPay)
    }

    setCardTokenAndPay(status, response) {
        if (status == 200 || status == 201) {
            let form = <HTMLFormElement>document.getElementById("paymentForm")
            let card = document.createElement("input")
            card.setAttribute("name", "token")
            card.setAttribute("type", "hidden")
            card.setAttribute("value", response.id)
            form.appendChild(card)

            this.http.post(form.getAttribute("action"), new FormData(form)).subscribe(
                (data) => this.processPayment(data),
                (error) => console.log("Error: " + error)
            )
        } else {
            this.setErrorPayment(response)
        }
    }

    processPayment(data) {
        if (data.status === "approved" || data.status === "in_process") {
            this.store.dispatch(new NoShow())
            this.router.navigate([`payment-detail/${data.internal_payment_id}`])
        }
        if (data.status === "rejected") {
            this.setErrorPayment(data)
        }

        if (data.status === null) {
            this.alert = true
            this.message = "Ha ocurrido un error, intente nuevamente"
            this.store.dispatch(new NoShow())
        }
    }

    setErrorPayment(data, message?) {
        this.store.dispatch(new NoShow())
        this.alert = true
        let error
        if (!message) {
            error = this.errors.find((error) => error.name_error === data.status_detail)
        } else {
            error = message
        }

        if(data.error == 'bad_request'){
            error = this.data_errors.find((error) => error.code === data.cause[0].code)
        }

        if (error) {
            this.message = error.detail
        } else {
            this.message = "Ha ocurrido un error, por favor intente nuevamente"
        }
    }

    getData(id) {
        this.coursService.get(id).subscribe((c) => this.loadData(c))
    }

    loadData(c) {

        if (c.modality == 'pay') { 
            this.PaymentService.getCoursePayment(c.id).subscribe((payment: any) => {
                if(payment.payment_status === "approved" || payment.payment_status === "in_process"){
                    this.locked = true
                } 
                ;(<HTMLInputElement>document.getElementById("description")).value = payment.id
            })
            
                ;(<HTMLInputElement>document.getElementById("transactionAmount")).value = c.price
          
        }
      
        ;(<HTMLInputElement>document.getElementById("email")).value = this.user.email
        ;(<HTMLInputElement>document.getElementById("cardholderName")).value = ""
        this.store.dispatch(new NoShow())

        // })
    }

    sendPayment() {
        this.store.dispatch(new LoadStart())
        this.alert = false
        this.getCardToken()
    }
}
