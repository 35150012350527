import {
    Component,
    Input,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnDestroy,
    ViewChild,
} from "@angular/core";
import { from, Observable, Subscription } from "rxjs";
import { slider } from "./hello-slide.animation";
import { Course } from "../../../models/course.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../../ngrx/app.reducer";
import { Router } from "@angular/router";
import { User } from "../../../models/user.model";
import { UserCourse } from "../../../models/user-course.model";
import { first } from "rxjs/operators";
import { IonSlides } from "@ionic/angular";


@Component({
    selector: "app-carousel",
    templateUrl: "carousel.component.html",
    styleUrls: ["carousel.component.scss"],
    animations: [slider],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, OnDestroy {
    @Input() courses: Course[];
    @Input() activeCounter: boolean;
    @Input() resource: string;
    @ViewChild(IonSlides) slides: IonSlides;
    isBeginning$: Observable<boolean>;
    isEnd$: Observable<boolean>;

    user: User;
    userCourse: UserCourse[];
    userSub: Subscription = new Subscription();

    public slideOpts = {
        initialSlide: 0,
        speed: 1500,
        slidesPerView: 2,
        spaceBetween: 20,
        autoplay: false,
        breakpoints: {
            1000: {
                slidesPerView: 1,
            },
        },
    };

    constructor(private cdr: ChangeDetectorRef, private store: Store<AppState>, private router: Router) {
        this.getStore();
    }

    ngOnInit() {
        setTimeout(() => {}, 5000);
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
    }
    getStore() {
        this.store
            .select("userLogged")
            .pipe(first())
            .subscribe((data) => {
                this.user = data.user;
                this.userCourse = data.userCourses;
            });
    }

    slideNext() {
        this.slides.slideNext();
      
      }
    slidePrev() {
        this.slides.slidePrev();
    }

    updateSliderIconState() {
        this.isEnd$ = from(this.slides.isEnd());
        this.isBeginning$ = from(this.slides.isBeginning());
      }
}
