import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NavController} from '@ionic/angular';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService,
        private nav: NavController
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.user;
        if (currentUser && currentUser.accessToken && currentUser.enable) {
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.nav.navigateRoot(['/login'], { queryParams: { returnUrl: state.url }});
        return false;

    }
}
