import {Component, OnInit} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {Payment} from "src/app/models/payment.model"
import {PaymentsService} from "src/app/services/payments.service"

@Component({
    selector: "app-payment-detail",
    templateUrl: "./payment-detail.page.html",
    styleUrls: ["./payment-detail.page.scss"],
})
export class PaymentDetailPage implements OnInit {
    payment: Payment = {
        id: null,
        payment_id: "",
        course_id: "",
        payment_status: "",
        payment_status_detail: "",
        payment_method_id: 0,
        transaction_amount: "",
        external_resource_url: "",
        user_id: "",
        email: "",
        created_at: null,
    }
    

    constructor(private router: Router, private route: ActivatedRoute, private PaymentService: PaymentsService) {}

    ngOnInit() {
        const id = this.route.snapshot.params.id
        this.loadData(id)
    }

    loadData(id) {
        if (id) {
            this.PaymentService.getPayment(id).subscribe((payment: Payment) => this.setPayment(payment))
        }
    }

    setPayment(payment: Payment) {
               
        if (payment) {
            this.payment = payment
            if (payment.payment_status == "rejected") {
                this.payment.payment_status  = "No pudimos procesar tu pago"
            } else {
                this.payment.payment_status = this.setStatus(payment.payment_status_detail)
            }

            if (payment.payment_method_id == 1) {
                this.payment.payment_method_id = "Pago con Tarjeta de Crédito"
            }

            if (payment.payment_method_id == 2) {
                this.payment.payment_method_id = "En Puntos de Pago habilitados"
            }
        }

    }

    setStatus(status) {
        switch (status) {
            case "accredited":
                return "¡Listo! Se acreditó tu pago. En tu resumen verás el cargo."

            case "pending_waiting_payment":
                return "Estamos procesando tu pago. No te preocupes, en menos de 2 días hábiles te avisaremos por e-mail si se acreditó."

            case "pending_contingency":
                return "Estamos procesando tu pago. No te preocupes, en menos de 2 días hábiles te avisaremos por e-mail si se acreditó."

            case "pending_review_manual":
                return "Estamos procesando tu pago. No te preocupes, en menos de 2 días hábiles te avisaremos por e-mail si se acreditó o si necesitamos más información."

            default:
                break
        }
    }

    showCourse() {
        this.router.navigate([`course/show/${this.payment.course_id}`])
    }
}
