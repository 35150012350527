import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { VideoModule } from '../video/video.module';
import { ExamListPage } from './exam-list/exam-list.page';
import { ExamFormPage } from './exam-form/exam-form.page';
import { QuestionFormPage } from './question-form/question-form.page';
import { AnswerFormPage } from './answer-form/answer-form.page';
import { EditExamsConfirmationComponent } from './edit-exams-confirmation/edit-exams-confirmation.component';
import { DeleteQuestionConfirmationComponent } from './delete-question-confirmation/delete-question-confirmation.component';
import { MatDialogModule } from '@angular/material';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        VideoModule,
        MatDialogModule
    ],
    declarations: [
        ExamListPage,
        ExamFormPage,
        QuestionFormPage,
        AnswerFormPage,
        EditExamsConfirmationComponent,
        DeleteQuestionConfirmationComponent,
    ],
    entryComponents: [
        EditExamsConfirmationComponent,
        DeleteQuestionConfirmationComponent,
    ]
})
export class ExamModule { }
