import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {ExamResponse} from './exam-response.model';


@jsonObject
export class ExamResolution {
    @jsonMember
    id: number;
    @jsonArrayMember(ExamResponse)
    responses: ExamResponse[];
    @jsonMember
    result: number;
    @jsonMember
    finishedAt: string;
    @jsonMember
    startedAt: string;
    @jsonMember
    approved: boolean;
    @jsonMember
    examId: number;
    @jsonMember
    userId: number;
    @jsonArrayMember(Number)
    randomQuestions: number[];
}