import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from '../../../ngrx/app.reducer';
import {Subscription} from 'rxjs';
import {CourseService} from '../../../services/course.service';
import {Course} from '../../../models/course.model';
import {NavController} from '@ionic/angular';
import moment from 'moment';
import {ExamService} from '../../../services/exam.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-exam-list',
    templateUrl: './exam-list.page.html',
    styleUrls: ['./exam-list.page.scss'],
})
export class ExamListPage implements OnInit, OnDestroy {
    public course: Course;

    private subscription: Subscription = new Subscription();

    constructor(private route: ActivatedRoute,
                private store: Store<AppState>,
                private nav: NavController,
                private courseService: CourseService,
                private examService: ExamService) {

    }

    async ngOnInit() {
        const id = this.route.snapshot.params['course-id'];
        this.courseService.get(id)
            .subscribe((course) => {
                this.course = course;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    goTo(url, queryParams?) {
        if (queryParams) {
            const navigationExtras: NavigationExtras = {
                queryParams
            };

            this.nav.navigateRoot([url], navigationExtras);
        } else {
            this.nav.navigateRoot([url]);
        }
    }

    getExamReport(id) {
        const date = moment(new Date()).format('DD/MM/YYYY');

        this.examService.getReport(id).subscribe(
            response => {
                const blob: any = new Blob([response], { type: 'text/csv; charset=utf-8' });
                saveAs(blob, `users-${date}.xls`);
            },
            error => console.log('Error downloading the file'),
            () => console.log('File downloaded successfully')
        );
    }

}
