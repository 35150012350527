import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Certification {
    @jsonMember
    id: number;
    @jsonMember
    svg: string;
    @jsonMember
    type: string;
    @jsonMember
    comments: string;
    @jsonMember
    courseId: number;
    @jsonMember
    enable: number;
}
