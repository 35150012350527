import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';

@Component({
    selector: 'app-ui',
    templateUrl: './ui.page.html',
    styleUrls: ['./ui.page.scss'],
})
export class UiPage implements OnInit {

    loading = true;
    success = false;
    error = false;

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
        this.store.select('ui')
            .subscribe((state) => {
                this.loading = state.loading;
                this.success = state.success;
                this.error = state.error;
            });
    }

}
