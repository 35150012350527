import {Inject, Injectable} from '@angular/core';
import {EntityService} from './entity.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {TypedJSON} from 'typedjson';
import {Certification} from '../models/certification.model';

@Injectable({
  providedIn: 'root'
})
export class CertificationService extends EntityService {

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(Store) store: Store<AppState>) {
    super(http, store);
  }

  resource = 'certifications';
  className = 'certification';

  transformToModel(obj) {
    const serializer = new TypedJSON(Certification);
    obj.enable = Number(obj.enable);
    return serializer.parse(obj);
  }

}
