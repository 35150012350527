import {Store} from "@ngrx/store"
import {catchError, switchMap} from "rxjs/operators"
import {AppState} from "../app.reducer"
import {Actions, createEffect, ofType} from "@ngrx/effects"
import {Injectable} from "@angular/core"
import {NoShow, ShowError, ShowSuccess} from "../ui/ui.actions"
import {UserService} from "../../services/user.service"
import {
    AddUserLoggedCourse,
    PutUserLoggedVideo,
    StartCourse,
    UpdateProfile,
    UpdateUserLoggedSuccess,
    UserLoggedActionTypes,
} from "./user-logged.actions"
import {Router} from "@angular/router"
import {TypedJSON} from "typedjson"
import {UserCourse} from "../../models/user-course.model"
import {ServerResponse} from "../../shared/models/response.interface"
import {NavController} from "@ionic/angular"
import {SubscriptionService} from "src/app/services/subscription.service"

@Injectable()
export class UserLoggedEffects {
    private courseId: number
    private modality: string

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private userService: UserService,
        private SubscriptionService: SubscriptionService,
        private router: Router,
        private nav: NavController
    ) {}

    startCourse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserLoggedActionTypes.StartCourse),
            switchMap((action: StartCourse) => {
                this.courseId = action.payload.course.id
                this.modality = action.payload.course.modality
                return this.userService.startCourse(action.payload.course.id)
            }),
            switchMap((resp: ServerResponse) => {
                const userCoursesSerializer = new TypedJSON(UserCourse)
                const userCourse = userCoursesSerializer.parse(resp.data)

                if (this.modality == "free" || this.modality == "free_with_code") {
                    this.nav.navigateRoot([`course/show/${this.courseId}`])
                }
                if (this.modality == "pay") {
                    this.nav.navigateRoot([`payment-main/${this.courseId}`])
                }
                if (this.modality == "subscription") {
                    this.nav.navigateRoot([`course/show/${this.courseId}`])
                }
                return [new AddUserLoggedCourse({userCourse}), new ShowSuccess()]
            }),
            catchError((err, caught) => {
                this.store.dispatch(new ShowError())
                return caught
            })
        )
    )

    putUserVideo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserLoggedActionTypes.PutUserLoggedVideo),
            switchMap((action: PutUserLoggedVideo) => {
                return this.userService.putUserVideo(action.payload.userVideo)
            }),
            switchMap((resp: ServerResponse) => {
                return [new NoShow()]
            }),
            catchError((err, caught) => {
                this.store.dispatch(new NoShow())
                return caught
            })
        )
    )

    updateProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserLoggedActionTypes.UpdateProfile),
            switchMap((action: UpdateProfile) => {
                return this.userService.updateProfile(action.payload.user)
            }),
            switchMap((resp: ServerResponse) => {
                return [new UpdateUserLoggedSuccess({user: resp.data}), new ShowSuccess()]
            }),
            catchError((err, caught) => {
                console.log(err)
                this.store.dispatch(new ShowError())
                return caught
            })
        )
    })
}
