import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ServerResponse} from '../shared/models/response.interface';
import {map} from 'rxjs/operators';
import {TypedJSON} from 'typedjson';
import {Comment} from '../models/comment.model';

@Injectable({
    providedIn: 'root'
})
export class CommentsService {

    constructor(private http: HttpClient) {
    }

    public getCourseComments(courseId, page) {
        return this.http.get(`${environment.urlRoot}/comments/course/${courseId}?page=${page}`);
    }

    public getCommentsResponse(commentId, page) {
        return this.http.get(`${environment.urlRoot}/comments/${commentId}/responses?page=${page}`)
            .pipe(
                map((resp: ServerResponse) => {
                        const serializer = new TypedJSON(Comment);
                        resp.data.map(d => {
                            return serializer.parse(d);
                        });

                        return resp;
                    }
                )
            );
    }

    public addCourseComments(comment: Comment) {
        return this.http.post(`${environment.urlRoot}/comments`, comment)
            .pipe(
                map((resp: ServerResponse) => {
                        const serializer = new TypedJSON(Comment);
                        resp.data = serializer.parse(resp.data);
                        return resp;
                    }
                )
            );
    }
}
