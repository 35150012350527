import {normalize, schema} from 'normalizr';
import {Section} from './section.model';
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from 'typedjson';
import {Tag} from './tag.model';
import { Course } from './course.model';


@jsonObject
export class Carousel {
    @jsonMember
    id: number;
    @jsonMember
    title: string;
    @jsonArrayMember(Tag)
    tags: Tag[];
    @jsonMember
    show: number;
    @jsonArrayMember(Course)
    courses: Course[];
    @jsonMember
    position: number;

    public static getSchema() {
        return new schema.Entity('carousels', {
            sections: [Section.getSchema()]
        });
    }

    public static normalize(data) {
        return normalize(data, Carousel.getSchema());
    }

    public static toModel(obj: any) {
        const carouselSerializer = new TypedJSON(Carousel);
        return carouselSerializer.parse(obj);
    }
}
