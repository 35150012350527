import { Component, OnInit } from '@angular/core';
import config from '../../../config/config';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  private title: string;
  constructor() {
    this.title = config.name;

  }

  ngOnInit() {}

}
