import {Routes} from '@angular/router';
import {QuestionFormPage} from './question-form/question-form.page';

export const QuestionRoutes: Routes = [
    {
        path: 'form/:id',
        component: QuestionFormPage
    }
];

