import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {Answer} from './answer.model';

@jsonObject
export class Question {
    @jsonMember
    id: number;
    @jsonMember
    description: string;
    @jsonMember
    examId: number;
    @jsonMember
    type: string;
    @jsonMember
    correctAnswerId: number;
    @jsonArrayMember(Answer)
    answers: Answer[];
}