import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {LoadStart, NoShow, SetPaginator, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {catchError, map} from 'rxjs/operators';
import {ServerResponse} from '../shared/models/response.interface';
import {Paginator} from '../shared/models/paginator.interface';
import {Order} from '../shared/models/order.interface';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class EntityService {
    abstract resource: string;

    abstract transformToModel(obj);

    protected constructor(protected http: HttpClient,
                          protected store: Store<AppState>) {
    }


    save(body) {
        this.store.dispatch(new LoadStart());
        return this.http.post(`${environment.urlRoot}/${this.resource}`, body)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new ShowSuccess());
                    return this.transformToModel(resp.data);
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    get(id) {
        this.store.dispatch(new LoadStart());
        return this.http.get(`${environment.urlRoot}/${this.resource}/${id}`)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new NoShow());
                    return this.transformToModel(resp.data);
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    return throwError(err);
                })
            );
    }

    getAll(page=1) {
        this.store.dispatch(new LoadStart());
        return this.http.get(`${environment.urlRoot}/${this.resource}?page=${page}`)
            .pipe(
                map((resp: ServerResponse) => {
                    const paginator: Paginator = {
                        totalPages: resp.lastPage,
                        currentPage: resp.currentPage,
                        totalItems: resp.total
                    };

                    const data = resp.data.map((e) => this.transformToModel(e));
                    this.store.dispatch(new SetPaginator({paginator}));
                    this.store.dispatch(new NoShow());

                    return data;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    update(id, body) {
        this.store.dispatch(new LoadStart());
        return this.http.patch(`${environment.urlRoot}/${this.resource}/${id}`, body)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new ShowSuccess());
                    return this.transformToModel(resp.data);
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    delete(id) {
        this.store.dispatch(new LoadStart());
        return this.http.delete(`${environment.urlRoot}/${this.resource}/${id}`)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    reOrder(lastOrder: Order[], newOrder: Order[]) {
        const modified = [];
        for (let i = 0; i < newOrder.length; i++) {
            if (newOrder[i].position !== lastOrder[i].position) {
                newOrder[i].position = lastOrder[i].position;
                modified.push({...newOrder[i]});
            }
        }

        return this.http.patch(`${environment.urlRoot}/${this.resource}/order`, {...modified});
    }
}
