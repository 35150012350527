import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListPage } from './user-list/user-list.page';
import {SharedModule} from '../../shared/shared.module';
import {UserFormPage} from './user-form/user-form.page';
import {UserShowPage} from './user-show/user-show.page';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
      UserListPage,
      UserFormPage,
      UserShowPage
  ]
})
export class UserModule {}
