import { Injectable } from "@angular/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { Platform } from "@ionic/angular";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "../ngrx/app.reducer";
import { environment } from "src/environments/environment";
import { SetUserLoggedNotifications } from "../ngrx/user-logged/user-logged.actions";
import { Notification } from "../models/notification.model";
import { LoadStart } from "../ngrx/ui/ui.actions";

@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    constructor(public platform: Platform, private http: HttpClient, private store: Store<AppState>) {}

    public getNotifications() {
        setTimeout(() => {
            this.addListeners();
        }, 5000);

        return this.http.get(`${environment.urlRoot}/notifications/user`).subscribe((notifications: Notification[]) => {
            this.store.dispatch(new SetUserLoggedNotifications({ userNotifications: notifications }));
        });
    }

    public getNotification(id) {
        return this.http.get(`${environment.urlRoot}/notifications/notification?id=${id}`);
    }

    public refreshNotifications() {
        return this.http.get(`${environment.urlRoot}/notifications/user`).subscribe((notifications: Notification[]) => {
            this.store.dispatch(new SetUserLoggedNotifications({ userNotifications: notifications }));
        });
    }

    public newNotification(notification) {
        let body = notification;
        return this.http.post(`${environment.urlRoot}/notifications/new`, body);
    }

    public getAdminNotifications() {
        return this.http.get(`${environment.urlRoot}/notifications/user`);
    }

    public removeNotification(notification: Notification) {
        let body = { notification_id: notification.id };
        return this.http.post(`${environment.urlRoot}/notifications/remove`, body);
    }

    public setNotificationRead(notification) {
        let body = { notification_id: notification.id };
        return this.http
            .post(`${environment.urlRoot}/notifications/read`, body)
            .subscribe((notification: Notification[]) => {});
    }

    public setNotificationToken(token) {
        let body = { token: token };
        return this.http.post(`${environment.urlRoot}/notifications/register-user-token`, body).subscribe((resp) => {});
    }

    public addListeners = async () => {
        if (this.platform.is("android") || this.platform.is("ios")) {
            try {
                await PushNotifications.addListener("registration", (token) => {
                    console.info("Registration token: ", token.value);
                    this.setNotificationToken(token.value);
                });

                await PushNotifications.addListener("registrationError", (err) => {
                    console.error("Registration error: ", err.error);
                });
                await PushNotifications.addListener("pushNotificationReceived", (notification) => {
                    this.refreshNotifications();
                });
                this.registerNotifications();
            } catch (e) {
                console.warn(e);
            }
        }
    };

    public registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
            throw new Error("User denied permissions!");
        }
        await PushNotifications.register();
    };
}
