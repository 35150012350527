import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Exam} from '../../../models/exam.model';
import {QuestionService} from '../../../services/question.service';
import {first} from 'rxjs/operators';
import {Question} from '../../../models/question.model';
import {Answer} from '../../../models/answer.model';
import {ActivatedRoute} from '@angular/router';
import {FormTypes} from '../../../helpers/vars';
import {AnswerFormPage} from '../answer-form/answer-form.page';

@Component({
    selector: 'app-question-form',
    templateUrl: './question-form.page.html',
    styleUrls: ['./question-form.page.scss'],
})
export class QuestionFormPage implements OnInit {
    @ViewChildren('cmp') childComponents: QueryList<AnswerFormPage>;
    @Input() exam: Exam;
    @Input() question: Question;

    showAnswerForm = false;
    formType: string;

    form: FormGroup;

    id: string;

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            description: [this.question.description, Validators.required],
            type: ['multiple-choice', Validators.required]
        });
    }


    checkForm() {
        this.form.markAllAsTouched();
        this.question.description = this.form.value.description;
        this.question.type = this.form.value.type;

        let allValids = true;
        this.childComponents.forEach((c) => {
            allValids = c.checkForm();
        });

        return (allValids && this.form.valid);
    }

    newAnswer() {
        const answer = new Answer();
        answer.correct = false;
        this.question.answers.push(answer);
    }

    changeCorrectAnswer(answer: Answer) {
        this.question.answers.forEach((a) => a.correct = false);
        answer.correct = !answer.correct;
    }

    deleteAnswer(i: number) {
        this.question.answers.splice(i, 1);
    }
}
