import { Routes } from '@angular/router';
import {VideoShowPage} from './video-show/video-show.page';

export const VideoRoutes: Routes = [
    {
        path: 'show/:id',
        component: VideoShowPage
    }
];

