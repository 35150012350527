import { Component, OnInit } from '@angular/core';
import { CrudListBaseComponent } from '../../crud-base/crud-list-base.component';
import { CarouselService } from 'src/app/services/carousel.service';
import { Router } from '@angular/router';
import { Carousel } from 'src/app/models/carousel.model';
import {Subscription} from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-carousel-list',
  templateUrl: './carousel-list.page.html',
  styleUrls: ['./carousel-list.page.scss'],
})
export class CarouselListPage extends CrudListBaseComponent implements OnInit {
  
  entities: Carousel[] = [];
  subscription: Subscription = new Subscription();

  constructor(                
    protected service: CarouselService,
    public modalController: ModalController,
    private route: Router,
  ) {
    super(service, modalController); 
  }

  reorderItems(event) {
    const lastOrderArr = JSON.parse(JSON.stringify(this.entities));
    const itemMove = this.entities.splice(event.detail.from, 1)[0];
    this.entities.splice(event.detail.to, 0, itemMove);
    event.detail.complete();

    this.service
        .reOrder(lastOrderArr, this.entities)
        .subscribe(console.log);
}

}
