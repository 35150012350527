import {Inject, Injectable} from '@angular/core';
import {Section} from '../models/section.model';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {EntityService} from './entity.service';
import {TypedJSON} from 'typedjson';

@Injectable({
  providedIn: 'root'
})
export class SectionService extends EntityService {

  constructor(@Inject(HttpClient) http: HttpClient,
              @Inject(Store) store: Store<AppState>) {
    super(http, store);
  }

  resource = 'sections';
  className = 'section';

  transformToModel(obj) {
    const serializer = new TypedJSON(Section);
    return serializer.parse(obj);
  }
}
