import { Component, HostListener, OnInit } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Store } from "@ngrx/store";
import { AppState } from "../../ngrx/app.reducer";
import { Subject } from "rxjs";
import { Tag } from "../../models/tag.model";
import { auditTime, filter, first } from "rxjs/operators";
import { Course } from "../../models/course.model";
import { User } from "../../models/user.model";
import { UserCourse } from "../../models/user-course.model";
import { NavController } from "@ionic/angular";
import { CourseService } from "../../services/course.service";
import { TagService } from "../../services/tag.service";
import { Notification } from "../../models/notification.model";

@Component({
    selector: "app-header-dashboard",
    templateUrl: "./header-dashboard.component.html",
    styleUrls: ["./header-dashboard.component.scss"],
    animations: [
        trigger("openClose", [
            state(
                "open",
                style({
                    height: "100%",
                    visibility: "unset",
                    opacity: 1,
                }),
            ),
            state(
                "closed",
                style({
                    height: 0,
                    visibility: "hidden",
                    opacity: 0,
                }),
            ),
            transition("open => closed", [animate("0.5s")]),
            transition("closed => open", [animate("0.4s")]),
        ]),
    ],
})
export class HeaderDashboardComponent implements OnInit {
    showSearch = false;
    showFilter = true;

    user: User;
    userCourse: UserCourse[];

    courses: Course[];
    coursesFilter: Course[];

    tags: any;
    tagsSelected: Tag[] = [];

    showNotificationActive = false;
    notificationsCount: number | string = 0;

    keyPress: Subject<string> = new Subject<string>();

    constructor(
        private nav: NavController,
        private courseService: CourseService,
        private tagsService: TagService,
        private store: Store<AppState>,
    ) {}

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.code === "Escape") {
            this.closeSearch();
        }
    }

    closeSearch() {
        this.showSearch = false;
    }

    ngOnInit() {
        this.store
            .select("userLogged")
            .pipe(first())
            .subscribe((data) => {
                this.user = data.user;
                this.userCourse = data.userCourses;
            });
        this.store.select("userLogged").subscribe((data) => {
            this.notificationsCount = this.getNotificationsCount(data.userNotifications);
        });

        this.tagsService
            .getAllTagsNoPagination()
            .pipe(first())
            .subscribe((tags) => (this.tags = tags));

        this.courseService
            .getFilterCourse("all")
            .pipe(first())
            .subscribe((courses) => (this.courses = courses));

        this.keyPress.pipe(auditTime(500)).subscribe((value) => this.filter(value));
    }

    isTagSelected(tag) {
        if (tag instanceof Tag) {
            return this.tagsSelected.find((item: Tag) => {
                return item.id === tag.id;
            });
        }
    }

    selectTag(tag, title) {
        if (tag instanceof Tag) {
            const idx = this.tagsSelected.findIndex((item: Tag) => {
                return item.id === tag.id;
            });

            if (idx > -1) {
                this.tagsSelected.splice(idx, 1);
            } else {
                this.tagsSelected.push(tag);
            }

            this.changeInput(title);
        }
    }

    changeInput(value) {
        this.keyPress.next(value);
    }

    filter(value) {
        if (value) {
            this.coursesFilter = this.courses.filter((c: Course) => {
                const title = c.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                value = value
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

                if (title.includes(value) && this.tagsSelected.length === 0) {
                    return true;
                }

                if (title.includes(value)) {
                    if (this.checkCourseTags(c) === 0) {
                        return true;
                    }
                }
                return false;
            });
        } else if (!value && this.tagsSelected.length) {
            this.coursesFilter = this.courses.filter((c: Course) => {
                return this.checkCourseTags(c) === 0;
            });
        } else {
            this.coursesFilter = this.courses;
        }
    }

    checkCourseTags(c: Course): number {
        const tagsSelectedIds = this.tagsSelected.map((t: Tag) => t.id);
        const tagsCourseIds = c.tags.map((t: Tag) => t.id);
        return tagsSelectedIds.filter((v) => -1 === tagsCourseIds.indexOf(v)).length;
    }

    selectCourse(course: Course) {
        if (this.userCourse.find((c) => c.courseId === course.id)) {
            this.nav.navigateRoot([`course/show/${course.id}`]);
        } else {
            this.nav.navigateRoot(["course/detail"]);
        }
        this.showSearch = false;
    }

    goHome() {
        this.nav.navigateRoot(["/"]);
    }

    switchSearch() {
        this.showSearch = !this.showSearch;
    }

    getNotificationsCount(notifications: Notification[]) {
        const unread = notifications.filter((n: Notification) => {
            return !n.read;
        });

        return unread.length < 100 ? unread.length : "99+";
    }

    showNotifications() {
        this.showNotificationActive = !this.showNotificationActive;
        //this.notificationsCount = 0;
    }
}
