import {Component, OnInit} from "@angular/core"
import {ActivatedRoute} from "@angular/router"
import {NavController} from "@ionic/angular"
import {SubscriptionService} from "src/app/services/subscription.service"

@Component({
    selector: "app-subscription-pay-pal",
    templateUrl: "./subscription-pay-pal.page.html",
    styleUrls: ["./subscription-pay-pal.page.scss"],
})
export class SubscriptionPayPalPage implements OnInit {
    alert: boolean = false
    plan: any

    constructor(
        private SubscriptionService: SubscriptionService,
        private nav: NavController,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.loadData()
    }

    async loadData() {
        this.SubscriptionService.getPayPalPlan().subscribe((plan: any) => (this.plan = plan))
    }


    onSubmit() {
        const id = this.route.snapshot.params.id   
        this.SubscriptionService.newPayPalSubscription({
            subscription_id: id
        }).subscribe((data: any) => {
          
           if(data && data.status == 'pending_waiting_payment' && data.external_resource){
            window.open(data.external_resource, "_self");
            this.nav.navigateRoot([`/subscription-main`])
           }
            
        })
    }
}
