import {OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {EntityService} from '../../services/entity.service';
import {DeleteConfirmPage} from '../../shared/components/delete-confirm/delete-confirm.page';
import {ModalController} from '@ionic/angular';

export abstract class CrudListBaseComponent implements OnInit {
    entities: any[];
    page: number;

    protected constructor(
        protected service: EntityService,
        public modalController?: ModalController) {
    }

    ionViewWillEnter() {
        this.load(1); 
    }

    ngOnInit() {
        this.load(1);
    }

    load(page) {
        this.page = page;

        this.service.getAll(page)
            .pipe(first())
            .subscribe(
                (resp) => {
                    this.entities = resp;
                }
            );
    }

    changePage(page) {
        this.load(page);
    }

    async delete(tag) {
        const modal = await this.modalController.create({
            component: DeleteConfirmPage,
            cssClass: 'delete-modal'
        });

        modal.onDidDismiss()
            .then((resp) => {
                if (resp.data === true) {
                    this.service.delete(tag.id)
                        .pipe(first())
                        .subscribe(() => {
                            this.load(this.page);
                        });
                }
            });

        return await modal.present();
    }

}