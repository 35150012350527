import {FormGroup} from '@angular/forms';

export const FormTypes = {
    edit: 'edit',
    new: 'new'
};


export function resetForm(form: FormGroup) {

    form.reset();

    Object.keys(form.controls).forEach(key => {
        form.get(key).setErrors(null) ;
    });
}
