import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {first} from 'rxjs/operators';
import {Course} from '../../../../models/course.model';
import {ServerResponse} from '../../../models/response.interface';
import {UserCourse} from '../../../../models/user-course.model';
import {getS3Resource} from '../../../auxiliar';
import {ExamService} from '../../../../services/exam.service';
import {CourseService} from '../../../../services/course.service';
import {ModalController} from '@ionic/angular';
import {DeleteConfirmPage} from '../../delete-confirm/delete-confirm.page';
import {ConfirmDataCertificationPage} from './confirm-data-certification/confirm-data-certification.page';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../ngrx/app.reducer';
import {LoadStart, NoShow} from '../../../../ngrx/ui/ui.actions';

@Component({
    selector: 'app-profile-course',
    templateUrl: './profile-course.component.html',
    styleUrls: ['./profile-course.component.scss'],
})
export class ProfileCourseComponent implements OnInit {
    userCourses: any;
    courseProgress: any;
    light = true;
    resolutions = [];

    courseCertificationSelected: Course;
    showCertificationLoader = false;
    showDisabledText = false;

    constructor(private examService: ExamService,
                private courseService: CourseService,
                private store: Store<AppState>,
                private modalController: ModalController,
                private userService: UserService) {
    }

    async ngOnInit() {
        this.store.dispatch(new LoadStart());
        const courseProgress: any = await this.userService.getCoursesProgress().toPromise();
        const resp: any = await this.userService.getUserProfileCourse().toPromise();

        this.userCourses = resp.data;
        this.courseProgress = courseProgress.data;

        this.store.dispatch(new NoShow());
    
    }

    getUrlImage(course: Course) {
        if (course.imagePath) {
            return getS3Resource(course.imagePath);
        }
    }

    getPercentage(id) {
        const cp = this.courseProgress.find(c => c.course.id === id);
        return (cp.percentage) ? cp.percentage : '0';
    }

    getCertificate(c: Course) {
        this.courseCertificationSelected = c;
        this.showCertificationLoader = true;
        this.showDisabledText = false;

        this.courseService.getCourseUserCertification(c.id)
            .subscribe(
                (resp: ServerResponse) => {
                    if (resp.data.id && resp.data.enable) {
                        this.showCertificationLoader = false;
                        window.open(`${location.origin}/certification/${resp.data.id}`);
                    } else {
                        this.showDisabledText = true;
                    }
                },
                () => {
                    this.openModal(c);
                });
    }

    async openModal(c) {
        const modal = await this.modalController.create({
            component: ConfirmDataCertificationPage
        });

        modal.onDidDismiss()
            .then((resp) => {
                if (resp.data) {
                    this.courseService.createUserCertification(c.id, resp.data)
                        .subscribe((r: ServerResponse) => {
                            if (r.data.id && r.data.enable) {
                                this.showCertificationLoader = false;
                                window.open(`${location.origin}/certification/${r.data.id}`);
                            } else {
                                this.showDisabledText = true;
                            }
                        });
                }
            });

        return await modal.present();
    }


}
