import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Exam } from '../../models/exam.model';
import { ExamService } from '../../services/exam.service';
import { first } from 'rxjs/operators';
import { Question } from '../../models/question.model';
import { ExamResolution } from '../../models/exam-resolution.model';
import { CountdownComponent } from 'ngx-countdown';
import { ServerResponse } from '../../shared/models/response.interface';
import { NavController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import 'moment-timezone';
import { FinishExamConfirmationComponent } from './finish-exam-confirmation/finish-exam-confirmation.component';


@Component({
    selector: 'app-exam',
    templateUrl: './exam.page.html',
    styleUrls: ['./exam.page.scss'],
})
export class ExamPage implements OnInit {
    @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
    countDownConfig: any;

    exam: Exam;
    resolution: ExamResolution = new ExamResolution();
    id: number;

    selectedQuestion: Question;
    selectedQuestionIndex: number;
    selectedAnswerId: number;

    showCountDown = false;
    showMessageCounterFinished = false;
    showMessageResolution = false;
    showFinished = true;

    constructor(
        private route: ActivatedRoute,
        private service: ExamService,
        private nav: NavController,
        public dialog: MatDialog
    ) {
        this.id = this.route.snapshot.params.id;
    }

    async ngOnInit() {
        const checkUserExam: any = await this.service.checkExam(this.id).toPromise();
        if (!checkUserExam.data.enabled) {
            this.nav.navigateRoot([`exam/detail/${this.id}`]);
        }

        this.resolution = await this.service.getExamResolution(this.id).toPromise();

        this.service.get(this.id)
            .pipe(first())
            .subscribe((e: Exam) => {
                if (!e.status) {
                    this.nav.navigateRoot([`exam/detail/${this.id}`]);
                }
                e.questions = e.questions.filter(q => this.resolution.randomQuestions.find(rq => rq === q.id));
                this.setInitialData(e);
            });
    }

    async setInitialData(e) {
        this.exam = e;
        this.selectedQuestion = this.exam.questions[0];
        this.selectedQuestionIndex = 0;

        if (this.resolution.responses) {
            this.selectedAnswerId = this.resolution.responses[0].answerId;
        } else {
            this.resolution.responses = this.exam.questions.map(q => {
                return {
                    answerId: null,
                    questionId: q.id
                };
            });
        }

        if (this.exam.minutesTimeLimit > 0) {
            const startedAt = moment.tz(this.resolution.startedAt, 'UTC');
            const maxDate = moment(startedAt).add(this.exam.minutesTimeLimit, 'minutes');
            const now = moment().utc();

            const diff = moment.duration(maxDate.diff(now)).asSeconds();
            if (diff <= 0) {
                this.endTime();
            } else {
                this.countDownConfig = {
                    leftTime: diff
                };
                this.showCountDown = true;
            }
        }
    }

    selectedAnswerEvent(answerId) {
        this.selectedAnswerId = answerId;
        const questionIdx = this.resolution.responses.findIndex((q) => q.questionId === this.selectedQuestion.id);
        this.resolution.responses[questionIdx].answerId = this.selectedAnswerId;
    }

    changeQuestion(type: string) {
        if (type === 'next' && this.selectedQuestionIndex !== (this.exam.questions.length - 1)) {
            this.selectedQuestionIndex++;
            this.beforeSetAnswer();
        }

        if (type === 'last' && this.selectedQuestionIndex !== 0) {
            this.selectedQuestionIndex--;
            this.beforeSetAnswer();
        }
    }

    beforeSetAnswer() {
        delete this.selectedQuestion;
        // fuerzo la recreacion del componente
        setTimeout(() => {
            this.selectedQuestion = this.exam.questions[this.selectedQuestionIndex];
            this.setAnswer();
        }, 0);
    }

    setAnswer() {
        const questionIdx = this.resolution.responses.findIndex((q) => q.questionId === this.selectedQuestion.id);
        this.selectedAnswerId = this.resolution.responses[questionIdx].answerId;
        this.service.updateExam(this.resolution).toPromise().then(() => {});
    }

    finishExam(): void {
        const data = this.dialog.open(FinishExamConfirmationComponent, {
            width: '500px'
        })
        data.afterClosed().subscribe(result => {
            if (result) {
                this.endExam();
            }
        });
    }

    endExam() {
        this.showFinished = false;
        this.service.endExam(this.resolution)
            .subscribe((resolution) => {
                this.resolution = resolution;
                this.showMessageCounterFinished = false;
                this.showMessageResolution = true;
            });
    }

    handleTimeEvent(event: { action: string, left: number, status: number, text: string }) {
        console.log(event);
        if (event.left === 0 && event.status === 3 && this.showFinished) {
            this.endTime();
        }
    }

    endTime() {
        this.showMessageCounterFinished = true;
        setTimeout(() => {
            this.endExam();
        }, 4000);
    }

}
