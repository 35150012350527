import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AdminDashboardPage} from './dashboard/admin-dashboard.page';

import {CourseRoutes} from './course/course-routing.module';
import {TaskRoutes} from './task/task-routing.module';
import {VideoRoutes} from './video/video-routing.module';
import {UserRoutes} from './user/user-routing.module';
import {AdminGuard} from '../helpers/admin.guard';
import {TagRoutes} from './tags/tag-routing.module';
import {AdvertisingRoutes} from './advertising/advertising-routing.module';
import {ExamRoutes} from "./exams/exam-routing.module";
import {QuestionRoutes} from './exams/question-routing.module';
import {CertificationRoutes} from './certifications/certification-routing.module';
import { CarouselRoutes } from './carousels/carousel-routing.module';
import { NotificationRoutes } from './notifications/notifications-routing.module';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminDashboardPage,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'courses',
        children: CourseRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'carousels',
        children: CarouselRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'videos',
        children: VideoRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'tasks',
        children: TaskRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'users',
        children: UserRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'tags',
        children: TagRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'advertisements',
        children: AdvertisingRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'exams',
        children: ExamRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'questions',
        children: QuestionRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'certifications',
        children: CertificationRoutes,
        canActivate: [AdminGuard]
      },
      {
        path: 'notifications',
        children: NotificationRoutes,
        canActivate: [AdminGuard]
      }
    ]
  },
  
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
      RouterModule
  ]
})


export class AdminRoutingModule {}
