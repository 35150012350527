import {Component, OnInit} from "@angular/core"
import {ActivatedRoute} from "@angular/router"
import {NavController} from "@ionic/angular"
import {NoShow} from "src/app/ngrx/ui/ui.actions"
import {SubscriptionService} from "src/app/services/subscription.service"

@Component({
    selector: "app-subscription-wire-transfer",
    templateUrl: "./subscription-wire-transfer.page.html",
    styleUrls: ["./subscription-wire-transfer.page.scss"],
})
export class SubscriptionWireTransferPage implements OnInit {
    
    plan: any

    constructor(
        private SubscriptionService: SubscriptionService,
        private nav: NavController,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.loadData()
    }

    async loadData() {
        this.SubscriptionService.getPlan().subscribe((plan: any) => (this.plan = plan.body))
    }

    onSubmit() {
       
        const id = this.route.snapshot.params.id
        this.SubscriptionService.newSubscriptionWireTransfer({
            subscription_id: id,
            amount: this.plan.auto_recurring.transaction_amount * 6,
            description: this.plan.reason
        }).subscribe((response: any) => {
            this.nav.navigateRoot([`/subscription-main`])
            
        })
    }
}
