import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class UserCourse {
    @jsonMember
    courseId: number;
    @jsonMember
    createdAt: string;
    @jsonMember
    finishedAt: string;
}
