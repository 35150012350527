import {Routes} from '@angular/router';
import {CertificationFormPage} from './certification-form/certification-form.page';

export const CertificationRoutes: Routes = [
    {
        path: 'form',
        component: CertificationFormPage
    },
    {
        path: 'form/:id',
        component: CertificationFormPage
    }
];

