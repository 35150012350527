import { NgModule } from "@angular/core";
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import { CommonModule } from "@angular/common";
import { NotificationsListPage } from "./notifications-list/notifications-list.page";
import { NotificationFormPage } from "./notification-form/notification-form.page";




@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [NotificationsListPage, NotificationFormPage],
    exports: [NotificationsListPage, NotificationFormPage],
    entryComponents: [],
})
export class NotificationsModule {}
