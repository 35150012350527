import {jsonMember, jsonObject} from 'typedjson';

@jsonObject
export class Answer {
    @jsonMember
    id: number;
    @jsonMember
    description: string;
    @jsonMember
    correct: boolean;
}