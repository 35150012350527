import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {NavController} from '@ionic/angular';
import {UserService} from '../../../services/user.service';
import {CrudListBaseComponent} from '../../crud-base/crud-list-base.component';
import {first} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
    selector: 'app-task-list',
    templateUrl: './user-list.page.html',
    styleUrls: ['./user-list.page.scss'],
})
export class UserListPage extends CrudListBaseComponent implements OnInit {

    filter = '';

    constructor(private store: Store<AppState>,
                public nav: NavController,
                protected service: UserService) {
        super(service);
    }

    selectUser(id) {
        this.nav.navigateRoot([`/admin/users/show/${id}`]);
    }

    search(value) {
        this.filter = value;
        this.service.getAll(this.page, this.filter)
            .pipe()
            .subscribe(
                (resp) => {
                    this.entities = resp;
                }
            );
    }

    changePage(page) {
        if (this.filter) {
            this.page = page;
            this.search(this.filter);
        } else {
            this.page = page;
            this.load(page);
        }
    }

    getUserListLink() {
        const date = moment(new Date()).format('DD/MM/YYYY');

        this.service.getUserListLink().subscribe(
            response => {
                const blob: any = new Blob([response], { type: 'text/csv; charset=utf-8' });
                saveAs(blob, `users-${date}.xls`);
            },
                error => console.log('Error downloading the file'),
            () => console.log('File downloaded successfully')
        );
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
       
        let blob = new Blob([data], {type: type});
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }
}
