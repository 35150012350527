import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../ngrx/app.reducer';
import {EntityService} from './entity.service';
import {TypedJSON} from 'typedjson';
import {Exam} from '../models/exam.model';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ExamResolution} from '../models/exam-resolution.model';
import {ServerResponse} from '../shared/models/response.interface';
import {LoadStart, NoShow, ShowError, ShowSuccess} from '../ngrx/ui/ui.actions';
import {of, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExamService extends EntityService {

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>) {
        super(http, store);
    }

    resource = 'exams';

    public transformToModel(obj) {
        if (obj) {
            obj.minCorrectAnswersToPass = Number(obj.minCorrectAnswersToPass);
            obj.minutesBetweenAttemps = Number(obj.minutesBetweenAttemps);
            obj.minutesTimeLimit = Number(obj.minutesTimeLimit);
            obj.numbersOfQuestions = Number(obj.numbersOfQuestions);
            obj.videoId = Number(obj.videoId);
            obj.courseId = Number(obj.courseId);
            obj.status = Number(obj.status);

            const courseSerializer = new TypedJSON(Exam);
            const course = courseSerializer.parse(obj);

            course.questions.forEach(q => {
                q.answers.forEach(a => {
                    a.correct = (a.id === q.correctAnswerId);
                });
            });

            return course;
        }
        return null;
    }

    public getExamResolution(examId) {
        return this.http.get(`${environment.urlRoot}/exams-resolution/${examId}`)
            .pipe(
                map((resp: ServerResponse) => {
                    return this.transformToModelResolution(resp.data);
                })
            );
    }

    public endExam(body) {
        this.store.dispatch(new LoadStart());
        return this.http.put(`${environment.urlRoot}/exams-resolution/end`, body)
            .pipe(
                map((resp: ServerResponse) => {
                    this.store.dispatch(new NoShow());
                    return this.transformToModelResolution(resp.data);
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    return of(err);
                })
            );
    }

    public checkExam(examId) {
        return this.http.get(`${environment.urlRoot}/exams-resolution/check/${examId}`);
    }

    public updateExam(body) {
        return this.http.put(`${environment.urlRoot}/exams-resolution`, body);
    }

    transformToModelResolution(obj) {
        obj.examId = Number(obj.examId);
        obj.responses = (obj.responses) ? JSON.parse(obj.responses) : null;
        obj.result = (obj.result) ? Number(obj.result) : null;
        const serializer = new TypedJSON(ExamResolution);
        return serializer.parse(obj);
    }

    public changeStatus(id) {
        return this.http.put(`${environment.urlRoot}/exams/status/${id}`, {});
    }


    public getUserExam() {
        return this.http.get(`${environment.urlRoot}/resolutions`)
            .pipe(
                map((resp: ServerResponse) => {
                    return resp.data;
                })
            );
    }

    getReport(id) {
        return this.http.get(`${environment.urlRoot}/exams/${id}/users/list`, { responseType: 'blob' })
            .pipe(
                map((resp) => {
                    this.store.dispatch(new ShowSuccess());
                    return resp;
                }),
                catchError((err, caught) => {
                    this.store.dispatch(new ShowError());
                    console.log(err);
                    return throwError(err);
                })
            );
    }


    inProgressExamResolution(id) {
        return this.http.get(`${environment.urlRoot}/exams-resolution/in-progress/${id}`);
    }


}
