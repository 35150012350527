import {Component, Inject, OnInit} from '@angular/core';
import {RegisterBasePage} from '../register-base/register-base.page';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../../ngrx/app.reducer';
import {Router} from '@angular/router';
import {ErrorService} from '../../../services/error.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {fields} from './form.register';
import {TypedJSON} from 'typedjson';
import {User} from '../../../models/user.model';
import {AuthService} from '../../../services/auth.service';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent extends RegisterBasePage implements OnInit {

    model = {};
    fields = fields;

    constructor(@Inject(HttpClient) http: HttpClient,
                @Inject(Store) store: Store<AppState>,
                @Inject(Router) router: Router,
                @Inject(ErrorService) errorService: ErrorService,
                @Inject(FormBuilder) fb: FormBuilder,
                @Inject(NavController) nav: NavController,
                private service: AuthService
    ) {
        super(http, store, router, errorService, nav, fb);
        this.form = new FormGroup({});
    }

    ngOnInit() {
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.showFormError = false;
            const serializer = new TypedJSON(User);
            const user = serializer.parse(this.model);
            this.service.register(user)
                .subscribe();
        } else {
            this.showFormError = true;
        }
    }
}
