import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-delete-confirm',
    templateUrl: './confirm-data-certification.page.html',
    styleUrls: ['./confirm-data-certification.page.scss'],
})
export class ConfirmDataCertificationPage implements OnInit {

    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public modalController: ModalController) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            fullName: [null, Validators.required],
            dni: [null, Validators.required]
        });
    }

    cancel() {
        this.modalController.dismiss(false);
    }

    submit() {
        if (this.form.valid) {
            this.modalController.dismiss(this.form.value);
        }
    }

}
