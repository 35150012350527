import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AppState} from '../../../ngrx/app.reducer';
import {Store} from '@ngrx/store';
import {Paginator} from '../../models/paginator.interface';
import {SetPaginator} from '../../../ngrx/ui/ui.actions';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
    @Output() changePage = new EventEmitter<number>();

    public totalItems: number;
    public currentPage: number;
    public totalPages: number;

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
        this.store.select('ui', 'paginator').subscribe(
            (pag: Paginator) => {
                this.currentPage = pag.currentPage;
                this.totalPages = pag.totalPages;
                this.totalItems = pag.totalItems;
            }
        );
    }

    setCurrent(page: number) {
        if (page >= 1 && page <= this.totalPages) {
            const paginator: Paginator = {
                currentPage: page,
                totalPages: this.totalPages,
                totalItems: this.totalItems
            };
            this.store.dispatch(new SetPaginator({paginator}));
            this.changePage.emit(page);
        }
    }

    ngOnDestroy(): void {
        const paginator: Paginator = {
            currentPage: 1,
            totalPages: 0,
            totalItems: 0
        };

        this.totalItems = 0;
        this.totalPages = 0;
        this.currentPage = 1;

        this.store.dispatch(new SetPaginator({paginator}));
    }


}
